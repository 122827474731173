<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <!-- <v-btn icon @click="regresar()"><v-icon color="primary">arrow_back</v-icon></v-btn> -->
                <v-toolbar-title>Clientes </v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-toolbar-title> Asignar área a paciente <span></span></v-toolbar-title>
                <v-spacer></v-spacer>
                <!-- {{this.puesto}} -->
                
            </v-toolbar>
            <!-- DIALOGO PARA SELECCIONAR PACIENTE -->
            <v-dialog v-model="selccionarUbicacion" width="1000">
                <v-card>
                    <v-card-title>
                    <span class="headline">Seleccionar área </span>
                    </v-card-title>                    
                    <v-card-actions>
                        <v-card-title> 
                            Área actual: {{this.sucursalAct != null ? this.sucursalAct.alias + " - " + this.sucursalAct.tipo : ""}}
                        </v-card-title>
                        <v-spacer></v-spacer>
                        <v-btn color="secondary" text @click="selccionarUbicacion = false; pacienteAct = null">Cancelar</v-btn>
                    </v-card-actions>
                    <v-divider></v-divider>
                    <v-container>
                        <template>
                            <v-flex>
                                <v-toolbar flat color="white">
                                    <v-toolbar-title>Áreas</v-toolbar-title>
                                    <v-divider class="mx-2" inset vertical></v-divider>
                                    <v-spacer></v-spacer>
                                    <v-text-field
                                        class="text-xs-center"
                                        v-model="search"
                                        append-icon="search"
                                        label="Búsqueda"
                                        single-line
                                        hide-details
                                    ></v-text-field>
                                    <v-spacer></v-spacer>
                                </v-toolbar>
                            </v-flex>
                        </template>
                        <v-data-table
                            :headers="headers"
                            :items="lstUbicaciones"
                            :search="search"
                            :loading="cargando"
                            loading-text="Cargando datos... Por favor espere un momento"
                            class="elevation-1"
                        >
                            <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.alias }}</td>
                                <td>{{ item.tipo }}</td>
                                <td>{{ item.responsable.nombre}} {{item.responsable.appPaterno}} {{item.responsable.appMaterno}}</td>
                                <td class="justify-center layout px-0">
                                    <v-btn
                                    icon
                                    color="primary"
                                    @click="seleccionarUbicacion(item)"
                                    >
                                        <v-icon>send</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </template>
                            <template v-slot:no-data>
                            <v-btn color="primary" @click="obtenerSucursales">Actualizar</v-btn>
                            </template>
                        </v-data-table>
                    </v-container>

                </v-card>
            </v-dialog>
            <!-- LISTA DE PACIENTES con ticket -->
            <div>
                
                <v-list subheader three-line >
                    <v-divider></v-divider>
                    <v-subheader >
                        <v-toolbar-title>Pacietes {{ lstMisPacientes.length >0 ? '(' + lstMisPacientes.length + ') ' : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-subheader>
                    <span v-if="lstMisPacientes.length <=0 && ! cargando" style="color: red;">  NO HAY DATOS</span>
                    <!-- <v-divider></v-divider> -->
                
                
                    <v-list-item v-for="pas in lstMisPacientes" :key="pas._id">
                        <!-- <v-spacer></v-spacer> -->
                        <v-list-item-content>
                            <v-list-item-title >{{pas.Cliente.nombre}} {{pas.Cliente.appPaterno}} {{pas.Cliente.appMaterno}}</v-list-item-title>
                            <v-list-item-subtitle >Genero: {{pas.Cliente.genero}}; F. Nac: {{pas.Cliente.fechaNacimiento}}; F. Ing: {{pas.Cliente.fechaIngreso}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                        <v-list-item-action>
                            <v-btn text color="secondary" @click="abrirSeleccionarUbic(pas)">
                            Seleccionar área
                            </v-btn>
                         </v-list-item-action>
                         <!-- <v-spacer></v-spacer> -->
                    </v-list-item>
                </v-list>   
            </div>
            <div>
                <v-progress-linear
                    v-if="cargando"
                    indeterminate
                    color="accent"
                    height="8"
                ></v-progress-linear>
                <v-snackbar v-model="snackbar" :multi-line="multiLine">
                    {{ this.txtSnackBar }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from "axios";

export default {
    
    data(){
        
        return{
            rutaServ : this.$store.state.servidor,  
            selccionarUbicacion: false,
            search: "",
            headers: [
                { text: "Área", value: "alias", sortable: true },
                { text: "Tipo", value: "tipo", sortable: true },
                { text: "Responsable", value: "responsable", sortable: true },
            ],
            //snackbar
            cargando: false,
            multiLine: true,
            snackbar: false,
            txtSnackBar: '',
            
            //
            cargando: false,
            pacienteAct: null,
            sucursalAct: null,
            lstMisPacientes: [],
            lstUbicaciones: [],
        };
    },
    computed:{},
    watch:{},
    created(){
        this.obtenerPacientes();
        this.obtenerSucursales();
    },
    methods:{
        obtenerPacientes(){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            me.lstMisPacientes = [];
            axios
            .get(me.$store.state.servidor + "ticketIngreso/0/0", configuracion)
            .then(function (response) {
                me.lstMisPacientes = response.data.tikects;
                me.cargando = false;
            })
            .catch(function (error) {
                me.cargando = false;
                console.log(error);
                me.mensajeSnackBar("No es posible recuperar pacientes");
            });
        },
        obtenerSucursales(){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            me.lstUbicaciones = [];
            axios
            .get(me.$store.state.servidor + "sucursal/0/0", configuracion)
            .then(function (response) {
                me.lstUbicaciones = response.data.Sucursales;
                me.cargando = false;
            })
            .catch(function (error) {
                me.cargando = false;
                console.log(error);
                me.mensajeSnackBar("No es posible recuperar ubicaciones");
            });
        },
        abrirSeleccionarUbic(paciente){
            this.pacienteAct = paciente;
            let encontrado = false;
            this.lstUbicaciones.forEach(u => {
                if (u._id == this.pacienteAct.Cliente.InSucursal){
                    this.sucursalAct = u;
                    encontrado = true;
                }
            });
            if (!encontrado) {
                this.sucursalAct = null;
            }
            this.selccionarUbicacion = true;
        },
        seleccionarUbicacion(suc){
            let me = this;
            const body ={
                InSucursal:suc._id,
                Persona:this.pacienteAct.Cliente._id,
                fechaIngreso: "",
                isInSucursal: true
            };
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            
            axios
            .put(
                this.$store.state.servidor + "personaIsInSucursal",
                body,
                configuracion
            )
            .then(function (response) {
                me.cargando = false;
                me.mensajeSnackBar("Se asignó correctamente el paciente");
                me.pacienteAct = null;
                me.selccionarUbicacion = 0;
                me.obtenerPacientes();
            })
            .catch(function (error) {
                me.cargando = false;
                console.log('error', error);
                me.mensajeSnackBar("No se liberó el paciente");
            })
            .finally(() => (this.snackbar = true));
        },
        mensajeSnackBar(mensaje){
            this.txtSnackBar = mensaje;
            this.snackbar = true
        },
    },
};
</script>