<template>
  <v-container>
    <h1>Información de Paciente {{idCliente}}</h1>
    
  </v-container>
</template>

<script>
import axios from "axios";
export default {
     data() {
         return {
             idCliente : ""
         };
     },
     methods: {
         regresar(){

         }
     },
     created() {
         console.log("Se ejecuta");
     this.idCliente = this.$route.params.id;
    }
}
</script>
