<template>
  <v-flex >
    <v-progress-linear v-if = "procesando"
        indeterminate
        color="primary"
      ></v-progress-linear>
    <v-container>
      <v-dialog v-model="selPuestoToApp" max-width="500">
        <v-card>
            <v-card-title>
              <span class="headline">Puestos de {{appSel.Titulo}}</span>            
            </v-card-title>
            <v-card-text>
              <v-card-subtitle>
                 <v-btn @click="verFormPuesto = !verFormPuesto" color="primary"
                icon
              >
                <v-icon>{{verFormPuesto ? 'remove_circle' : "add_circle"}}</v-icon>
              </v-btn>
                Puestos con acceso a esta aplicación
              </v-card-subtitle>
             
              <div class="text-center">
                <div v-if="verFormPuesto">
                  <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm8 md8>
                      <v-select
                        :items="lstPuestosDisp"
                        v-model="puestoSel"
                        item-text="descripcion"
                        item-value="_id"
                        label="Seleccionar puesto"
                        @change="chnagePuesto"
                      ></v-select>
                      <div v-if="isError" class="red--text">{{msjError}}</div>
                    </v-flex>
                    <v-flex xs12 sm4 md4>
                      <v-btn text  @click="setPuesto(2)">
                        Guardar
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
                </div>
              </div>
              
              <v-divider></v-divider>
              <v-list>
                <v-list-item v-for="(item, i) in appSel.puestos "
                  :key="i"
                  cols="12"
                  md="6"
                >
                <v-list-item-content>
                  <v-list-item-title>{{item.CategoriaPuesto.titulo}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="delPuestoApp(item._id)">
                      <v-icon>delete</v-icon>
                    </v-btn>
                </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="setPuesto(1)">Cerrar</v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    <v-item-group>
      <v-row>
        <v-col v-for="(item, i) in lstAppsHijos "
              :key="i"
              cols="12"
              md="6">
          <v-item >
            <v-card class="mx-auto" outlined>
              <v-list-item three-line>
                <v-list-item-icon>
                  <v-icon>fa-building-o</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <div class="overline mb-4">Aplicación - {{item.Folio}}</div>
                  <v-list-item-title class="headline mb-1">
                    {{item.Titulo}}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{item.Descripcion}}
                  </v-list-item-subtitle>
                  <br />
                  <v-flex>
                    
                  </v-flex>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn
                  color="deep-purple lighten-2"
                  text
                  @click="addPuesto(item)"
                >
                  Puestos
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-item>     
        </v-col>
      </v-row>
    </v-item-group>
    </v-container>

    <!-- MENSAJE DE SNACKBAR -->
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>


  </v-flex>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      // Global
      procesando: false,
      // datos de  apps
      lstApps: [],
      lstAppsHijos: [],
      appSel: {
        Folio: app.Folio,
        Titulo: app.Titulo,
        Descripcion: app.Descripcion,
        puestos: []
      },
      // datos para selPuestoToApp
      lstAllPuestos: [],
      lstPuestosDisp: [],
      selPuestoToApp: false,
      puestoSel: null,
      verFormPuesto: false,
      isError: false,
      msjError: '',
      // modelo para snackbar
      snackbar: false,
      textPeticionRest: "",
      multiLine: true,

      items: 4,
    };
  },
  created(){
    this.obtenerAllPuestos();
    this.listarApps();
  },
  methods: {
    obtenerAllPuestos(){
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
         axios
        .get(this.$store.state.servidor + "categoriaPuesto/0/0", configuracion)
        .then(function (response) {
          me.lstAllPuestos = response.data.categoriasPuesto;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    listarApps(){
      this.procesando = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "aplicacionesAll", configuracion)
        .then(function (response) {
          me.lstApps = response.data.aplicacionesERP;
          me.lstApps.forEach((app) =>{
            if (!app.isPadre){
              let appH = {
                Folio: app.Folio,
                Titulo: app.Titulo,
                Descripcion: app.Descripcion,
                puestos: []
              };
              me.lstAppsHijos.push(appH);
            }
            me.procesando = false;
          });
        })
        .catch(function (error) {
          me.procesando = false;
          console.log(error);
        });
    },
    addPuesto(item){
      this.appSel = item;
      this.lstPuestosDisp = [];
      this.puestoSel = null;
      this.isError = false;
      this.msjError ='';
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "puestoFolioAplicacion/" + me.appSel.Folio, configuracion)
        .then(function (response) {
          me.appSel.puestos = response.data.puestosAplicaciones;
          me.lstAllPuestos.forEach((p) => {
            let encontrado = false;
            me.appSel.puestos.forEach((pi) =>{
              if(pi.CategoriaPuesto._id == p._id){
                encontrado = true;
              }
            });
            if(! encontrado){
              me.lstPuestosDisp.push(p);
            }
          });
          me.selPuestoToApp = true;
        })
        .catch(function (error) {
          me.appSel.puestos = [];
          console.log(error);
        }); 
    },
    setPuesto(opcion){
      if (opcion===1){
        this.selPuestoToApp = false;
      }
      if(opcion===2){
        if(this.validaSelPuesto()){
          let me = this;
          let header = { Authorization: localStorage.token };
          let configuracion = { headers: header };
          const dat = {
              CategoriaPuesto: this.puestoSel,
              aplicacionesERPFolio: this.appSel.Folio
          }
          axios
            .post(this.$store.state.servidor + "puestoAplicacion", dat, configuracion)
            .then(function (response) {
              me.mensajeSnackBar("Se agregó correctamente el puesto");
              me.addPuesto(me.appSel);
            })
            .catch(function (error) {
              me.mensajeSnackBar("No fue posible agregar el puesto");
              console.log(error);
            });
        }
      }
    },
    delPuestoApp(id){
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(this.$store.state.servidor + "puestoAplicacion/" + id, configuracion)
        .then(function (response) {
          me.mensajeSnackBar("Se quitó correctamente el puesto");
          me.addPuesto(me.appSel);
        })
        .catch(function (error) {
          me.mensajeSnackBar("No fue posible quito el puesto");
          console.log(error);
        });
    },
    validaSelPuesto(){
      if( this.puestoSel == null || this.appSel == null ){
          this.msjError = 'Debe haber un puesto seleccionado';
          this.isError = true;
          return false;
      }else{
        this.msjError = '';
        this.isError = false;
        return true;
      }
    },
    chnagePuesto(){
      this.validaSelPuesto();
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
      this.snackbar = true;
    },
  },
};
</script>