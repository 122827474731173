<template>
  <v-app id="app">
    <v-navigation-drawer
      fixed
      :clipped="$vuetify.breakpoint.mdAndUp"
      app
      v-model="drawer"
      v-if="login"
    >
      <v-list dense>
        <template>
          <v-list-item :to="{ name: 'home' }">
            <v-list-item-action >
              <v-icon  color="primary">home</v-icon>
            </v-list-item-action>
            <v-list-item-title> Inicio </v-list-item-title>
          </v-list-item>
        </template>
        <template>
          <span  
          v-for = "padre in aplicaiconesPadre" 
          :key = "padre.Folio">
          <v-list-group>
              <v-list-item slot="activator">
                <v-list-item-content>
                  <v-list-item-title> {{padre.Titulo}} </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item 
                v-for="hijo in padre.hijos"
                :key ="hijo.Folio"
                :to="{ name: hijo.Url }">
                <v-list-item-action>
                  <v-icon>{{hijo.icon}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{hijo.Titulo}}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

          </v-list-group>
          </span>
        </template>
      </v-list>
    </v-navigation-drawer>

        <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="secondary"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down flex text-center">Sistema</span>
      </v-toolbar-title><v-spacer></v-spacer>
      {{this.nombre}} {{this.appPaterno}} {{this.appMaterno}}
      
      <v-btn icon @click="salir()">
        <v-icon>logout</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container fluid fill-height>
        <!-- LOGUIN -->
        <v-layout v-if="! login" align-center justify-center>
          <v-flex xs12 sm8 md6 lg5 xl4>
            <v-card>
              <v-toolbar dark color="secondary">
                <v-toolbar-title class="flex text-center">
                  Iniciar Sesión</v-toolbar-title
                >
              </v-toolbar>
              <v-card-text>
                <v-text-field
                  v-model="email"
                  autofocus
                  color="accent"
                  label="Email"
                  required
                >
                </v-text-field>
                <v-text-field
                  v-model="password"
                  type="password"
                  color="accent"
                  label="Password"
                  required
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions class="px-3 pb-3">
                <v-flex text-xs-right>
                  <v-btn @click="ingresar()" color="primary">Ingresar</v-btn>
                </v-flex>
              </v-card-actions>
            </v-card>
            <div>
              <v-progress-linear
                v-if="isDataSending"
                indeterminate
                color="accent"
                height="8"
              ></v-progress-linear>
              <v-snackbar v-model="snackbar" :multi-line="multiLine">
                {{ textNoLogin }}

                <template v-slot:action="{ attrs }">
                  <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                  </v-btn>
                </template>
              </v-snackbar>
            </div>
          </v-flex>
        </v-layout>

        <!-- APP -->
        <v-slide-y-transition v-if="login" mode="out-in">
          <router-view />
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer :padless="true" height="auto">
      <v-layout justify-center>
        <v-flex text-xs-center>
          <v-card flat tile color="primary" class="white--text">
            <v-card-text class="white--text pt-0">
              Logia Systems &copy;2021
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import { createApp } from "vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      // PERMISOS
      drawer: false,
      aplicaiconesPadre: [],
      aplicaciones: [],
      // DATOS USUARIOS
      alias: "",
      nombre: "",
      appPaterno: "",
      appMaterno: "",
      puestoTitulo: "",
      
      // Login
      login: false,
      email: "",
      password: "",
      isDataSending: false,
      // SNACBAR
      multiLine: true,
      snackbar: false,
      textNoLogin: 'No se puede acceder al sistema, verifique sus credenciales',
      
    };
  },
  computed: {
  },
  watch: {
  login(v1,v2){
     this.actualizarDatos();   
  }
  },
  created() {
    this.validaAcceso();
  },
  methods: {
    salir() {
      this.login = false;
      this.$store.dispatch("salir");
    },
    actualizarDatos() {
      this.nombre =  localStorage.getItem("nombre") ? localStorage.getItem("nombre") : '';
      this.appPaterno =  localStorage.getItem("appPaterno") ? localStorage.getItem("appPaterno") : '';
      this.appMaterno =  localStorage.getItem("appMaterno") ? localStorage.getItem("appMaterno") : '';
      this.creaMenu();
    },
    creaMenu(){
      this.aplicaiconesPadre = [];
      if (this.login)
      {
        let me = this;
        let header = { Authorization: localStorage.token };
        let configuracion = { headers: header };
        axios
          .get(this.$store.state.servidor + "personalPuestoAsignadoSucursalPrincipal", configuracion)
          .then(function (response) {
            me.aplicaciones = response.data.listadoAplicaciones;
            localStorage.setItem("aplicaciones", JSON.stringify(me.aplicaciones));
            me.aplicaciones.forEach((ap) => {
              if (ap.isPadre){
                const menuItem = {
                  Folio: ap.Folio,
                  Titulo: ap.Titulo,
                  Descripcion: ap.Descripcion,
                  Nombre: ap.Nombre,
                  Url: ap.Url,
                  icon: ap.ico,
                  hijos: []
                };
                me.aplicaiconesPadre.push(menuItem);
              }
            });
            me.aplicaciones.forEach((ap) => {
              if (!ap.isPadre) {
                me.aplicaiconesPadre.forEach((padre) => {
                  if (ap.folioPadre == padre.Folio){
                    const menuHijo = {
                      Folio: ap.Folio,
                      Titulo: ap.Titulo,
                      Descripcion: ap.Descripcion,
                      Nombre: ap.Nombre,
                      Url: ap.Url,
                      icon: ap.ico
                    };
                    padre.hijos.push(menuHijo);
                  }
                });
              }
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    //Login
    async ingresar() {
      
      this.isDataSending = true;
       axios
        .post(this.$store.state.servidor + "login", {
          usuario: this.email,
          password: this.password,
        })
        .then(function (response) {
          return response.data;
        })
        .then((data) => {
          this.$store.dispatch("guardarToken", data);
          if(this.$route.name != "home")
            this.$router.push({ name: "home" });
          this.login = true;
          this.email = '';
          this.password = '';
          this.isDataSending = false;
        })
        .catch((error) => {
          this.snackbar = 1;
          this.isDataSending = false;
          return "error";
        });
    },    
    validaAcceso(){
      const apps = JSON.parse(localStorage.getItem("aplicaciones"));
      const local = this.$store.name;
      let acceso = false;
      if(apps){
        apps.forEach((ap) => {
          if (ap.Url === local){
            acceso = true;
          }
        });
      }
      if ((!acceso) && (local != "home") && local != null){
        console.log('val-local',local)
          this.$router.push({ name: "home" });
      }
    },
    mensajeSnackBar(mensaje) {
        this.textPeticionRest = mensaje;
    },

  },

  sockets: {
        ///connect: function () {
        ///    console.log('Iniciamos conexion')
        ///},
        ///customEmit: function (data) {
        ///    console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)');
        ///    console.log(data);
        ///}
    },

  mounted() {
    if (localStorage.nombre) {
      this.nombre = localStorage.nombre;
    }
    if (localStorage.alias) {
      this.alias = localStorage.alias;
    }
    if (localStorage.token) {
      this.login = true;
    }
  },
};
</script>


<style>
::-webkit-scrollbar {
  display: none;
}
</style>