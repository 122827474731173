<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Puesto</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template >
        <v-btn class="mb-2"  color="xls" @click="crearExcel">
          <v-icon color="white">fa-file-excel-o</v-icon>
        </v-btn>
      </template>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="titulo" label="Titulo">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="descripcion" label="Descripción">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="rolesExtras"
                      v-model="tagPuesto"
                      label="Selecciona tipo de puesto especial"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Puesto
              {{ adTitulo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="puestos"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.titulo }}</td>

            <td>{{ item.descripcion }}</td>
            <td>{{ item.tagPuesto }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon v-if="validarTituloNoAdmin(item)">
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon v-if="validarTituloNoAdmin(item)">
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";  

export default {
  data() {
    return {
      dialog: false,
      search: "",
      puestos: [],
      headers: [
        { text: "Titulo", value: "titulo", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Rol Extra", value: "tagPuesto", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },

        
      ],

      rolesExtras: ['','Enfermería','Médico'],
      editedIndex: -1,
      _id: "",

      titulo: "",
      descripcion: "",
      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      tagPuesto : "",

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo puesto" : "Editar puesto";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    validarTituloNoAdmin(item){
      if (item.titulo.toUpperCase()=="ADMIN" || item.titulo.toUpperCase()=="ADMINISTRADOR" ){
        return false;
      }else {
        return true;
      }
    },
    crearExcel() {
      let infTmp = [];
      this.puestos.forEach((item) => {
        let variable = {
          "Titulo" : item.titulo ,
          "Descripción": item.descripcion,
          "Rol Extra" : item.tagPuesto
        };
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte_Puesto";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "categoriaPuesto/0/0", configuracion)
        .then(function (response) {
          me.puestos = response.data.categoriasPuesto;
          
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";

      this.titulo = "";
      this.descripcion = "";
      this.tagPuesto = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.titulo.length < 3 || this.titulo.length > 50) {
        this.validaMensaje.push(
          "La titulo del usuario debe tener entre 3-50 caracteres."
        );
      }
      if (this.descripcion.length < 10) {
        this.validaMensaje.push(
          "La descripción debe contener al menos 10 caracteres"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        console.log(this.tagPuesto);
        axios
          .put(
            this.$store.state.servidor + "categoriaPuesto",
            {
              _id: this._id,
              titulo: this.titulo,
              tagPuesto : this.tagPuesto||"",
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Puesto");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar el Puesto");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        console.log(this.tagPuesto);
        axios
          .post(
            this.$store.state.servidor + "categoriaPuesto",
            {
              titulo: this.titulo,
              tagPuesto : this.tagPuesto||"",
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Puesto");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear el Puesto");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.titulo = item.titulo;
      console.log(item.tagPuesto);
      this.tagPuesto = item.tagPuesto;
      this.descripcion = item.descripcion;
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adTitulo = item.titulo;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "categoriaPuesto/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adTitulo = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente el Puesto");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Puesto");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>
