<template>
    <v-layout align-start>
        <v-flex>
            <v-card 
            class="mx-auto" 
            outlined max-width="600px"
            >
              <v-list-item three-line>
                <v-list-item-icon>
                    <v-icon>fa-user-circle-o</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <span v-if="puesto != null">
                        <div class="overline mb-4">{{this.puesto.titulo}}</div>
                        
                    </span>
                    <v-list-item-title class="headline mb-1">
                        {{this.nombre}} {{this.paterno}} {{this.materno}}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{this.puesto.descripcion}}</v-list-item-subtitle>
                    <br />
                    <v-flex>  
                    </v-flex>
                </v-list-item-content>
            </v-list-item>
            <v-card-actions>
                <v-card-text> 
                    <v-card-title>Cambiar contraseña de {{this.alias}}</v-card-title>
                    
                    <v-container grid-list-md>
                        <v-layout wrap>
                        <v-flex xs12 sm6 md6>
                            <v-text-field type="password" v-model="password" label="Contraseña">
                            </v-text-field>
                        </v-flex>
                        <v-flex xs12 sm6 md6>
                            <v-text-field type="password" v-model="confirmaPass" label=" Confirmar contraseña">
                            </v-text-field>
                        </v-flex>

                        <v-flex xs12 sm12 md12 v-show="valida">
                            <div
                                class="red--text"
                                v-for="v in validaMensaje"
                                :key="v"
                                v-text="v"
                            ></div>
                        </v-flex>
                        <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="guardar()"
                        >
                        Guardar
                        </v-btn>
                    </v-layout>
                </v-container>
                </v-card-text>
            </v-card-actions>
            </v-card>
        </v-flex>
        <v-snackbar v-model="snackbar" :multi-line="multiLine">
          {{ textPeticionRest }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
      </v-snackbar>
    </v-layout> 
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";  

export default {
    data(){
        return{
            valida: 0,
            validaMensaje: [],
            
            nombre: "",
            paterno: "",
            materno: "",
            alias: "",
            id: "",
            password: "",
            confirmaPass: "",
            puesto: null,
            
            // modelo para snackbar
            snackbar: false,
            textPeticionRest: "",
            multiLine: true,
        };
    },
    computed: {
    },
    watch:{

    },
    created(){
        this.obtenerDatos();
    },
    methods:{
        obtenerDatos(){
            let me = this;
            if(localStorage.getItem("_id")){
                me.id = localStorage.getItem("_id");
            }
            if(localStorage.getItem("puestoAsignado")){
                const puestos = JSON.parse( localStorage.getItem("puestoAsignado"));
                me.puesto = puestos[0].CategoriaPuesto;
            }
            if(localStorage.getItem("nombre")){
                me.nombre = localStorage.getItem("nombre");
            }
            if(localStorage.getItem("appPaterno")){
                me.paterno = localStorage.getItem("appPaterno");
            }
            if(localStorage.getItem("appMaterno")){
                me.materno = localStorage.getItem("appMaterno");
            }
            if(localStorage.getItem("usuario")){
                me.alias = localStorage.getItem("usuario");
            }
        },
        guardar(){
            if(this.validar() == 0){
                const body = {
                    password: this.password
                }
                let me = this;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                axios
                .put(
                    this.$store.state.servidor + "MyPass",
                    body,
                    configuracion
                )
                .then(function (response) {
                    me.mensajeSnackBar("Se actualizo correctamente su contraseña");
                })
                .catch(function (error) {
                    console.log('error', error);
                    me.mensajeSnackBar("no se puede actualizar la contraseña");
                })
                .finally(() => (this.snackbar = true));
            }
        },
        validar(){
            this.valida = 0; 
            this.validaMensaje = [];
            if (this.password.length > 0) {
                if(this.password != this.confirmaPass){
                    this.validaMensaje.push(
                        "La contraseña es diferente a la confirmación"
                    );
                }
                this.confirmaPass = "";
            }else{
                this.validaMensaje.push(
                    "Debe introducir su nueva contraseña y confirmarla"
                );
            } 
            if ( this.validaMensaje.length > 0) {
                this.valida = 1;
            }
            return this.valida;
        },
        mensajeSnackBar(mensaje) {
            this.textPeticionRest = mensaje;
        },
    },
};
</script>