<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-btn icon @click="regresar()"><v-icon color="primary">arrow_back</v-icon></v-btn>
                <v-toolbar-title>Servicio </v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-toolbar-title>{{servicioAct.nombre}} <span>({{servicioAct.alias}})</span></v-toolbar-title>
                <v-spacer></v-spacer>
                {{servicioAct.tipoServicio}}
                
            </v-toolbar>
            <v-dialog v-model="addProducto" width="1000">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Configurar productos</span>
                        </v-card-title>   
                        <v-container>
                            <div>
                            <v-spacer></v-spacer>
                            <label>
                                Producto seleccionado: <b> {{this.productoAct != null ? this.productoAct.nombre : ""}}</b>
                            </label>
                            <v-spacer></v-spacer>                            
                            <label>
                                Cantidad:
                                <b><input v-model.number="cantidad" type="number" step="1"/></b>
                            </label>
                            </div>
                        </v-container>                     
                        <v-card-actions>
                            
                            <!-- Producto seleccionado: 
                            <v-text-field>{{this.productoAct != null ? this.productoAct.nombre: ""}}</v-text-field>
                            <input v-model.number="cantidad" type="number" step="1" min="0" max="1000" /> -->
                            
                                <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="setProducto(productoAct,2)">Agregar a servicio</v-btn>
                            <v-btn color="secondary" text @click="setProducto(null, 0)">Cancelar</v-btn>
                            
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-container>
                            <template>
                                <v-flex>
                                    <v-toolbar flat color="white">
                                        <v-toolbar-title>Producto</v-toolbar-title>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            class="text-xs-center"
                                            v-model="search"
                                            append-icon="search"
                                            label="Búsqueda"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </v-flex>
                            </template>
                            <v-data-table
                                :headers="headers"
                                :items="lstAllProduct"
                                :search="search"
                                :loading="cargando"
                                loading-text="Cargando datos... Por favor espere un momento"
                                class="elevation-1"
                            >
                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.codigoProducto }}</td>
                                    <td>{{ item.nombre }}</td>
                                    <td>{{ item.descripcion }}</td>
                                    <td class="justify-center layout px-0">
                                        <v-btn
                                        icon
                                        color="primary"
                                        @click="setProducto(item,1)"
                                        >
                                            <v-icon>send</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </template>
                                <template v-slot:no-data>
                                <v-btn color="primary" @click="obtenerDatos">Actualizar</v-btn>
                                </template>
                            </v-data-table>
                        </v-container>

                    </v-card>
            </v-dialog>
            <!-- LISTA DE PRODUCTOS DE SERVICIO -->
            <div>
                <v-list subheader two-line>
                    <v-divider></v-divider>
                    <v-subheader >
                        <v-toolbar-title>Detalle del servicio {{ lstProductsServicio.length >0 ? '(' + lstProductsServicio.length + ' prods.) ' : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2"
                        outlined
                        color="indigo"
                        @click="abrirAddProducto()"
                        >
                        Agregar
                        </v-btn>
                    </v-subheader>
                    <span v-if="lstProductsServicio.length <=0 && ! cargando" style="color: red;">  NO HAY DATOS</span>
                    <!-- <v-divider></v-divider> -->
                    
                    <v-list-item v-for="prod in lstProductsServicio" :key="prod._id">
                        <v-spacer></v-spacer>
                        <v-list-item-content>
                            <v-list-item-title >{{prod.producto.nombre}}</v-list-item-title>
                            <v-list-item-subtitle >Cantidad: {{prod.cantidad}}</v-list-item-subtitle>
                        </v-list-item-content>
                        
                        <v-spacer></v-spacer>
                        <v-list-item-action>
                            <v-btn icon>
                                <v-icon color="grey lighten-1" @click="setProducto(prod,3)">delete</v-icon>
                            </v-btn>
                         </v-list-item-action>
                         <v-spacer></v-spacer>
                    </v-list-item>
                </v-list>   
            </div>
            <div>
                <v-progress-linear
                    v-if="cargando"
                    indeterminate
                    color="accent"
                    height="8"
                ></v-progress-linear>
                <v-snackbar v-model="snackbar" :multi-line="multiLine">
                    {{ this.txtSnackBar }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from "axios";
export default {
    data(){
        return {
            //bar
            v0: true,
            //  model para agregar poroducto
            search: "",
            addProducto: 0,
            cantidad: 0,
            productoAct: null,
             headers: [
                { text: "Código", value: "codigoProducto", sortable: true },
                { text: "Nombre", value: "nombre", sortable: true },
                { text: "descripcion", value: "descripcion", sortable: true },
                { text: "Opciones", value: "opciones", sortable: false },
            ],
            //
            idServicio: "",
            servicioAct: {
                alias: "",
                descripcion: "",
                fechaRegistro: null,
                nombre: "",
                tipoServicios: "",
            },
            lstAllProduct: [],
            lstProductsDisp: [],
            lstProductsServicio: [],

            //snackbar
            cargando: false,
            multiLine: true,
            snackbar: false,
            txtSnackBar: '',
        };
    },
    coputed: {},
    watch: {},
    created(){
        this.idServicio = this.$route.params.id;
        this.obtenerDatos();
    },
    methods: {
        obtenerDatos(){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            axios
            .get(this.$store.state.servidor + "servicio/" + me.idServicio, configuracion)
            .then(function (response) {
                me.servicioAct = response.data.servicio;
                me.cargando = false;
            })
            .catch(function (error) {
                me.cargando = false;
                console.log(error);
            });
            me.lstProductsServicio = [];
            me.cargando = true;
            axios
            .get(this.$store.state.servidor + "productoDelServicio/" + me.idServicio, configuracion)
            .then(function (response) {
                
                const lstTemp = response.data.productosDelServicio;
                lstTemp.forEach(t => {
                    const prodLst = {
                        _id: t._id,
                        producto: t.Producto,
                        cantidad: t.cantidad
                    };    
                    me.lstProductsServicio.push(prodLst);
                });
                me.cargando = false;
            })
            .catch(function (error) {
                me.cargando = false;
                console.log(error);
            });
            
        },
        abrirAddProducto(){
            
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            axios
            .get(this.$store.state.servidor + "producto/0/0", configuracion)
            .then(function (response) {
                me.lstAllProduct = response.data.productos;
                me.addProducto = 1;
                me.cargando = false;
            })
            .catch(function (error) {
                console.log(error);
                me.addProducto = 0;
                me.cargando = false;
            });
        },
        setProducto(item, opcion){
            // CANCELAR
            if(opcion == 0) {
                this.addProducto = 0;
            }
            // SELECCIONA PRODUCTO
            if(opcion == 1) {
                this.productoAct = item;
                this.cantidad = 0;
            }
            // agrega producto a servicio
            if(opcion == 2) {
                const body = {
                    Servicio: this.idServicio,
                    Producto: item._id,
                    cantidad: this.cantidad
                };
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                axios
                .post(
                    this.$store.state.servidor + "productoServicio",
                    body,
                    configuracion
                )
                .then(function (response) {
                    me.cargando = false;
                    me.mensajeSnackBar("Se agregó correctamente el producto");
                    me.addProducto = 0;
                    me.obtenerDatos();
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log('error', error);
                    me.mensajeSnackBar("No se agregó el producto");
                })
                .finally(() => (this.snackbar = true));
            }
            if(opcion == 3) {
                
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                axios
                    .delete(
                    this.$store.state.servidor + "productoServicio/" + item._id,
                    configuracion
                    )
                    .then(function (response) {
                        me.mensajeSnackBar("Se elimino correctamente el producto");
                        me.cargando = false;
                        me.obtenerDatos();
                    })
                    .catch(function (error) {
                        me.cargando = false;
                        console.log('error', error);
                        me.mensajeSnackBar("No se puede eliminar el producto");
                    })
                    .finally(() => (this.snackbar = true));
            }
        },
        regresar(){
           this.$router.push({
                name: "servicios"
            });
        },
        mensajeSnackBar(mensaje){
            this.txtSnackBar = mensaje;
            this.snackbar = true
        },
    },
};
</script>