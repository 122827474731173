<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Paciente</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="appPaterno" label="Apellido Paterno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="appMaterno" label="Apellido Materno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="RFC" label="RFC"> </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 d-flex>
                    <v-select
                      :items="generos"
                      v-model="genero"
                      item-text="generos"
                      item-value="generos"
                      label="Genero"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="direccion" label="Dirección">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 md6
                    ><v-text-field
                      v-model="telefono"
                      label="Telefono"
                    ></v-text-field
                  ></v-flex>
                  <v-flex xs12 sm6 md6
                    ><v-text-field
                      v-model="alergia"
                      label="Alergía"
                    ></v-text-field
                  ></v-flex>
                  <v-flex xs12 sm6 md6
                    ><v-text-field
                      v-model="expediente"
                      label="Expediente"
                    ></v-text-field
                  ></v-flex>
                  <v-flex xs12 sm6 md6
                    ><v-text-field
                      v-model="seguro"
                      label="Seguro"
                    ></v-text-field
                  ></v-flex>

                  <v-flex xs12 sm6 d-flex>
                    <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="fechaNacimiento"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="fechaNacimiento"
                          label="Selecciona la fecha de caducidad"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fechaNacimiento" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="modal = false"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          color="primary"
                          @click="$refs.dialog.save(fechaNacimiento)"
                          >Asignar</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              al Paciente
              {{ adTitulo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="pacientes"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.nombre }} {{ item.appPaterno }} {{ item.appMaterno }}
            </td>
            <td :style="mostrarFaltantes(item.RFC )" >{{ item.RFC }}</td>
            <td :style="mostrarFaltantes(item.genero )" >{{ item.genero }}</td>
            <td :style="mostrarFaltantes(item.fechaNacimiento )" >{{ item.fechaNacimiento }}</td>
            <td :style="mostrarFaltantes(item.direccion )" >{{ item.direccion }}</td>
            <td :style="mostrarFaltantes(item.telefono )" >{{ item.telefono }}</td>
            <td :style="mostrarFaltantes(item.alergia )" >{{ item.alergia }}</td>
            <td :style="mostrarFaltantes(item.expediente )" >{{ item.expediente }}</td>
            <td :style="mostrarFaltantes(item.seguro )" >{{ item.seguro }}</td>

            <td class="justify-center layout px-0">
              <!--<v-btn icon target="_blank">
                <v-icon color="info" @click="obtenerInf(item)">info</v-icon>
              </v-btn>-->
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      modal: false,

      dialog: false,
      search: "",
      generos: ["Masculino", "Femenino", "Sin especificar"],
      pacientes: [],
      headers: [
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "RFC", value: "RFC", sortable: true },
        { text: "Genero", value: "genero", sortable: true },
        { text: "Fecha Nacimiento", value: "fechaNacimiento", sortable: true },
        { text: "Dirección", value: "direccion", sortable: true },
        { text: "Telefono", value: "telefono", sortable: true },
        { text: "Alergía", value: "alergia", sortable: true },
        { text: "Expediente", value: "expediente", sortable: true },
        { text: "Seguro", value: "seguro", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",

      nombre: "",
      appPaterno: "",
      appMaterno: "",
      RFC: "",
      genero: "",
      fechaNacimiento: "",
      direccion: "",
      telefono: "",
      alergia: "",
      expediente: "",
      seguro: "",

      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo paciente" : "Editar paciente";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    mostrarFaltantes(param){
      if(!param || param.length<1 || param==""){
        return "border-bottom-color : #FADA5E; border-bottom-width:7px;";
      } else {
        return "";
      }
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "persona/0/0", configuracion)
        .then(function (response) {
          me.pacientes = response.data.ListadoPersonas;
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.nombre = "";
      this.appPaterno = "";
      this.appMaterno = "";
      this.direccion = "";
      this.RFC = "";
      this.genero = "";
      this.telefono = "";
      this.alergia = "";
      this.expediente = "";
      this.seguro = "";

      this.valida = 0;
      this.fechaNacimiento = "";
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nombre.length < 3 || this.nombre.length > 50) {
        this.validaMensaje.push(
          "El nombre del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (this.appPaterno.length < 3 || this.appPaterno.length > 50) {
        this.validaMensaje.push(
          "El Apellido Paterno del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (this.appMaterno.length < 3 || this.appMaterno.length > 50) {
        this.validaMensaje.push(
          "El Apellido Materno del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (
        this.RFC.length != 0 &&
        (this.RFC.length < 12 || this.RFC.length > 13)
      ) {
        this.validaMensaje.push(
          "El RFC del paciente debe estar entre 12-13 caracteres."
        );
      }
      if (!this.genero) {
        this.validaMensaje.push("Debe seleccionar el genero del paciente");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "persona",
            {
              _id: this._id,
              nombre: this.nombre,
              direccion: this.direccion,
              appPaterno: this.appPaterno,
              appMaterno: this.appMaterno,
              fechaNacimiento: this.fechaNacimiento,
              telefono: this.telefono,
              alergia: this.alergia,
              expediente: this.expediente,
              seguro: this.seguro,
              RFC: this.RFC,
              genero: this.genero,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Paciente");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar el Paciente");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "persona",
            {
              nombre: this.nombre,
              direccion: this.direccion,
              appPaterno: this.appPaterno,
              appMaterno: this.appMaterno,
              fechaNacimiento: this.fechaNacimiento,
              telefono: this.telefono,
              alergia: this.alergia,
              expediente: this.expediente,
              seguro: this.seguro,
              RFC: this.RFC,
              genero: this.genero,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Paciente");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear el Paciente");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    obtenerInf(item) {
      this.$router.push({
        name: "informacionPaciente",
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this._id = item._id;
      this.nombre = item.nombre;
      this.direccion = item.direccion;
      this.appPaterno = item.appPaterno;
      this.appMaterno = item.appMaterno;
      this.fechaNacimiento = item.fechaNacimiento;
      this.RFC = item.RFC;
      this.genero = item.genero;
      this.telefono = item.telefono;
      this.alergia = item.alergia;
      this.expediente = item.expediente;
      this.seguro = item.seguro;

      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adTitulo =
        item.nombre + " " + item.appPaterno + " " + item.appMaterno;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "persona/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adTitulo = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente el Paciente");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Paciente");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>
