<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Fármaco</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template >
        <v-btn class="mb-2"  color="xls" @click="crearExcel">
          <v-icon color="white">fa-file-excel-o</v-icon>
        </v-btn>
      </template>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              style="margin-left: 10px"
              v-on="on"
              >Nuevo</v-btn
            >
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm3 md3>
                    <v-text-field v-model="codigoProducto" label="Código">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm9 md9>
                    <v-text-field v-model="nombre" label="Sustancia Activa">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-text-field v-model="descripcion" label="Concentración">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm5 md5>
                    <v-select
                      :items="UnidadDimension"
                      v-model="selectUnidadDimension"
                      label="Unidad de Dimensión"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 d-flex>
                    <v-select
                      :items="Departamento"
                      v-model="selectDepartamento"
                      item-text="nombre"
                      item-value="_id"
                      label="Categoria"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="minimoProducto"
                      type="Number"
                      min="0"
                      label="Mínimo"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="maximoProducto"
                      type="Number"
                      min="0"
                      label="Máximo"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="tags" label="Tags"> </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Fármaco
              {{ adNombre }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="transferirToSucursal" max-width="500">
          <v-card>
            <v-card-title class="headline">
              Transferir medicamento a Área
            </v-card-title>
            <v-card-text>
              Medicamento a transferir <br />
              Código: <b>{{ codigoProducto }}</b> <br />
              <b>{{ nombre }}</b> {{ descripcion }}
              <b>{{ selectUnidadDimension }}</b> <br />
              Máxima cantidad: {{ maxCantidadMedicamento }}
            </v-card-text>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm3 md3>
                    <v-text-field
                      v-model="cantidadMaterialToTransferir"
                      type="Number"
                      min="1"
                      :max="maxCantidadMedicamento"
                      label="Cantidad"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm9 md9>
                    <v-select
                      :items="areas"
                      v-model="seleccionArea"
                      item-value="_id"
                      item-text="alias"
                      item-disabled="disabled"
                      label="Selecciona una área"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm9 md9>
                    <v-text-field
                      v-model="comentariosTransferir"
                      label="Comentarios"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="cerrarTranferir()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="transferir()">
                Transferir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="ingresoMaterial" max-width="600px">
          <v-card>
            <v-card-title class="headline">
              {{
                isAgregarOrRetiro
                  ? "Ingresar Fármaco a Inventario"
                  : "Retirar Producto"
              }}
            </v-card-title>
            <v-card-text
              >Código: <b>{{ codigoProducto.toUpperCase() }}</b>
              <br />Descripción: <b>{{ nombre.toUpperCase() }}</b>
              {{ descripcion }} {{ selectUnidadDimension }} <br />
              Registra: <b>{{ UsuarioDescripcion }}</b>
            </v-card-text>
            <v-card-text>
              <v-flex xs12 sm4 md4>
                <v-text-field
                  v-model="cantidadIngresoMaterial"
                  label="Cantidad"
                  type="number"
                  min="0"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm8 md8>
                <v-text-field
                  v-model="comentariosMovimientoInventario"
                  label="comentarios"
                  item-text="nombre"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12 v-show="valida">
                <div
                  class="red--text"
                  v-for="v in validaMensaje"
                  :key="v"
                  v-text="v"
                ></div>
              </v-flex>

              <v-flex v-if="isAgregarOrRetiro == false" xs12 sm6 d-flex>
                <v-select
                  :items="tipoMovimientoInventario"
                  v-model="selectTipoMovimientoInventario"
                  label="Tipo"
                ></v-select>
              </v-flex>
              <v-flex xs12 sm6 d-flex>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="fechaCaducidad"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field v-if="isAgregarOrRetiro==true"
                      v-model="fechaCaducidad"
                      label="Selecciona la fecha de caducidad"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker  v-model="fechaCaducidad" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal = false"
                      >Cancelar</v-btn
                    >
                    <v-btn
                      color="primary"
                      @click="$refs.dialog.save(fechaCaducidad)"
                      >Asignar</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="ingresoMaterialSucursal()">
                {{ isAgregarOrRetiro ? "Ingresar Fármaco" : "Retirar Fármaco" }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="productos"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.codigoProducto }}</td>
            <td>
              <v-chip
                :color="
                  colorMaximosMinimos(item.minimo, item.maximo, item.cantidad)
                "
                >{{ item.cantidad }}
              </v-chip>
            </td>
            <td>{{ item.minimo }} / {{ item.maximo }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.descripcion }}</td>
            <td>{{ item.unidadDimension }}</td>
            <td>
              {{
                item.Departamento.nombre.includes("-Baja")
                  ? "No Disponible, necesario actualizar"
                  : item.Departamento.nombre
              }}
            </td>
            <td>{{ item.tags }}</td>

            <td class="justify-center layout px-0">
              <v-btn :disabled="item.cantidad <= 0 ? true : false" icon>
                <v-icon color="transfer" @click="transferAsucursal(item)"
                  >fa-exchange</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon
                  :disabled="item.cantidad <= 0 ? true : false"
                  color="remove"
                  @click="addProductoToInventario(item, false)"
                  >fa-minus-square</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="add" @click="addProductoToInventario(item, true)"
                  >fa-plus-square</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  data() {
    return {
      modal: false,
      dialog: false,
      transferirToSucursal: false,
      fechaCaducidad: "",
      maxCantidadMedicamento: 0,
      cantidadMaterialToTransferir: 1,
      comentariosTransferir: "",
      minimoProducto: 0,
      maximoProducto: 0,
      search: "",
      productos: [],
      inventarioProd: [],
      inventario: [],
      areas: [],
      seleccionArea: [],
      Departamento: [],
      UnidadDimension: [],
      filename: "",
      headers: [
        { text: "Código", value: "codigoProducto", sortable: true },
        { text: "Cantidad", value: "cantidad", sortable: true },
        { text: "Min/Max", value: "minimo", sortable: false },
        { text: "Sustancia Activa", value: "nombre", sortable: true },
        { text: "Concentración", value: "descripcion", sortable: true },
        {
          text: "Unidad de Dimension",
          value: "unidadDimension",
          sortable: true,
        },
        { text: "Categoria", value: "Departamento", sortable: true },
        { text: "Tags", value: "tags", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",
      codigoProducto: "",
      nombre: "",
      descripcion: "",
      selectDepartamento: false,
      tags: "",
      selectUnidadDimension: "",
      areaSeleccionadaFarmaciaHospitalaria: "",
      comentariosMovimientoInventario: "",

      tipoMovimientoInventario: ["Egreso", "Garantia", "A dato", "Ajuste"],
      selectTipoMovimientoInventario: "Egreso",

      snackbar: false,
      UsuarioDescripcion:
        localStorage.nombre.toUpperCase() +
        " " +
        localStorage.appPaterno.toUpperCase() +
        " " +
        localStorage.appMaterno.toUpperCase(),
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      cantidadIngresoMaterial: 0,
      adAccion: 0,
      ingresoMaterial: 0,
      adNombre: "",
      removeId: "",

      isAgregarOrRetiro: true,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Fármaco" : "Editar Fármaco";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    crearExcel() {
      let infTmp = [];
      this.productos.forEach((item) => {
        let variable = {
          "Código" : item.codigoProducto ,
          Cantidad: item.cantidad,
          min : item.minimo,
          max : item.maximo,
          "Sustancia Activa" : item.nombre,
          "Concentración" : item.descripcion,
          "Unidad de Dimensión" : item.unidadDimension,
          "Categoría" : item.Departamento.nombre.includes("-Baja")? "No Disponible, necesario actualizar" : item.Departamento.nombre,
          "Tags" : item.tags,
        };
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte_Farmacia_Hospitalaria";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    colorMaximosMinimos(minimo, maximo, cantidad) {
      if (cantidad < minimo) {
        return "menorToMin";
      } else if (cantidad > maximo) {
        return "mayorToMax";
      } else {
        return "entreMinToMax";
      }
    },
    transferir() {
      this.validarTransferencia();
      if (this.validarTransferencia()) {
        return;
      }
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .post(
          this.$store.state.servidor + "transferirProducto/",
          {
            SucursalOrigen: this.areaSeleccionadaFarmaciaHospitalaria,
            SucursalDestino: this.seleccionArea,
            cantidad: this.cantidadMaterialToTransferir,
            Producto: this._id,
            comentarios: this.comentariosTransferir,
          },
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";
          me.mensajeSnackBar("Se transfiere correctamente el Fármaco");
          me.listar();
          me.transferirToSucursal = false;
          me.comentariosTransferir = "";
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede transferir el Fármaco");
        })
        .finally(() => (this.snackbar = true));
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },

    cerrarTranferir() {
      this.transferirToSucursal = false;
    },
    async listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      await axios
        .get(
          this.$store.state.servidor + "sucursal/0/0?puedeIngresarInventario=true",
          configuracion
        )
        .then(function (response) {
          me.areas = response.data.Sucursales;
        })
        .catch(function (error) {
          console.log(error);
        });
      await axios
        .get(
          this.$store.state.servidor + "ConfiguracionAplicacionSucursal/FarmaciaHospitalaria",
          configuracion
        )
        .then(function (response) {
          me.areaSeleccionadaFarmaciaHospitalaria =
            response.data.configuracion.Sucursal;
        })
        .catch(function (error) {
          console.log(error);
        });
      await axios
        .get(
          this.$store.state.servidor + "inventario/" +
            me.areaSeleccionadaFarmaciaHospitalaria +
            "/0/0",
          configuracion
        )
        .then(function (respuesta) {
          me.inventarioProd = respuesta.data.inventario;
        })
        .catch(function (error) {
          console.log(error);
        });

      await axios
        .get(this.$store.state.servidor + "departamento/0/0", configuracion)
        .then(function (response) {
          me.Departamento = response.data.Departamentos;
        })
        .catch(function (error) {
          console.log(error);
        });
      await axios
        .get(
          this.$store.state.servidor + "unidadDimensionSustancias",
          configuracion
        )
        .then(function (response) {
          me.UnidadDimension = response.data.unidadDimensionSustancias;
        })
        .catch(function (error) {
          console.log(error);
        });

      await axios
        .get(this.$store.state.servidor + "producto/0/0", configuracion)
        .then(function (response) {
          me.productos = response.data.productos;
        })
        .catch(function (error) {
          console.log(error);
        });
      me.productos.forEach((element) => {
        element.cantidad = 0;
        this.inventarioProd.forEach((prod) => {
          if (prod.Producto) {
            if (prod.Producto._id == element._id) {
              element.cantidad = prod.cantidad;
            }
          }
        });
      });
      me.areas.forEach((element) => {
        if (element._id == me.areaSeleccionadaFarmaciaHospitalaria) {
          element.disabled = true;
        } else {
          element.disabled = false;
        }
      });

      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.codigoProducto = "";
      this.abreviaturaTicket = "";
      this.nombre = "";
      this.descripcion = "";
      this.cantidadIngresoMaterial = 0;
      this.selectDepartamento = "";
      this.tags = "";
      this.selectUnidadDimension = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
      this.minimoProducto = 0;
      this.maximoProducto = 0;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.codigoProducto.length < 4 || this.codigoProducto.length > 25) {
        this.validaMensaje.push(
          "El Código del farmaco debe tener entre 4-25 caracteres."
        );
      }
      if (this.nombre.length < 4 || this.nombre.length > 75) {
        this.validaMensaje.push(
          "La sustancia activa del farmaco debe tener entre 4-75 caracteres."
        );
      }
      if (this.descripcion.length < 3 || this.descripcion.length > 250) {
        this.validaMensaje.push(
          "La concentración farmaco debe tener entre 3-250 caracteres."
        );
      }
      if (this.selectDepartamento.length < 1) {
        this.validaMensaje.push(
          "Debe seleccionar el departamento del farmaco."
        );
      }
      if (this.UnidadDimension.length < 1) {
        this.validaMensaje.push(
          "Debe seleccionar el unidad de dimensión del farmaco."
        );
      }
      if (parseFloat(this.minimoProducto) > parseFloat(this.maximoProducto)) {
        this.validaMensaje.push(
          "El minimo debe de ser igual o menor que el máximo"
        );
      }
      if (parseFloat(this.minimoProducto) < 0) {
        this.validaMensaje.push("El minimo debe de ser igual o mayor que 0");
      }
      if (parseFloat(this.maximoProducto) < 0) {
        this.validaMensaje.push("El máximo debe de ser igual o mayor que 0");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    validarIngresoEgreso() {
      this.valida = 0;
      this.validaMensaje = [];

      if (this.cantidadIngresoMaterial == 0) {
        this.validaMensaje.push("No puedes agregar cero al inventario");
      }
      if (this.comentariosMovimientoInventario.length < 1) {
        this.validaMensaje.push("Necesitas agregar comentarios del movimiento");
      }
      if (this.isAgregarOrRetiro==true && this.fechaCaducidad.length<10){
        this.validaMensaje.push("Necesitas Seleccionar la fecha");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    validarTransferencia() {
      this.valida = 0;
      this.validaMensaje = [];

      if (this.cantidadMaterialToTransferir <= 0) {
        this.validaMensaje.push("La cantidad a transferir debe ser mayor a 0");
      }
      if (this.seleccionArea.length <= 0) {
        this.validaMensaje.push("Debe seleccionar un area para transferir");
      }
      if (this.comentariosTransferir.length < 5) {
        this.validaMensaje.push(
          "Debe escribir un comentario mayor a 5 caracteres"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },

    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {


        console.log(me.areaSeleccionadaFarmaciaHospitalaria);
          axios 
          .put(
            this.$store.state.servidor + "actInventario",
            {
              Sucursal : me.areaSeleccionadaFarmaciaHospitalaria,
              minimo: this.minimoProducto,
              maximo: this.maximoProducto,
              Producto: this._id,
            },
            configuracion
          )
          .then(function (response) {
          });
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "producto",
            {
              _id: this._id,
              codigoProducto: this.codigoProducto,
              abreviaturaTicket: this.nombre.substring(0, 4),
              nombre: this.nombre,
              descripcion: this.descripcion,
              Departamento: this.selectDepartamento,
              tags: this.tags,
              perecedero: true,
              marca: "Interna",
              equipoElectrico: false,
              dimension: 1,
              unidadDimension: this.selectUnidadDimension,
              minimo: this.minimoProducto,
              maximo: this.maximoProducto,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Fármaco");
          })
          .catch(function (error) {
            me.mensajeSnackBar(
              "No se puede actualizar el Fármaco, verifique sus datos y que no se repita el Código de Fármaco"
            );
          })
          .finally(() => (this.snackbar = true));


          //actInventario
          
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "producto",
            {
              codigoProducto: this.codigoProducto,
              abreviaturaTicket: this.nombre.substring(0, 4),
              nombre: this.nombre,
              descripcion: this.descripcion,
              Departamento: this.selectDepartamento,
              tags: this.tags,
              perecedero: true,
              marca: "Interna",
              equipoElectrico: false,
              dimension: 1,
              unidadDimension: this.selectUnidadDimension,
              minimo: this.minimoProducto,
              maximo: this.maximoProducto,
              //unidadDimension : "pieza"
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Fármaco");

            ///axios.post(
            ///  this.$store.state.servidor + "agregarProducto/",
            ///  {
            ///    Sucursal: me.areaSeleccionadaFarmaciaHospitalaria,
            ///    cantidad: 0,
            ///    Producto: response,
            ///    comentarios: this.comentariosMovimientoInventario,
            ///    tipo: tempTipo,
            ///  },
            ///  configuracion
            ///);
          })
          .then(function (response) {})
          .catch(function (error) {
            me.mensajeSnackBar(
              "no se puede crear el Fármaco, verifique que los datos son correctos y el código de producto no se utilizo con anterioridad "
            );
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.codigoProducto = item.codigoProducto;
      this.selectDepartamento =
        item.Departamento.nombre.includes("-Baja") == true
          ? ""
          : item.Departamento;

      this.tags = item.tags;
      this.selectUnidadDimension = item.unidadDimension;
      this.minimoProducto = item.minimo;
      this.maximoProducto = item.maximo;

      this.dialog = true;
      this.editedIndex = 1;
    },
    addProductoToInventario(item, band) {
      this.isAgregarOrRetiro = band;
      this._id = item._id;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.codigoProducto = item.codigoProducto;
      this.selectUnidadDimension = item.unidadDimension;
      //this.comentariosAgregarUnidad = "";
      this.comentariosMovimientoInventario = "";
      this.dialog = false;
      this.editedIndex = -1;
      this.ingresoMaterial = 1;
    },
    transferAsucursal(item) {
      this._id = item._id;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.codigoProducto = item.codigoProducto;
      this.selectUnidadDimension = item.unidadDimension;
      //this.comentariosAgregarUnidad = "";
      this.comentariosMovimientoInventario = "";
      this.dialog = false;

      this.transferirToSucursal = true;
      this.maxCantidadMedicamento = item.cantidad;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.removeId = item._id;
      this.adAccion = 2;
      this.ingresoMaterial = 0;
    },
    DesactivarCerrar() {
      this.adModal = 0;
      this.ingresoMaterial = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "producto/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente el Fármaco");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Fármaco");
        })
        .finally(() => (this.snackbar = true));
    },
    ingresoMaterialSucursal() {
      if (this.validarIngresoEgreso()) {
        return;
      }
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      let tempUrl = this.isAgregarOrRetiro
        ? this.$store.state.servidor + "agregarProducto/"
        : this.$store.state.servidor + "retirarProducto/";
      let tempTipo = this.isAgregarOrRetiro
        ? "Ingreso"
        : this.selectTipoMovimientoInventario;
      axios
        .post(
          tempUrl,
          {
            Sucursal: this.areaSeleccionadaFarmaciaHospitalaria,
            cantidad: this.cantidadIngresoMaterial,
            Producto: this._id,
            comentarios: this.comentariosMovimientoInventario,
            tipo: tempTipo,
            fechaCaducidad : this.fechaCaducidad
          },
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";

          this.isAgregarOrRetiro
            ? me.mensajeSnackBar(
                "Se agrego correctamente la cantidad a Farmacia Hospitalaria"
              )
            : me.mensajeSnackBar(
                "Se retiro correctamente la cantidad a Farmacia Hospitalaria"
              );
          
          me.listar();
        })
        .catch(function (error) {
          this.isAgregarOrRetiro
            ? me.mensajeSnackBar(
                "No se puede agregar la cantidad a Farmacia Hospitalaria"
              )
            : me.mensajeSnackBar(
                "No se puede retirar la cantidad a Farmacia Hospitalaria"
              );
        })
        .finally(() => (this.snackbar = true));
      this.ingresoMaterial = 0;
      this.cantidadIngresoMaterial = 0;
      this.comentariosMovimientoInventario = "";
      //this.ingresoMaterialSucursal = "";
      this.fechaCaducidad = "";
      this.listar();
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>
