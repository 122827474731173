<template>
  <v-container align:center>
    <v-layout align-start>

      <v-spacer></v-spacer>
      <v-flex xs12 sm8 md8 lg8 xl8>
        <v-card
          flat
          tile
          elevation="24"
          class="d-flex"
          width="90%"
          style="position: center; float: center"
        >
          <v-img src="logo.png"></v-img>
        </v-card>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {};
  },
};
</script>
