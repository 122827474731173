import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import es from 'vuetify/lib/locale/es';
import { mdiAccountCogOutline } from '@mdi/js';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#8B008B',
        secondary: '#C71585',
        accent: '#800080',
        error: '#4B0082',
        info: '#483D8B',
        success: '#9933CC',
        warning: '#FFC107',
        edit: '#008F39',
        delete: '#FF0000',
        inner: '#009B7D',
        noInner: '#B29E9E',
        departament : '#000000',
        security: '#572364',
        cancel: '#56008C',
        ok: '#620096',
        new:'#7900AC',
        more:'#9032BB',
        add:'#AF69CD',
        less:'#CB9BDE',
        remove:'#E6CCEF',
        transfer: '#5416B4',
        menorToMin: '#F0C0C0',
        mayorToMax: '#FAED27',
        entreMinToMax: '#AEF359',
        pdf : '#CB3234',
        csv : '#006600',
        xls : '#006600',
        temp : '#cccccc',
        calendar : '#3B83BD',
        permiso : '#BA55D3',
        open : '#046307',
        close : '#900D09',
        cardInfo : '#FEF8FE'
      },
    },
  },
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'md',
  },
});
