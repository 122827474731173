<template>
  <v-layout wrap>
    <v-flex xs12 sm8 md8>
      <v-card-text>
        <h2>
          Validaciones Pendientes de autorización - Transferencia de tu Sucursal
        </h2>
      </v-card-text>
    </v-flex>

    <v-flex xs12 sm4 md4>
      <v-select
        :items="sucursales"
        :loading="loadingSelectSucursal"
        v-model="selectSucursal"
        v-on:change="obtenerProductosSucursal"
        item-text="alias"
        item-value="_id"
        label="Selecciona Area"
      >
      </v-select>
    </v-flex>

    <v-container grid-list-md>
      <v-layout wrap align-center>
        <v-flex
          xs12
          sm4
          md4
          lg4
          xl4
          v-for="item in validacionesPendientes"
          :key="item._id"
        >
          <v-card elevation="2">
            <v-card-title
              >{{ item.Producto?item.Producto.nombre:"" }} {{ item.Producto?item.Producto.descripcion:"" }}
              {{ item.Producto?item.Producto.unidadDimension:"" }}
            </v-card-title>
            <v-card-subtitle>
              Cantidad: <b>{{ item.cantidad }}</b>
            </v-card-subtitle>
            <v-card-text>
              <p>
                Sucursal de Origen : <br /><b
                  >{{
                    item.SucursalOrigen
                      ? item.SucursalOrigen.alias
                      : "Sin información"
                  }}
                </b>
              </p>
              <p>
                Sucursal de Destino : <br /><b
                  >{{
                    item.SucursalDestino
                      ? item.SucursalDestino.alias
                      : "Sin información"
                  }}
                </b>
              </p>

              <p>
                Genero la transferencia : <br /><b
                  >{{
                    item.Registra
                      ? item.Registra.nombre +
                        " " +
                        item.Registra.appPaterno +
                        " " +
                        item.Registra.appMaterno
                      : "Sin información"
                  }}
                </b>
              </p>
              <p>
                Fecha de la transferencia : <br /><b
                  >{{
                    item.fechaRegistro
                      ? item.fechaRegistro.substring(0, 10) +
                        " a las " +
                        item.fechaRegistro.substring(11, 16)
                      : "Sin información"
                  }}
                </b>
              </p>
              <p>
                Comentarios : <br /><b
                  >{{
                    item.comentarios
                  }}
                </b>
              </p>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" text @click="validarTransferencia(item)">
                Validar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-flex xs12 sm8 md8>
      <v-card-text>
        <h2>
          Validaciones Pendientes de autorización - Transferencia para tu
          Sucursal
        </h2>
      </v-card-text>
    </v-flex>

    <v-container grid-list-md>
      <v-layout wrap align-center>
        <v-flex
          xs12
          sm4
          md4
          lg4
          xl4
          v-for="item in validacionesPendientesParaTi"
          :key="item._id"
        >
          <v-card elevation="2">
            <v-card-title
              >{{ item.Producto.nombre }} {{ item.Producto.descripcion }}
              {{ item.Producto.unidadDimension }}
            </v-card-title>
            <v-card-subtitle>
              Cantidad: <b>{{ item.cantidad }}</b>
            </v-card-subtitle>
            <v-card-text>
              <p>
                Sucursal de Origen : <br /><b
                  >{{
                    item.SucursalOrigen
                      ? item.SucursalOrigen.alias
                      : "Sin información"
                  }}
                </b>
              </p>
              <p>
                Sucursal de Destino : <br /><b
                  >{{
                    item.SucursalDestino
                      ? item.SucursalDestino.alias
                      : "Sin información"
                  }}
                </b>
              </p>

              <p>
                Genero la transferencia : <br /><b
                  >{{
                    item.Registra
                      ? item.Registra.nombre +
                        " " +
                        item.Registra.appPaterno +
                        " " +
                        item.Registra.appMaterno
                      : "Sin información"
                  }}
                </b>
              </p>
              <p>
                Fecha de la transferencia : <br /><b
                  >{{
                    item.fechaRegistro
                      ? item.fechaRegistro.substring(0, 10) +
                        " a las " +
                        item.fechaRegistro.substring(11, 16)
                      : "Sin información"
                  }}
                </b>
              </p>
               <p>
                Comentarios : <br /><b
                  >{{
                    item.comentarios
                  }}
                </b>
              </p>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    moment.locale("es-mx");

    return {
      validacionesPendientes: [],
      validacionesPendientesParaTi: [],
      sucursales: [],
      loadingSelectSucursal: "",
      selectSucursal: "",
    };
  },
  created() {
    this.obtenerSucursalesPosibles();
  },
  methods: {
    validarTransferencia(item) {
      this.confirmarTransferencia(item);
    },
    obtenerProductosSucursal() {
      this.obtenerTransferenciasPosibles();
      this.obtenerTransferenciasPendientes();
    },
    obtenerSucursalesPosibles() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      axios
        .get(
          this.$store.state.servidor + "personalResponsableSucursal",
          configuracion
        )
        .then(function (response) {
          me.sucursales = response.data.sucursales;
          if (me.sucursales.length == 1) {
            selectSucursal = me.sucursales[0];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      me.loadingSelectSucursal = false;
    },
    obtenerTransferenciasPosibles() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      axios
        .get(
          this.$store.state.servidor + "movimientosPendientesRegistrarCEYE/" +
            me.selectSucursal,
          configuracion
        )
        .then(function (response) {
          me.validacionesPendientes = response.data.movimientosInventario;
        })
        .catch(function (error) {
          console.log(error);
        });
      me.loadingSelectSucursal = false;
    },
    obtenerTransferenciasPendientes() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      axios
        .get(
          this.$store.state.servidor + "movimientosPendientesRecibirCEYE/" +
            me.selectSucursal,
          configuracion
        )
        .then(function (response) {
          me.validacionesPendientesParaTi = response.data.movimientosInventario;
        })
        .catch(function (error) {
          console.log(error);
        });
      me.loadingSelectSucursal = false;
    },
    confirmarTransferencia(item) {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      axios
        .put(
          this.$store.state.servidor + "validarRecpecionMovimientoCEYE/",
          { id: item._id },
          configuracion
        )
        .then(function (response) {
          //me.validacionesPendientes = response.data.movimientosInventario;
        })
        .catch(function (error) {})
        .finally(()=>this.obtenerProductosSucursal());
      
      me.loadingSelectSucursal = false;
    },
  },
};
</script>
