import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
//import Editor from 'vue-editor-js'
import VueCompositionApi from '@vue/composition-api'

// Sockets
import VueSocketIO from 'vue-socket.io'



///Vue.use(new VueSocketIO({
///  debug: true,
///  connection: 'http://logiaerp.herokuapp.com',
///  vuex: {
///      store,
///      actionPrefix: 'SOCKET_',
///      mutationPrefix: 'SOCKET_'
///  },
///  options: { path: "/" } //Optional options
///}))




Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  VueCompositionApi,
  iconfont: 'fa4',
  render: h => h(App)

  
}).$mount('#app')



