<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Servicio</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="alias" label="Alias"> </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      @change="calcularPrecio"
                      :items="tipoServicio"
                      v-model="selectTipoServicio"
                      label="Tipo de Servicio"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="precio" label="Precio">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="descripcion" label="Descripción">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Servicio
              {{ adTitulo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="servicios"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.alias }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.descripcion }}</td>
            <td>{{ item.tipoServicio }}</td>
            <td>{{ item.precio }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="primary" @click="irProductos(item)"
                  >settings</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      servicios: [],
      tipoServicio: ["", "Consulta", "Hospitalización", "Venta", "Cirugía"],
      selectTipoServicio: "",
      headers: [
        { text: "Alias", value: "alias", sortable: true },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        { text: "Tipo", value: "tipoServicio", sortable: true },
        { text: "precio", value: "precio", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",

      alias: "",
      nombre: "",
      descripcion: "",
      precio: 0,

      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo servicio" : "Editar servicio";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "servicio/0/0", configuracion)
        .then(function (response) {
          me.servicios = response.data.Servicios;
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.selectTipoServicio = "";
      this.alias = "";
      this.nombre = "";
      this.precio = 0;
      this.descripcion = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.alias.length < 3 || this.alias.length > 50) {
        this.validaMensaje.push(
          "La alias del servicio debe tener entre 3-50 caracteres."
        );
      }
      if (this.nombre.length < 5) {
        this.validaMensaje.push(
          "El nombre debe contener al menos 5 caracteres"
        );
      }
      if (isNaN(this.precio)) {
        this.validaMensaje.push("El Precio debe ser número");
      } else {
        if (parseFloat(this.precio) <= 0) {
          this.validaMensaje.push("El Precio debe ser mayor a cero");
        }
      }
      if (this.descripcion) {
        if (this.descripcion.length < 10 && this.descripcion.length > 0) {
          this.validaMensaje.push(
            "La descripción debe contener al menos 10 caracteres"
          );
        }
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "servicio",
            {
              id: this._id,
              alias: this.alias,
              descripcion: this.descripcion,
              nombre: this.nombre,
              precio: this.precio,
              tipoServicio: this.selectTipoServicio,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Servicio");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar el Servicio");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "servicio",
            {
              alias: this.alias,
              descripcion: this.descripcion,
              nombre: this.nombre,
              precio: this.precio,
              tipoServicio: this.selectTipoServicio,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Servicio");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear el Servicio");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.alias = item.alias;
      this.descripcion = item.descripcion;
      this.selectTipoServicio = item.tipoServicio;
      this.nombre = item.nombre;
      this.precio = item.precio;
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adAlias = item.alias;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },
    irProductos(item) {
      this.$router.push({
        name: "sertvicioProductos",
        params: {
          id: item._id,
        },
      });
    },
    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "servicio/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adTitulo = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente el Servicio");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Servicio");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
    calcularPrecio() {},
  },
};
</script>
