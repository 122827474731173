<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Concentrado de Farmacos</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template>
        <v-btn color="xls" @click="crearExcel">
          <v-icon color="white">fa-file-excel-o</v-icon>
        </v-btn>
      </template>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="inventario"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.descripcion ? item.descripcion.codigoProducto : "" }}
            </td>
            <td>
              {{ item.descripcion ? item.descripcion.nombre : "" }}
              {{ item.descripcion ? item.descripcion.descripcion : "" }}
              {{ item.descripcion ? item.descripcion.unidadDimension : "" }}
            </td>
            <td>
              <v-chip
                :color="
                  colorMaximosMinimos(
                    item.minimoTotal,
                    item.maximoTotal,
                    item.cantidadTotal
                  )
                "
                >{{ item.cantidadTotal }}
              </v-chip>
            </td>
            <td>{{ item.minimoTotal }} / {{ item.maximoTotal }}</td>
            <td>{{ item.cantidadTotal-item.minimoTotal>0?0:item.minimoTotal-item.cantidadTotal}}</td>
            <td>{{ item.cantidadTotal-item.maximoTotal>0?0:item.maximoTotal-item.cantidadTotal}}</td>

            <td>
              <span v-for="itmTemp in item.Inventario" :key="itmTemp._id">
                {{ itmTemp.Sucursal ? itmTemp.Sucursal.alias : "" }}:
                <v-chip
                  :color="
                    colorMaximosMinimos(
                      itmTemp.minimo,
                      itmTemp.maximo,
                      itmTemp.cantidad
                    )
                  "
                >
                  {{ itmTemp.cantidad }}
                </v-chip>
              </span>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="obtenerDatosInventario()">Actualizar</v-btn>
        </template>
      </v-data-table>

      
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";

// options components
export default {
  data() {
    return {
      headers: [
        { text: "Codigo", value: "descripcion.codigoProducto", sortable: true },
        { text: "Descripcion", value: "descripcion.nombre", sortable: true },
        { text: "Cantidad total", value: "descripcion.codigoProducto", sortable: true },
        {
          text: "Minimo Total / Maximo Total",
          value: "cantidadTotal",
          sortable: false,
        },
        { text: "Pedido Minimo", value: "minimoTotal-cantidadTotal", sortable: false },
        { text: "Pedido Maximo", value: "maximoTotal-cantidadTotal", sortable: false },
        { text: "cantidad", value: "cantidad", sortable: false },
      ],
      productos: [],
      Sucursales: [],
      inventario: [],
      inventarioUbicacion: [],
      search: "",
      loadingTable: false,
    };
  },
  created() {
    this.obtenerDatosInventario();
  },
  methods: {
    crearExcel() {
      let infTmp = [];
      this.inventario.forEach((item) => {
        let variable = {
          Codigo: item.descripcion ? item.descripcion.codigoProducto : "",
          Descripcion: item.descripcion
            ? item.descripcion.nombre
            : "" + " " + item.descripcion
            ? item.descripcion.descripcion
            : "" + " " + item.descripcion
            ? item.descripcion.unidadDimension
            : "",
          "Cantidad total ": item.cantidadTotal || 0,
          "Minimo Total": item.minimoTotal,
          "Maximo Total": item.maximoTotal,
          "Solicitud Minima" : item.cantidadTotal-item.minimoTotal>0?0:item.minimoTotal-item.cantidadTotal,
          "Solicitud Maxima" : item.cantidadTotal-item.maximoTotal>0?0:item.maximoTotal-item.cantidadTotal,
        };
        item.Inventario.forEach((itmTemp) => {
          variable[itmTemp.Sucursal.alias] = itmTemp.cantidad;
        });
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte_Inventario_General";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    colorMaximosMinimos(minimo, maximo, cantidad) {
      if (cantidad < minimo) {
        return "menorToMin";
      } else if (cantidad > maximo) {
        return "mayorToMax";
      } else {
        return "entreMinToMax";
      }
    },
    async obtenerDatosInventario() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingTable = true;
      await axios
        .get(
          this.$store.state.servidor + "inventarioConcentrado",
          configuracion
        )
        .then(function (response) {
          me.inventario = response.data.inventarioConcentradoAreas;
          let temp = [];

          me.inventario.forEach((producto) => {
            let ubicTemp = "";

            producto.Inventario.forEach((ubicacion) => {
              if (ubicacion.Sucursal != null) {
                ubicTemp +=
                  ubicacion.Sucursal.alias +
                  ": " +
                  ubicacion.cantidad +
                  "  (" +
                  ubicacion.minimo +
                  " / " +
                  ubicacion.maximo +
                  ")  ";
              }
            });
            producto.Inventario.inventarioExplicito = ubicTemp;
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      me.loadingTable = false;
    },
  },
};
</script>