<template>
  <v-flex xs12 sm12 md12>
    <v-stepper v-model="apuntadorCasilla" vertical>
      <v-stepper-step step="1">
        Datos del Paciente
        <small>Verificación de datos</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-card color="cardInfo" class="mb-12" elevation="3">
          <v-card-text align="right"
            >Fecha de Registro:
            <b>{{
              ticket.fechaRegistro
                ? ticket.fechaRegistro.substring(0, 10)
                : " Sin Información"
            }}</b>
            <br />
            Fecha de Cita:
            <b>{{
              ticket.Cliente
                ? ticket.Cliente.fechaIngreso.substring(0, 10)
                : " Sin Información"
            }}</b>
            <br />
            Estado : <b>{{ ticket.isClosed == true ? "Cerrado" : "Abierto" }}</b
            ><br />
            Pago :
            <b>{{ ticket.isPagado == true ? "Pendiente" : "Cubierto" }}</b
            ><br />
          </v-card-text>
          Paciente: <br />
          <b
            >{{ ticket.Cliente ? ticket.Cliente.nombre : "" }}
            {{ ticket.Cliente ? ticket.Cliente.appPaterno : "" }}
            {{ ticket.Cliente ? ticket.Cliente.appMaterno : "" }}
          </b>
          <br />
          Acompañante: <br />
          <b>{{ ticket.Cliente ? ticket.Cliente.acompanante : "" }} </b>
          <br />
          Código de Consulta: <br />
          <b
            >{{ ticket.url ? ticket.url : "Sin código de consulta" }}
            <v-btn icon
              ><v-icon color="edit" @click="actualizarDatos('url')"
                >fa-pencil</v-icon
              ></v-btn
            >
          </b>
          <br />
          Comentarios: <br />
          <b
            >{{
              !ticket.comentarios || ticket.comentarios == ""
                ? "Sin comentarios"
                : ticket.comentarios
            }}<v-btn icon
              ><v-icon color="edit" @click="actualizarDatos('comentarios')"
                >fa-pencil</v-icon
              ></v-btn
            >
          </b>
          <br />
        </v-card>
        <div align="right">
          <v-btn-toggle>
            <v-btn color="primary" @click="apuntadorCasilla = 2">
              Siguiente
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-stepper-content>

      <v-stepper-step step="2"> Datos para permisos </v-stepper-step>

      <v-stepper-content step="2">
        <v-card color="cardInfo" class="mb-12">
          <br />
          Cirugía <br /><b
            >{{
              ticket.cirugia != "" &&
              ticket.cirugia != " " &&
              ticket.cirugia != "undefined"
                ? cirugia
                : "Sin definición"
            }}<v-btn icon
              ><v-icon color="edit" @click="actualizarDatos('cirugia')"
                >fa-pencil</v-icon
              ></v-btn
            > </b
          ><br />
          Médico <br /><b
            >{{
              medicoNombre != "" &&
              medicoNombre != " " &&
              medicoNombre != "undefined"
                ? medicoNombre
                : "Sin definición"
            }}<v-btn icon
              ><v-icon color="edit" @click="actualizarDatos('medicoNombre')"
                >fa-pencil</v-icon
              ></v-btn
            ></b
          ><br />
          Hora <br /><b
            >{{
              hora != "" && hora != " " && hora != "undefined"
                ? hora
                : "Sin definición"
            }}<v-btn icon>
              <v-icon color="edit" @click="actualizarDatos('hora')"
                >fa-pencil</v-icon
              ></v-btn
            ></b
          >
          <br />
          Habitación <br /><b
            >{{
              habitacion != "" && habitacion != " " && habitacion != "undefined"
                ? habitacion
                : "Sin definición"
            }}<v-btn icon>
              <v-icon color="edit" @click="actualizarDatos('habitacion')"
                >fa-pencil</v-icon
              ></v-btn
            ></b
          ><br />
          Diagnóstico <br /><b>{{
            diagnostico != "" &&
            diagnostico != " " &&
            diagnostico != "undefined"
              ? diagnostico
              : "Sin definición"
          }}</b
          ><v-btn icon
            ><v-icon color="edit" @click="actualizarDatos('diagnostico')"
              >fa-pencil</v-icon
            ></v-btn
          >

          <br />
          Fecha <br /><b>{{
            fecha != "" && fecha != " " && fecha != "undefined"
              ? fecha
              : "Sin definición"
          }}</b
          ><br />
          Fecha de Nacimiento <br /><b>{{
            fechaNacimiento != "" &&
            fechaNacimiento != " " &&
            fechaNacimiento != "undefined"
              ? fechaNacimiento
              : "Sin definición"
          }}</b
          ><br />
          Edad <br /><b>{{
            edad != "" && edad != " " && edad != "undefined"
              ? edad
              : "Sin definición"
          }}</b
          ><br />
          Dirección <br /><b>{{
            direccion != "" && direccion != " " && direccion != "undefined"
              ? direccion
              : "Sin definición"
          }}</b
          ><br />
          Telefono <br /><b>{{
            telefono != "" && telefono != " " && telefono != "undefined"
              ? telefono
              : "Sin definición"
          }}</b
          ><br />
          Alergía <br /><b>{{
            alergia != "" && alergia != " " && alergia != "undefined"
              ? alergia
              : "Sin definición"
          }}</b
          ><br />
          Expediente <br /><b>{{
            expediente != "" && expediente != " " && expediente != "undefined"
              ? expediente
              : "Sin definición"
          }}</b
          ><br />
          Seguro <br /><b>{{
            seguro != "" && seguro != " " && seguro != "undefined"
              ? seguro
              : "Sin definición"
          }}</b
          ><br />
          Recepcionista <br /><b>{{
            nombreRecepcionista != "" &&
            nombreRecepcionista != " " &&
            nombreRecepcionista != "undefined"
              ? nombreRecepcionista
              : "Sin definición"
          }}</b
          ><br />

          Acompañante <br /><b
            >{{ ticket.Cliente ? ticket.Cliente.acompanante : "" }} </b
          ><br />

          Parentesco <br /><b
            >{{
              Parentesco != "" && Parentesco != " " && Parentesco != "undefined"
                ? Parentesco
                : "Sin definición"
            }}<v-btn icon
              ><v-icon color="edit" @click="actualizarDatos('Parentesco')"
                >fa-pencil</v-icon
              ></v-btn
            ></b
          ><br />
          Teléfono del Acompañante <br /><b
            >{{
              telefonoAcompanante != "" &&
              telefonoAcompanante != " " &&
              telefonoAcompanante != "undefined"
                ? telefonoAcompanante
                : "Sin definición"
            }}<v-btn icon
              ><v-icon
                color="edit"
                @click="actualizarDatos('telefonoAcompanante')"
                >fa-pencil</v-icon
              ></v-btn
            > </b
          ><br />
          Dirección del Acompañante <br /><b
            >{{
              direccionAcompanante != "" &&
              direccionAcompanante != " " &&
              direccionAcompanante != "undefined"
                ? direccionAcompanante
                : "Sin definición"
            }}<v-btn icon
              ><v-icon
                color="edit"
                @click="actualizarDatos('direccionAcompanante')"
                >fa-pencil</v-icon
              ></v-btn
            ></b
          ><br />
          <br />
        </v-card>
        <div align="right">
          <v-btn-toggle>
            <v-btn color="primary" @click="apuntadorCasilla = 1"> atras </v-btn>

            <v-btn color="primary" @click="apuntadorCasilla = 3">
              Siguiente
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-stepper-content>

      <v-stepper-step :complete="apuntadorCasilla > 3" step="3">
        Formato de Permisos
      </v-stepper-step>

      <v-stepper-content step="3">
        <v-card color="cardInfo" class="mb-12">
          <br />
          <v-btn color="primary" style="margin-width: 10px" @click="format_1()">
            Formato Consentimiento Quirúrgica
          </v-btn>
          <v-btn color="primary" style="margin-width: 10px" @click="format_2()">
            Autorización
          </v-btn>
          <v-btn color="primary" style="margin-width: 10px" @click="format_3()">
            Caratula
          </v-btn>
          <v-btn color="primary" style="margin-width: 10px" @click="format_4()">
            Consentimiento Anestesia
          </v-btn>
          <v-btn color="primary" style="margin-width: 10px" @click="format_5()">
            Consentimiento Parto
          </v-btn>
          <v-btn color="primary" style="margin-width: 10px" @click="format_6()">
            Contrato
          </v-btn>
          <br />
        </v-card>
        <div align="right">
          <v-btn-toggle>
            <v-btn color="primary" @click="apuntadorCasilla = 2"> atras </v-btn>

            <v-btn color="primary" @click="apuntadorCasilla = 4">
              Siguiente
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-stepper-content>

      <v-stepper-step step="4"> Asignación de servicios </v-stepper-step>
      <v-stepper-content step="4">
 
      <v-list shaped>
        <v-subheader>Seleccione el servicio</v-subheader>
        <v-list-item-group
          v-model="selectedItem"
          color="primary"
        >
          <v-list-item
            v-for="(item, indice) in lstServicios"
            
            :key="indice"
          >
            
            <v-list-item-content @click="seleccionarServicio(item)">
              <v-list-item-title v-text="item.nombre"></v-list-item-title>
              <v-list-item-subtitle v-text="item.descripcion"></v-list-item-subtitle>
              <v-list-item-subtitle v-text="item._id"></v-list-item-subtitle>
              $ {{item.precio}}
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    


        <div align="right">
          <v-btn-toggle>
            <v-btn color="primary" @click="apuntadorCasilla = 3"> atras </v-btn>

            <v-btn color="primary" @click="apuntadorCasilla = 1">
              Siguiente
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-stepper-content>
    </v-stepper>

    <v-dialog v-model="dialog" max-width="500px">
      
      <v-card>
        <v-card-title>
          <span class="headline">Información del paciente</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex v-if="mostrar('cirugia')" xs12 sm6 md6>
                <v-text-field v-model="cirugia" label="Cirugía"> </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('comentarios')" xs12 sm6 md6>
                <v-text-field v-model="comentarios" label="Comentarios">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('diagnostico')" xs12 sm12 md12>
                <v-text-field v-model="diagnostico" label="Diagnóstico">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('Parentesco')" xs12 sm12 md12>
                <v-text-field v-model="Parentesco" label="Parentesco">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('habitacion')" xs12 sm12 md12>
                <v-text-field v-model="habitacion" label="Habitación">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('url')" xs12 sm12 md12>
                <v-text-field v-model="url" label="Código de consulta">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('telefonoAcompanante')" xs12 sm12 md12>
                <v-text-field
                  v-model="telefonoAcompanante"
                  label="Telefono del acompañante"
                >
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('direccionAcompanante')" xs12 sm12 md12>
                <v-text-field
                  v-model="direccionAcompanante"
                  label="Dirección de acompañante"
                >
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('medicoNombre')" xs12 sm12 md12>
                <v-text-field v-model="medicoNombre" label="Nombre del médico">
                </v-text-field>
              </v-flex>
              <v-flex v-if="mostrar('hora')" xs12 sm6 md6>
                <v-time-picker
                  ampm-in-title
                  format="ampm"
                  landscape
                  v-model="hora"
                ></v-time-picker>
              </v-flex>

              <v-flex xs12 sm12 md12 v-show="validaComentarios">
                <div
                  class="red--text"
                  v-for="v in validaMensaje"
                  :key="v"
                  v-text="v"
                ></div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close">Cancelar</v-btn>
          <v-btn color="primary" text @click="guardarComentariosTicket"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs"> Cerrar </v-btn>
      </template>
    </v-snackbar>
  </v-flex>
</template>
<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
export default {
  data() {
    return {
      selectedItem : -1,
      lstServicios : [],
      servicio : "",
      servicioGroup : "",
      folioOperacion: "",
      apuntadorCasilla: 1,
      comentarios: "",
      ticket: {},
      dialog: false,
      validaComentarios: "",
      validaMensaje: [],
      textPeticionRest: "Se actualizo correctamente",
      multiLine: true,
      snackbar: false,
      campoVisible: "",
      url: "",
      medico: "",
      cirugia: "",
      medicoNombre: "",
      hora: "",
      habitacion: "",
      diagnostico: "",
      fecha: "",
      edad: "",
      fechaNacimiento: "",
      direccion: "",
      telefono: "",
      alergia: "",
      expediente: "",
      seguro: "",
      nombreRecepcionista: "",
      idRecepcionista: "",
      Parentesco: "",
      acompanante: "",
      telefonoAcompanante: "",
      direccionAcompanante: "",
    };
  },
  async created() {
    this.obtenerInf();
    this.obtenerInfServ();
    
    
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    ticket(val) {},
  },
  methods: {
    seleccionarItemServicio(){
      this.selectedItem = this.servicio;
      console.log(this.lstServicios);
      this.lstServicios.forEach((element, ind)=>{
        if (element._id == this.servicio){
          
          this.selectedItem = ind;
        }
      });
      console.log(this.selectedItem);
    },
    seleccionarServicio(item) {
      this.servicio = item._id;
      let Objeto = {
        Cliente  : "algo",
        servicio : item._id
      };

      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .put(
          this.$store.state.servidor + "ticket",
          {
            id: this.ticket._id,
            servicio : item._id
          },
          configuracion
        )
        .then(function (response) {
          me.servicio = item._id;
          me.limpiar();
          me.close();
          me.obtenerInf();
          me.obtenerInfServ();
          me.mensajeSnackBar("Se actualizo correctamente el Paciente");
          me.seleccionarItemServicio();
        })
        .catch(function (error) {
          console.log(error);
          me.mensajeSnackBar("no se puede actualizar el Paciente");
        })
        .finally(() => (this.snackbar = true));
    },



    format_1() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/consntimientoQ.png");
      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      imgPermiso.src = permiso1;
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let cirugia = this.cirugia; //Nombre de Cirugia
      let medico = this.medico; //Nombre del medico
      doc
        .addImage(imgPermiso, "PNG", 40, 20, 530, 800)
        .setFontSize(12)
        .setFont("colibri");
      doc.text(cirugia, 165, 218); //Nombre de la cirugia
      doc.text(nombrecompleto, 273, 189).setFontSize(10).setFont("colibri");
      doc.text(nombrecompleto, 100, 600);
      doc.text(this.ticket.Cliente.acompanante, 350, 680);
      doc.text(medico, 350, 600); //Nombre del medico

      doc.save("consentimientoQ-"+this.folioOperacion+".pdf");
    },
    format_2() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/autorizacion1.jpg");
      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      imgPermiso.src = permiso1;
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let hora = this.hora; //Colocar hora
      let habitacion = this.habitacion; //Colocar habitacion
      let diagnostico = this.diagnostico; //Colocar diagnostico
      let Medico = this.medicoNombre; //ColocarMedico
      let fecha = this.fecha; //Colocar fecha
      fecha =
        "a " +
        moment(fecha).format("Do") +
        " de " +
        moment(fecha).format("MMM ") +
        " del " +
        moment(fecha).format("YYYY ");
      doc
        .addImage(imgPermiso, "JPG", 40, 20, 530, 800)
        .setFontSize(10)
        .setFont("colibri");
      doc.text(diagnostico, 88, 316);
      doc.text(this.ticket.Cliente.acompanante, 250, 262);
      doc.text(Medico, 240, 275); //Medico
      doc.text(nombrecompleto, 100, 235).setFontSize(10).setFont("colibri");
      doc.text(nombrecompleto, 100, 633);
      doc.text(Medico, 346, 717); //Nombre del medico trante
      doc.text(this.ticket.Cliente.acompanante, 358, 635);
      doc.text(hora, 495, 130);
      doc.text(habitacion, 500, 144);
      doc.text(fecha, 433, 116);
      doc.save("AUTORIZACION-"+this.folioOperacion+".pdf");
    },
    format_3() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/caratula1.jpg");
      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      imgPermiso.src = permiso1;
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let fechaNacimiento = this.fechaNacimiento; //Colocar fecha
      let edad = this.edad; //Colocar fecha
      let genero = this.ticket.Cliente.genero; //Colocar fecha
      let direccion = this.direccion; //Colocar domicilio
      let telefono = this.telefono; //Colocar fecha
      let alergia = this.alergia; //Colocar fecha
      let habitacion = this.habitacion; //Colocar habitacion
      let Medico = this.medicoNombre; //ColocarMedico
      let fecha = this.fecha; //Colocar fecha
      let hora = this.hora; //Colocar hora
      let numregistro = this.expediente; //Colocar fecha
      let seguro = this.seguro; //Colocar fecha
      let recepcionista = this.nombreRecepcionista; //Colocar fecha

      doc
        .addImage(imgPermiso, "JPG", 40, 20, 530, 800)
        .setFontSize(14)
        .setFont("colibri");
      doc.text(nombrecompleto, 260, 370);
      doc.text(fechaNacimiento, 225, 391); //Nombre del medico trante
      doc.text(edad, 355, 391);
      doc.text(genero, 470, 391);
      doc.text(direccion, 225, 413);
      doc.text(telefono, 225, 435);
      doc.text(alergia, 225, 455); //Medico
      doc.text(habitacion, 470, 455);
      doc.text(Medico, 225, 475);
      doc.text(fecha, 225, 492);
      doc.text(fecha, 225, 492);
      doc.text(hora, 225, 510);
      doc.text(numregistro, 225, 595);
      doc.text(seguro, 225, 618).setFontSize(11).setFont("colibri");
      doc.text(recepcionista, 330, 654);

      doc.save("caratula-"+this.folioOperacion+".pdf");
    },
    format_4() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/CONSENTIMIENTOA2.jpg");
      const permiso2 = require("/src/assets/plantilla/CONSENTIMIENTOA1.jpg");
      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      var imgPermiso2 = new Image();
      imgPermiso.src = permiso1;
      imgPermiso2.src = permiso2;
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let edad = this.edad; //Colocar fecha
      let genero = this.ticket.Cliente.genero; //Colocar fecha
      let hora = this.hora; //Colocar hora
      let numregistro = this.expediente; //numero de registro
      let habitacion = this.habitacion; //Colocar habitacion
      let telefono = this.telefono; //Colocar fecha
      let acompañante = this.ticket.Cliente.acompanante; //ColocarMedico
      let parentesco = this.Parentesco; //Colocar fecha
      let telecompañante = this.telefonoAcompanante;
      let direccion = this.direccion; //Colocar domicilio

      let fecha = this.fecha; //Colocar fecha
      fecha =
        "a " +
        moment(fecha).format("Do") +
        " de " +
        moment(fecha).format("MMM ") +
        " del " +
        moment(fecha).format("YYYY ");
      let diagnostico = this.diagnostico; //Colocar fecha
      doc
        .addImage(imgPermiso, "JPG", 5, 5, 590, 830)
        .setFontSize(10)
        .setFont("colibri");
      doc.text(fecha, 481, 102);
      doc.text(nombrecompleto, 143, 126);
      doc.text(edad, 520, 126);
      doc.text(genero, 90, 136);
      doc.text(hora, 195, 136);
      doc.text(numregistro, 290, 136);
      doc.text(habitacion, 390, 136).setFontSize(9).setFont("colibri");
      doc.text(telecompañante, 500, 172);
      doc.text(telefono, 450, 136).setFontSize(10).setFont("colibri");
      doc.text(acompañante, 195, 172);
      doc.text(parentesco, 400, 172);
      doc.text(direccion, 90, 183);

      doc.addPage();
      doc
        .addImage(imgPermiso2, "JPG", 40, 20, 530, 800)
        .setFontSize(10)
        .setFont("colibri");
      doc.text(diagnostico, 270, 117);
      doc.text(nombrecompleto, 100, 668);
      doc.text(acompañante, 255, 710);
      doc.save("consentimientoA-"+this.folioOperacion+".pdf");
    },
    format_5() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/concentimientoparto1.jpg");
      const permiso2 = require("/src/assets/plantilla/concentimientoparto2.jpg");
      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      var imgPermiso2 = new Image();
      imgPermiso.src = permiso1;
      imgPermiso2.src = permiso2;
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let edad = this.edad; //Colocar fecha
      let hora = this.hora; //Colocar hora
      let numregistro = this.expediente; //numero de registro
      let habitacion = this.habitacion; //Colocar habitacion
      let telefono = this.telefono; //Colocar fecha
      let acompañante = this.ticket.Cliente.acompanante; //ColocarMedico

      let fecha = this.fecha; //Colocar fecha
      fecha =
        "a " +
        moment(fecha).format("Do") +
        " de " +
        moment(fecha).format("MMM ") +
        " del " +
        moment(fecha).format("YYYY ");
      doc
        .addImage(imgPermiso, "JPG", 5, 5, 590, 830)
        .setFontSize(10)
        .setFont("colibri");
      doc.text(nombrecompleto, 163, 150);
      doc.text(fecha, 490, 126);
      doc.text(edad, 67, 177);
      doc.text(hora, 130, 177);
      doc.text(numregistro, 220, 177);
      doc.text(habitacion, 310, 177);
      doc.text(telefono, 385, 177);

      doc.addPage();
      doc
        .addImage(imgPermiso2, "JPG", 40, 20, 530, 800)
        .setFontSize(9)
        .setFont("colibri");

      doc.text(nombrecompleto, 120, 495);
      doc.text(acompañante, 360, 495);
      doc.save("consentimientoParto-"+this.folioOperacion+".pdf");
    },
    format_6() {
      var vm = this;
      const permiso1 = require("/src/assets/plantilla/contrato1.jpg");
      const permiso2 = require("/src/assets/plantilla/contrato2.jpg");
      const permiso3 = require("/src/assets/plantilla/contrato3.jpg");
      const permiso4 = require("/src/assets/plantilla/contrato4.jpg");

      var doc = new jsPDF("p", "pt");
      var imgPermiso = new Image();
      var imgPermiso2 = new Image();
      var imgPermiso3 = new Image();
      var imgPermiso4 = new Image();
      imgPermiso.src = permiso1;
      imgPermiso2.src = permiso2;
      imgPermiso3.src = permiso3;
      imgPermiso4.src = permiso4;

      let direccion = this.direccion; //Colocar domicilio
      let direccionA = this.direccionAcompanante; //Colocar domicilio
      let nombrecompleto =
        this.ticket.Cliente.nombre +
        " " +
        this.ticket.Cliente.appPaterno +
        " " +
        this.ticket.Cliente.appMaterno;
      let acompañante = this.ticket.Cliente.acompanante; //ColocarMedico
      let fecha = this.fecha; //Colocar fecha
      let fechaHora =
        "a " +
        moment(fecha).format("Do") +
        " de " +
        moment(fecha).format("MMM ") +
        " del " +
        moment(fecha).format("YYYY ") +
        " a las " +
        this.hora;

      let medico = this.medico; //Colocar fecha
      let telacomp = this.telefonoAcompanante; //Colocar fecha
      let telefono = this.telefono; //Colocar fecha

      doc
        .addImage(imgPermiso4, "JPG", 5, 5, 590, 830)
        .setFontSize(11)
        .setFont("colibri");
      doc.text(nombrecompleto, 130, 127);
      doc.text(direccion, 130, 140);
      doc.text(telefono, 130, 155);
      doc.text(acompañante, 130, 190);
      doc.text(direccionA, 130, 204);
      doc.text(telacomp, 130, 217);
      doc.text(medico, 60, 265);
      doc.text(fechaHora, 165, 288);

      doc.addPage();
      doc
        .addImage(imgPermiso, "JPG", 40, 20, 530, 800)
        .setFontSize(9)
        .setFont("colibri");
      doc.addPage();
      doc
        .addImage(imgPermiso2, "JPG", 40, 20, 530, 800)
        .setFontSize(9)
        .setFont("colibri");
      doc.addPage();
      doc
        .addImage(imgPermiso3, "JPG", 40, 20, 530, 800)
        .setFontSize(9)
        .setFont("colibri");

      doc.text(nombrecompleto, 70, 412);
      doc.text(acompañante, 410, 412);

      doc.save("contrato-"+this.folioOperacion+".pdf");
    },

    mostrar(campo) {
      if (campo == this.campoVisible) {
        return true;
      } else {
        return false;
      }
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    validarComentariosTicket() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardarComentariosTicket() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validarComentariosTicket()) {
        return;
      }

      //Código para editar
      axios
        .put(
          this.$store.state.servidor + "ticket",
          {
            id: this.ticket._id,
            comentarios: this.comentarios,
            cirugia: this.cirugia,
            hora: this.hora,
            diagnostico: this.diagnostico,
            Parentesco: this.Parentesco,
            telefonoAcompanante: this.telefonoAcompanante,
            direccionAcompanante: this.direccionAcompanante,
            medicoNombre: this.medicoNombre,
            habitacion: this.habitacion,
            url: this.url,
          },
          configuracion
        )
        .then(function (response) {
          me.limpiar();
          me.close();
          me.obtenerInf();
          me.obtenerInfServ();
          //me.ticket.comentarios = me.comentarios;
          //me.ticket.cirugia = me.cirugia;
          //me.ticket.hora = me.hora;
          me.mensajeSnackBar("Se actualizo correctamente el Paciente");
        })
        .catch(function (error) {
          console.log(error);
          me.mensajeSnackBar("no se puede actualizar el Paciente");
        })
        .finally(() => (this.snackbar = true));
    },
    limpiar() {
      this.validaMensaje = [];
    },
    close() {
      //this.limpiar();

      this.dialog = false;
    },

    actualizarDatos(campo) {
      this.campoVisible = campo;
      this.dialog = true;
    },
    async obtenerInf() {
      this.folioOperacion = this.$route.params.folio;

      
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      await axios
        .get(
          this.$store.state.servidor + "servicio/0/0",
          configuracion
        )
        .then(function (response) {
          me.lstServicios = response.data.Servicios;
          
        })
        .catch(function (error) {
          console.log(error);
        });

    },

    async obtenerInfServ() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };

      await axios
        .get(
          this.$store.state.servidor + "ticket/" + me.folioOperacion,
          configuracion
        )
        .then(function (response) {
          me.ticket = response.data.tikect;
          me.comentarios = me.ticket.comentarios;
          me.cirugia = me.ticket.cirugia;
          me.habitacion = me.ticket.habitacion;
          me.medicoNombre = me.ticket.medicoNombre;
          me.hora = me.ticket.hora || "";
          me.diagnostico = me.ticket.diagnostico || "";
          me.fecha = me.ticket.fechaInicio
            ? me.ticket.fechaInicio.substring(0, 10)
            : "";
          me.fechaNacimiento = me.ticket.Cliente.fechaNacimiento || "";
          moment.locale("es-mx");
          me.edad = moment(me.fechaNacimiento, "YYYY-MM-DD").fromNow(true);
          me.direccion = me.ticket.Cliente.direccion || "";
          me.alergia = me.ticket.Cliente.alergia || "";
          me.telefono = me.ticket.Cliente.telefono || "";
          me.expediente = me.ticket.Cliente.expediente || "";
          me.seguro = me.ticket.Cliente.seguro || "";
          me.nombreRecepcionista =
            localStorage.nombre +
            " " +
            localStorage.appPaterno +
            " " +
            localStorage.appMaterno +
            " ";
          me.Parentesco = me.ticket.Parentesco || "";
          me.telefonoAcompanante = me.ticket.telefonoAcompanante || "";
          me.direccionAcompanante = me.ticket.direccionAcompanante || "";
          me.url = me.ticket.url;
          me.servicio = me.ticket.servicio;
          
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(()=>{
          this.seleccionarItemServicio();
        });
    },
  },
};
</script>
