
<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>CEYE</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-flex xs12 sm4 md4>
          <v-select
            :items="sucursales"
            :loading="loadingSelectSucursal"
            v-model="selectSucursal"
            v-on:change="obtenerProductosSucursal"
            item-text="alias"
            item-value="_id"
            label="Selecciona Area"
          >
          </v-select>
        </v-flex>

        <v-dialog v-model="agregarMinimoMaximo" max-width="500">
          <v-card>
            <v-card-title class="headline">
              Agregar Minimos y Máximos
            </v-card-title>
            <v-card-text>
              {{ descripcionProductoSeleccionado }}
            </v-card-text>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="minCantidadMedicamento"
                      type="Number"
                      min="0"
                      label="Minimo"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm4 md4>
                    <v-text-field
                      v-model="maxCantidadMedicamento"
                      type="Number"
                      min="0"
                      label="Máximo"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="cerrarAgregarMinMax()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="actualizarProductoMinMax()">
                Actualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="transferirToSucursal" max-width="500">
          <v-card>
            <v-card-title class="headline">
              Transferir equipo a Área
            </v-card-title>
            <v-card-text>
              Equipo a transferir <br />
              Código: <b>{{ codigoProducto }}</b> <br />
              <b>{{ nombre }}</b> {{ descripcion }}
              <b>{{ selectUnidadDimension }}</b> <br />
              Máxima cantidad: {{ maxCantidadMedicamento }}
            </v-card-text>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm3 md3>
                    <v-text-field
                      v-model="cantidadMaterialToTransferir"
                      type="Number"
                      min="1"
                      :max="maxCantidadMedicamento"
                      label="Cantidad"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm9 md9>
                    <v-select
                      :items="sucursalesSinAreaSeleccionada"
                      v-model="seleccionArea"
                      item-value="_id"
                      item-text="alias"
                      item-disabled="disabled"
                      label="Selecciona una área"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm9 md9>
                    <v-text-field
                      v-model="comentariosTransferir"
                      label="Comentarios"
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="cerrarTranferir()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="transferir()">
                Transferir
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="inventarioProd"
        :search="search"
        :loading="loadingTableProductosSucursal"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.Producto.codigoProducto }}</td>
            <td>
              <v-chip
                :color="
                  colorMaximosMinimos(item.minimo, item.maximo, item.cantidad)
                "
                >{{ item.cantidad }}
              </v-chip>
            </td>
            <td>{{ item.minimo }} / {{ item.maximo }}</td>
            <td>{{ item.Producto.nombre }}</td>
            <td>{{ item.Producto.descripcion }}</td>
            <td>{{ item.Producto.unidadDimension }}</td>

            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon
                  color="transfer"
                  @click="retornarProductosFarmacia(item)"
                  >fa-chevron-circle-left</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="edit" @click="agregarMinMax(item)">edit</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="obtenerProductosSucursal()"
            >Actualizar</v-btn
          >
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      transferirToSucursal: false,
      codigoProducto: "",
      nombre: "",
      cantidadMaterialToTransferir: "",
      descripcion: "",
      selectUnidadDimension: "",
      seleccionArea: "",
      sucursalesSinAreaSeleccionada: [],
      comentariosTransferir: "",

      sucursales: [],
      sucTransferencia: [],
      productos: [],
      inventarioProd: [],
      agregarMinimoMaximo: false,
      maxCantidadMedicamento: 0,
      minCantidadMedicamento: 0,
      valida: 0,
      validaMensaje: [],
      descripcionProductoSeleccionado: "",
      idProducto: "",

      headers: [
        { text: "Código", value: "codigoProducto", sortable: true },
        { text: "Cantidad", value: "cantidad", sortable: true },
        { text: "Min/Max", value: "minimo", sortable: false },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Descripción", value: "descripcion", sortable: true },
        {
          text: "Unidad de Dimension",
          value: "unidadDimension",
          sortable: true,
        },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],

      search: "",
      textPeticionRest: `Se guardo correctamente`,
      selectSucursal: "",

      snackbar: false,
      loadingSelectSucursal: false,
      loadingTableProductosSucursal: false,

      multiLine: true,
    };
  },
  created() {
    this.obtenerSucursalesPosibles();
  },
  methods: {
    cerrarTranferir() {
      this.transferirToSucursal = false;
    },
    crearSucuesalesTransferencia() {},
    colorMaximosMinimos(minimo, maximo, cantidad) {
      if (cantidad < minimo) {
        return "menorToMin";
      } else if (cantidad > maximo) {
        return "mayorToMax";
      } else {
        return "entreMinToMax";
      }
    },
    async obtenerSucursalesPosibles() {
      //
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      await axios
        .get(
          this.$store.state.servidor + "personalResponsableAreasSinCEYE",
          configuracion
        )
        .then(function (response) {
          me.sucursales = response.data.sucursales;

          if (me.sucursales.length == 1) {
            selectSucursal = me.sucursales[0];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      await axios
        .get(
          this.$store.state.servidor + "personalResponsableAreasConCEYE",
          configuracion
        )
        .then(function (response) {
          me.sucursalesSinAreaSeleccionada = response.data.sucursales;

          if (me.sucursales.length == 1) {
            selectSucursal = me.sucursales[0];
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      me.loadingSelectSucursal = false;
    },
    async obtenerProductosSucursal() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingTableProductosSucursal = true;
      if (me.selectSucursal.length > 0) {
        await axios
          .get(
            this.$store.state.servidor +
              "inventarioCEYE/" +
              me.selectSucursal +
              "/0/0",
            configuracion
          )
          .then(function (respuesta) {
            me.inventarioProd = respuesta.data.inventario;
          })
          .catch(function (error) {
            //console.log(error);
            me.inventarioProd = [];
          })
          .finally(() => {
            let arregTmp = [];
            me.sucursalesSinAreaSeleccionada.forEach((element) => {
              if (element._id != me.selectSucursal) {
                arregTmp.push(element);

              }
            });
            me.sucursalesSinAreaSeleccionada = arregTmp;
          });
      } else {
        me.inventarioProd = [];
      }

      me.loadingTableProductosSucursal = false;
    },
    async actualizarMinimosMaximo() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingTableProductosSucursal = true;
      if (me.selectSucursal.length > 0) {
        await axios
          .put(
            this.$store.state.servidor + "actualizarMinimoMaximoProductoCEYE/",
            {
              Sucursal: me.selectSucursal,
              Producto: me.idProducto,
              minimo: me.minCantidadMedicamento,
              maximo: me.maxCantidadMedicamento,
            },
            configuracion
          )
          .then(function (respuesta) {
            me.inventarioProd = respuesta.data.inventario;
            me.mensajeSnackBar(
              "Se actualizo correctamente los minimos y máximos"
            );
          })
          .catch(function (error) {
            //console.log(error);
            me.mensajeSnackBar("No se pudo actualizar los minimos y máximos");
            me.inventarioProd = [];
          });
      } else {
        me.inventarioProd = [];
      }
      me.snackbar = true;
      me.loadingTableProductosSucursal = false;
    },
    agregarMinMax(item) {
      this.agregarMinimoMaximo = true;
      this.descripcionProductoSeleccionado =
        item.Producto.nombre +
        " " +
        item.Producto.descripcion +
        " " +
        item.Producto.unidadDimension;
      this.idProducto = item.Producto._id;
      this.minCantidadMedicamento = item.minimo;
      this.maxCantidadMedicamento = item.maximo;
    },
    retornarProductosFarmacia(item) {
      this.codigoProducto = item.Producto.codigoProducto;
      this._id = item.Producto._id;
      this.abreviatura = item.Producto.abreviaturaTicket;
      this.nombre = item.Producto.nombre;
      console.log(item);
      this.cantidadTransferir = 1;
      this.descripcionProductoSeleccionado =
        item.Producto.nombre +
        " " +
        item.Producto.descripcion +
        " " +
        item.Producto.unidadDimension;
      ///this.idProducto = item.Producto._id;
      ///this.minCantidadMedicamento = item.minimo;
      ///this.maxCantidadMedicamento = item.maximo;
      ///this.agregarMinimoMaximo = true;

      this.descripcion = item.descripcion;
      this.selectUnidadDimension = item.unidadDimension;
      //this.comentariosAgregarUnidad = "";
      this.comentariosMovimientoInventario = "";
      this.dialog = false;

      this.transferirToSucursal = true;
      this.maxCantidadMedicamento = item.cantidad;
    },
    solicitarProductosFarmacia(item) {
      ///this.agregarMinimoMaximo = true;
      ///this.descripcionProductoSeleccionado =
      ///  item.Producto.nombre +
      ///  " " +
      ///  item.Producto.descripcion +
      ///  " " +
      ///  item.Producto.unidadDimension;
      ///this.idProducto = item.Producto._id;
      ///this.minCantidadMedicamento = item.minimo;
      ///this.maxCantidadMedicamento = item.maximo;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (
        parseFloat(this.minCantidadMedicamento) >
        parseFloat(this.maxCantidadMedicamento)
      ) {
        this.validaMensaje.push(
          "El minimo debe de ser igual o menor que el máximo"
        );
      }
      if (parseFloat(this.minCantidadMedicamento) < 0) {
        this.validaMensaje.push("El minimo debe de ser igual o mayor que 0");
      }
      if (parseFloat(this.maxCantidadMedicamento) < 0) {
        this.validaMensaje.push("El máximo debe de ser igual o mayor que 0");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    cerrarAgregarMinMax() {
      this.agregarMinimoMaximo = false;
      this.limpiar();
    },
    cerrarTransferir() {
      this.transferirToSucursal = false;
      this.limpiar();
    },
    async actualizarProductoMinMax() {
      if (this.validar()) {
        return;
      }
      await this.actualizarMinimosMaximo();
      await this.obtenerProductosSucursal();
      this.cerrarAgregarMinMax();
      this.limpiar();
    },
    validarTransferencia() {
      this.valida = 0;
      this.validaMensaje = [];

      if (this.cantidadMaterialToTransferir <= 0) {
        this.validaMensaje.push("La cantidad a transferir debe ser mayor a 0");
      }
      if (this.seleccionArea.length <= 0) {
        this.validaMensaje.push("Debe seleccionar un area para transferir");
      }
      if (this.comentariosTransferir.length < 5) {
        this.validaMensaje.push(
          "Debe escribir un comentario mayor a 5 caracteres"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    async listar() {
      this.obtenerProductosSucursal();
    },

    async transferir() {
      this.validarTransferencia();
      if (this.validarTransferencia()) {
        return;
      }
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      await axios
        .post(
          this.$store.state.servidor + "transferirProductoCEYE/",
          {
            SucursalOrigen: this.selectSucursal,
            SucursalDestino: this.seleccionArea,
            cantidad: this.cantidadMaterialToTransferir,
            Producto: this._id,
            comentarios: this.comentariosTransferir,
          },
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";
          me.mensajeSnackBar("Se transfiere correctamente el Fármaco");

          me.transferirToSucursal = false;
          me.comentariosTransferir = "";
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede transferir el Fármaco");
        })
        .finally(() => {
          me.obtenerProductosSucursal();
          this.snackbar = true;
        });
    },
    limpiar() {
      this.descripcionProductoSeleccionado = "";
      this.maxCantidadMedicamento = 0;
      this.minCantidadMedicamento = 0;
      this.idProducto = "";
    },
  },
};
</script>

