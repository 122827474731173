<template>
<v-container grid-list-md>
  <v-layout align-center>
    <v-flex xs12 sm6 m6>
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-icon>
            <v-icon>fa-building-o</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="overline mb-4">Farmacia Hospitalaria</div>
            <v-list-item-title class="headline mb-1">
              Area de resguardo para Farmacia Hospitalaria
            </v-list-item-title>
            <v-list-item-subtitle
              >Area de Asignación para farmacia
              hospitalaria</v-list-item-subtitle
            >
            <br />
            <v-flex>
              <v-select
                :items="Sucursal"
                label="Selecciona la sucursal"
                v-model="selectSucursal"
                item-text="alias"
                item-value="_id"
                v-on:change="actualizarFarmaciaHospitalaria"
              ></v-select>
            </v-flex>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-flex>
    <v-flex xs12 sm6 md6>
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-icon>
            <v-icon>fa-building-</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <div class="overline mb-4">CEYE</div>
            <v-list-item-title class="headline mb-1">
              Area de resguardo para CEYE
            </v-list-item-title>
            <v-list-item-subtitle
              >Area de Asignación para CEYE</v-list-item-subtitle
            >
            <br />
            <v-flex>
              <v-select
                :items="Sucursal"
                label="Selecciona la sucursal"
                v-model="selectCEYE"
                item-text="alias"
                item-value="_id"
                v-on:change="actualizarCEYE"
              ></v-select>
            </v-flex>
          </v-list-item-content>
        </v-list-item>
      </v-card>

      <v-snackbar v-model="snackbar" :multi-line="multiLine">
        {{ textPeticionRest }}

        <template v-slot:action="{ attrs }">
          <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      farmaciaHospitalaria: "",
      Sucursal: [],
      selectSucursal: "",
      selectCEYE: "",
      snackbar: false,
      textPeticionRest: "",
      multiLine: true,
    };
  },
  created() {
    this.listar();
    //
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "sucursal/0/0", configuracion)
        .then(function (response) {
          me.Sucursal = response.data.Sucursales;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(
          this.$store.state.servidor + "ConfiguracionAplicacionSucursal/FarmaciaHospitalaria",
          configuracion
        )
        .then(function (response) {
          me.selectSucursal = response.data.configuracion.Sucursal;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(
          this.$store.state.servidor + "ConfiguracionAplicacionSucursal/CEYE",
          configuracion
        )
        .then(function (response) {
          me.selectCEYE = response.data.configuracion.Sucursal;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    actualizarFarmaciaHospitalaria() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .post(
          this.$store.state.servidor + "ConfiguracionAplicacionSucursal",
          {
            aplicacion: "FarmaciaHospitalaria",
            Sucursal: me.selectSucursal,
            comentarios: "Actualización por medio del sistema",
          },
          configuracion
        )
        .then(function (response) {
          me.mensajeSnackBar(
            "Sé actualizó correctamente la sucursal de Farmacia Hospitalaria"
          );
        })
        .catch(function (error) {
          console.log(error);
          me.mensajeSnackBar(
            "no se puede actualizar la sucursal de Farmacia Hospitalaria"
          );
        })
        .finally(() => (this.snackbar = true));
    },
    actualizarCEYE() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .post(
          this.$store.state.servidor + "ConfiguracionAplicacionSucursal",
          {
            aplicacion: "CEYE",
            Sucursal: me.selectCEYE,
            comentarios: "Actualización por medio del sistema",
          },
          configuracion
        )
        .then(function (response) {
          me.mensajeSnackBar(
            "Se actualizó correctamente la sucursal de CEYE"
          );
        })
        .catch(function (error) {
          console.log(error);
          me.mensajeSnackBar(
            "no se puede actualizar la sucursal de CEYE"
          );
        })
        .finally(() => (this.snackbar = true));
    },
  },
};
</script>
