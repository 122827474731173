<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <v-toolbar-title>Verificar Caducidades</v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-spacer></v-spacer>
            </v-toolbar>

            <!-- lista de caducidades -->
            <v-list subheader three-line >
                <v-divider></v-divider>
                <v-subheader >
                    <v-toolbar-title>Caducidades {{ lstCaducidades.length >0 ? '(' + lstCaducidades.length + ' fechas) ' : '' }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-subheader>
                <span v-if="lstCaducidades.length <=0 && ! cargando" style="color: red;">  NO HAY DATOS</span>
                    <v-list-group
                    v-for="cad in lstCaducidades" :key="cad._id"
                    :value="false"
                    no-action
                    sub-group
                    >
                        <template v-slot:activator>
                            <v-list-item-content>
                                <v-list-item-title v-if="dif(cad._id) <= 90" > <v-alert  dense text color="red">{{cad._id}} - Caduca en {{dif(cad._id)}} días</v-alert> </v-list-item-title>
                                <v-list-item-title v-if="dif(cad._id) <= 180 && dif(cad._id) > 90" > <v-alert dense text color="orange"> {{cad._id}} - Caduca en {{dif(cad._id)}} días</v-alert> </v-list-item-title>
                                <v-list-item-title v-if="dif(cad._id) > 180" > {{cad._id}} - Caduca en {{dif(cad._id)}} días</v-list-item-title>
                                <v-list-item-subtitle >Diferentes productos: {{cad.diferentesProd}}; Total de piezas: {{cad.cantidadTotal}};</v-list-item-subtitle>
                            </v-list-item-content>
                        </template>
                        <v-list-item subheader three-line
                         v-for="prod in cad.productos" :key="prod._id"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    [{{prod.cantidad}}] {{prod.Producto.nombre}}
                                </v-list-item-title>
                                <v-list-item-subtitle>{{prod.Producto.descripcion}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-spacer></v-spacer>
                            <v-list-item-action>
                                <v-btn text color="delete" @click="eliminarCaducidad(cad, prod)">Eliminar</v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
            </v-list>    
            <!-- progressbar y snackbar -->
            <div>
                <v-progress-linear
                    v-if="cargando"
                    indeterminate
                    color="accent"
                    height="8"
                ></v-progress-linear>
                <v-snackbar v-model="snackbar" :multi-line="multiLine">
                    {{ this.txtSnackBar }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-flex>
        
    </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";  
import moment from 'moment';

export default {
    data(){
        return{
            search: "",
            // CADUCIDAD
            lstCaducidades: [],
            //SNACKBAR Y INDICADOR DE PROCESO
            cargando: false,
            multiLine: true,
            txtSnackBar:"",
            snackbar: false,            
        };
    },
    computed: {},
    watch: {},
    created() {
        this.obtenerCaducidades();
    },
    methods:{
        obtenerCaducidades(){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            me.lstCaducudades = [];
            me.cargando = true;
            axios
            .get(me.$store.state.servidor + "caducidadConcentrada", configuracion)
            .then(function (response) {
                let lstRes = [];
                response.data.resultado.forEach(f => {
                    let cant = 0;
                    let difProd = 0;
                    f.productos.forEach(p => {
                        cant =+ p.cantidad;
                        difProd ++;
                    });
                    const temp = {
                        _id: f._id,
                        cantidadTotal: cant,
                        diferentesProd: difProd,
                        productos: f.productos
                    };
                    lstRes.push(temp);
                });
                me.lstCaducidades = lstRes.sort((a,b) =>{
                    const fa = Date.parse(a._id);
                    const fb = Date.parse(b._id);
                    if (fa > fb) {
                        return 1;
                    }
                    if (fa < fb) {
                        return -1;
                    }
                    return 0;
                });
                me.cargando = false;
            })
            .catch(function (error) {
                me.cargando = false;
                console.log(error);
                me.mensajeSnackBar("No es posible recuperar caducidades");
            });
        },
        eliminarCaducidad(caducidad,producto){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            axios
            .delete(
                me.$store.state.servidor + "caducidadIngreso/" + producto.Producto._id + "/" + caducidad._id,
                configuracion
            )
            .then(function (response) {
                me.cargando = false;
                me.mensajeSnackBar("Se eliminó correctamente el producto caduco");
                me.selccionarProducto = false;
                me.obtenerCaducidades();
            })
            .catch(function (error) {
                me.cargando = false;
                console.log('error', error);
                me.obtenerCaducidades();
                me.mensajeSnackBar("No se eliminó el producto");
            })
            .finally(() => (this.snackbar = true));
        },
        dif(fecha){
            let fecha1 = moment(fecha);
            let fechaAct = moment(new Date());
            let dif = 0;
            dif = fecha1.diff(fechaAct,'days');
            return dif;

        },
        mensajeSnackBar(mensaje){
            this.txtSnackBar = mensaje;
            this.snackbar = true
        },
    },
};
</script>
