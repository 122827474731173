<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Área</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nueva</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="alias" label="Nombre del área">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 d-flex>
                    <v-select
                      :items="tipoSucursal"
                      v-model="selectTipoSucursal"
                      item-text="tipoSucursal"
                      item-value="tipoSucursal"
                      label="Tipo de área"
                    ></v-select>
                  </v-flex>
                  <v-flex xs5 sm5 d-flex>
                    <div class="text-center">
                      <v-switch
                        v-model="puedeIngresarInventario"
                        label="Recibe medicamento"
                      ></v-switch>
                    </div>
                  </v-flex>
                  <v-flex xs5 sm5 d-flex>
                    <div class="text-center">
                      <v-switch
                        v-model="isSucursalPrincipal"
                        label="Es principal"
                      ></v-switch>
                    </div>
                  </v-flex>

                  <v-flex xs7 sm7 d-flex>
                    <v-select
                      :items="Personal"
                      v-model="selectPersonal"
                      item-text="informacion"
                      item-value="_id"
                      label="Responsable"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Área
              {{ adTitulo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Sucursales"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.alias }}</td>

            <td>{{ item.tipo }}</td>
            <td>
              {{ item.puedeIngresarInventario == true ? "Si" : "NO" }}
            </td>
            <td>
              {{ item.isSucursalPrincipal == true ? "Si" : "NO" }}
            </td>

            <td>
              {{ item.responsable.nombre }} {{ item.responsable.appPaterno }}
              {{ item.responsable.appMaterno }}
            </td>

            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      search: "",
      Sucursales: [],
      Personal: [],
      tipoSucursal: [],
      headers: [
        { text: "Alias", value: "alias", sortable: true },
        { text: "Tipo", value: "tipo", sortable: true },
        {
          text: "Recepción de Medicamentos",
          value: "puedeIngresarInventario",
          sortable: true,
        },
        { text: "Es Principal", value: "isSucursalPrincipal", sortable: true },
        { text: "Responsable", value: "responsable", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      selectPersonal: "",
      selectTipoSucursal: "",
      editedIndex: -1,
      _id: "",

      alias: "",
      puedeIngresarInventario: false,
      isSucursalPrincipal: false,
      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nueva área" : "Editar área";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "sucursal/0/0  ", configuracion)
        .then(function (response) {
          me.Sucursales = response.data.Sucursales;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(this.$store.state.servidor + "personal/0/0", configuracion)
        .then(function (response) {
          response.data.ListadoPersonas.forEach((element) => {
            element["informacion"] =
              element.nombre +
              " " +
              element.appPaterno +
              " " +
              element.appMaterno;
          });
          me.Personal = response.data.ListadoPersonas;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(this.$store.state.servidor + "tipoSucursal", configuracion)
        .then(function (response) {
          me.tipoSucursal = response.data.tipoSucursal;
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this.alias = "";
      this.selectTipoSucursal = "";
      this.selectPersonal = "";
      this._id = "";
      this.responsable = "";
      this.puedeIngresarInventario = false;
      this.isSucursalPrincipal = false;

      this.descripcion = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.alias.length < 3 || this.alias.length > 50) {
        this.validaMensaje.push(
          "La alias del área debe tener entre 3-50 caracteres."
        );
      }

      if (this.selectPersonal.length < 1) {
        this.validaMensaje.push("EL responsable del área debe existir.");
        this.validaMensaje.push(this.selectPersonal);
      }
      if (this.selectTipoSucursal.length < 1) {
        this.validaMensaje.push("EL tipo del área debe existir.");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        axios
          .put(
            this.$store.state.servidor + "sucursal",
            {
              _id: this._id,
              alias: this.alias,
              puedeIngresarInventario: this.puedeIngresarInventario.toString(),
              isSucursalPrincipal: this.isSucursalPrincipal.toString(),
              tipo: this.selectTipoSucursal,
              responsable: this.selectPersonal,
              estado: "Hidalgo",
              municipio: "Hidalgo",
              localidad: "Hidalgo",
              colonia: "centro",
              calle: "principal",
              numeroExt: "100",
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Área");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar el Área");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar

        axios
          .post(
            this.$store.state.servidor + "sucursal",
            {
              alias: this.alias,
              puedeIngresarInventario: this.puedeIngresarInventario.toString(),
              isSucursalPrincipal: this.isSucursalPrincipal.toString(),
              tipo: this.selectTipoSucursal,
              responsable: this.selectPersonal,
              estado: "Hidalgo",
              municipio: "Hidalgo",
              localidad: "Hidalgo",
              colonia: "centro",
              calle: "principal",
              numeroExt: "100",
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Área");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear el Área");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.alias = item.alias;
      this.puedeIngresarInventario = item.puedeIngresarInventario;
      this.isSucursalPrincipal = item.isSucursalPrincipal;
      this.selectPersonal = item.responsable;
      this.selectTipoSucursal = item.tipo;
      this.descripcion = item.descripcion;
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adTitulo = item.alias;
      this.puedeIngresarInventario = item.puedeIngresarInventario;
      this.isSucursalPrincipal = item.isSucursalPrincipal;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "sucursal/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adTitulo = "";
          me.removeId = "";
          me.alias = "";
          me.puedeIngresarInventario = false;
          me.isSucursalPrincipal = false;
          me.selectTipoSucursal = "";
          me.selectPersonal = "";
          me.mensajeSnackBar("Se elimino correctamente el Área");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Área");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>
