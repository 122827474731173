import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from './../router/index';
import { mapGetters } from 'vuex'


Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        token: null,
        _id: null,
        usuario: null,
        nombre: null,
        appPaterno: null,
        appMaterno: null,
        aplicaciones: [],
        puestoAsignado: [],
        //servidor: "http://logiaerp.herokuapp.com/",
        servidor: "http://localhost:3000/"
    },

    getters: {
        token: state => {
            return state.token;
        },
        usuario: state => {

            return state.usuario;
        },
        nombre: state => {
            return state.nombre;
        },
        appPaterno: state => {
            return state.appPaterno;
        },
        appMaterno: state => {
            return state.appMaterno;
        },
        servidor: state => {
            return state.servidor;
        },
    },
    mutations: {
        setToken(state, token) {

            state.token = token;
        },
        setUsuario(state, token) {

            state.usuario = token.usuario.alias ? token.usuario.alias : null;
        },
        getUsuario(state) {
            return state.usuario;
        },

        setId(state, token) {
            state._id = token.usuario._id ? token.usuario._id : null;
        },
        setNombre(state, token) {
            state.nombre = token.usuario.nombre ? token.usuario.nombre : null;
        },
        setAppPaterno(state, token) {
            state.appPaterno = token.usuario.appPaterno ? token.usuario.appPaterno : null;
        },
        setAppMaterno(state, token) {
            state.appMaterno = token.usuario.appMaterno ? token.usuario.appMaterno : null;
        },
        setAplicaciones(state, aplicaciones) {

            state.aplicaciones = aplicaciones ? JSON.stringify(aplicaciones) : null;
        },
        setPuestoAsignado(state, puestoAsignado) {

            state.puestoAsignado = puestoAsignado ? JSON.stringify(puestoAsignado) : null;
        }
    },
    actions: {

        guardarToken({ commit }, data) {

            commit("setToken", data.token);
            commit("setUsuario", decode(data.token));
            commit("setId", decode(data.token));
            commit("setNombre", decode(data.token));
            commit("setAppPaterno", decode(data.token));
            commit("setAppMaterno", decode(data.token));
            commit("setAplicaciones", data.aplicaciones);
            commit("setPuestoAsignado", data.puestoAsignado);

            localStorage.setItem("token", data.token);
            localStorage.setItem("usuario", this.state.usuario);
            localStorage.setItem("_id", this.state._id);
            localStorage.setItem("nombre", this.state.nombre);
            localStorage.setItem("appPaterno", this.state.appPaterno);
            localStorage.setItem("appMaterno", this.state.appMaterno);
            localStorage.setItem("aplicaciones", this.state.aplicaciones);
            localStorage.setItem("puestoAsignado", this.state.puestoAsignado);
        },
        autoLogin({ commit }) {
            let token = localStorage.getItem("token");
            let aplicaciones = localStorage.getItem("aplicaciones");
            if (token) {
                commit("setToken", token);
                commit("setUsuario", decode(token));
                commit("setId", decode(token));
                commit("setNombre", decode(token));
                commit("setAppPaterno", decode(token));
                commit("setAppMaterno", decode(token));
                commit("setAplicaciones", aplicaciones);
                commit("setPuestoAsignado", puestoAsignado);
            }
        },
        salir({ commit }) {
            localStorage.clear();
        },

    },
    modules: {},
    computed: {
        // mix the getters into computed with object spread operator
        ...mapGetters([
            'token',
            'ususario',
            // ...
        ])
    }
})