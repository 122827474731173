import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './../components/home.vue';
import App from './../App.vue';
import store from './../store/index';

import Area from './../components/Sucursal.vue';
import ActualizarMiPassword from './../components/ActualizarMiPassword.vue';
import Login from './../components/Login.vue'
import Cliente from './../components/Cliente.vue';
import AsignarCliente from './../components/AsignarCliente.vue';
import Categorias from './../components/Departamento.vue';
import Puesto from './../components/Puesto.vue';
import Servicio from './../components/Servicio';
import ServicioProductos from './../components/ServicioProductos.vue';
import CaducidadProducto from './../components/CaducidadProducto.vue';

import informacionPaciente from './../components/InformacionCliente.vue';
import Producto from './../components/Producto.vue';
import Precio from './../components/Precio.vue';
import CEYE from './../components/CEYE.vue';

import Personal from './../components/Personal.vue';
import AplicacionPuestos from './../components/AplicacionPuestos.vue';
import MiPerfil from './../components/MiPerfil.vue';
import Configuracion from './../components/Configuracion.vue';
import validarTransferencias from './../components/FarmaciaHospitalaria/validarTransferencias.vue';
import validarTransferenciasCEYE from './../components/CEYE/validarTransferenciasCEYE.vue';
import verificarInventarioArea from './../components/Areas/verificarInventarioArea.vue';
import verificarInventarioAreaCEYE from './../components/Areas/verificarInventarioAreaCEYE.vue';
import generarSolicitudInventario from './../components/Areas/generarSolicitudInventario.vue';

import categoriaCeye from './../components/CEYE/DepartamentoCEYE.vue';


import ingresoPaciente from './../components/Recepcion/ingreso.vue';
import EnfermeriaPacientes from './../components/EnfermeriaPacientes.vue';
import AsignacionMedicamentos from './../components/AsignacionMedicamentos.vue';

import GenerarSolicitudInventarioCEYE from './../components/CEYE/generarSolicitudInventarioCEYE.vue';

import manejoServicio from './../components/TicketServicio/ManejoServicio.vue';

import historialMovimientoFarmaco from './../components/FarmaciaHospitalaria/historialMovimientoFarmaco.vue';

import historialMovimientoCEYE from './../components/CEYE/historialMovimientoCEYE.vue';

import consultaCuentaPaciente from './../components/Cuenta/consultaCuentaPaciente.vue';

import historialCuentaPaciente from './../components/Cuenta/historialCuentaPaciente.vue';

import consultaCuentaPacienteCliente from './../components/Cuenta/consultaCuentaPacienteCliente.vue';



Vue.use(VueRouter)




const routes = [
    {
        path: '/:id',
        name: 'consultaCuentaPacienteCliente',
        component: consultaCuentaPacienteCliente,
        meta: {
            libre: true,
            login: false,
            title: 'Consulta de Cuenta de Paciente'
        }
    },


    
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    //document.title = to.meta.title;
    
    ///if (to.matched[0].meta.libre == true){
    ///    
    ///    localStorage.usuario = "invitado";
    ///    localStorage.token = "invitado";
    ///    next();
    ///} else
    if (!localStorage.usuario) {
        
        if (to.name != "") {
            next({ name: "" });
        }
    }

    next();

    //if(!localStorage.getItem("usuario") && from.name!="login"){
    //  next({name:'login'});
    //} else if (localStorage.getItem("usuario") && to.name=="login"){
    //  next({name:'home'});
    //} else if (to.matched.some(record => record.meta.libre)){
    //  next();
    //}



    /// else if (localStorage.usuario /*&& localStorage.usuario.rol == 'Administrador'*/){
    ///  if (to.matched.some(record=> record.meta.administrador)){
    ///    next();
    ///  }
    ///} else if (localStorage.usuario){
    ///  if (to.matched.some(record=>record.meta.login)){
    ///    next();
    ///  }
    ///}   else {
    ///  next({name:'home'});
    ///}

});

export default router