<template>
  <v-layout wrap>
    <v-flex xs12 sm7 md7>
      <v-select
        :items="pacientes"
        :loading="loadingSelectSucursal"
        v-model="seleccionPaciente"
        v-on:change="obtenerInformacionServicios"
        item-text="descCliente"
        item-value="_id"
        label="Selecciona el ticket del servicio"
      >
      </v-select>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title
          >{{
            pacienteInf.Cliente ? pacienteInf.Cliente.nombre : "Sin Información"
          }}
          {{ pacienteInf.Cliente ? pacienteInf.Cliente.appPaterno : "" }}
          {{
            pacienteInf.Cliente ? pacienteInf.Cliente.appMaterno : ""
          }}</v-card-title
        >
        <v-card-subtitle
          >Expediente:
          {{ pacienteInf.Cliente ? pacienteInf.Cliente.expediente : " ----- " }}
          <br />
          Fecha de Inicio:
          {{
            pacienteInf.fechaInicio
              ? pacienteInf.fechaInicio.slice(0, 10)
              : " --/--/---- "
          }}
          <br />
          comentarios : {{ pacienteInf.comentarios }} <br />
          Codigo de acceso : {{ pacienteInf.url }}
        </v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title
          >Servicio
          {{
            pacienteInf.servicio ? pacienteInf.servicio.alias : ""
          }}</v-card-title
        >
        <v-card-subtitle>
          Tipo :
          {{ pacienteInf.servicio ? pacienteInf.servicio.tipoServicio : "" }}
          <br />
          Nombre : {{ pacienteInf.servicio ? pacienteInf.servicio.nombre : "" }}
        </v-card-subtitle>
        <v-card-subtitle style="text-align: right"
          >$
          {{
            pacienteInf.servicio ? pacienteInf.servicio.precio : ""
          }}</v-card-subtitle
        >
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Contenido del Servicio</v-card-title>
        <v-card-subtitle>
          <ul v-if="productosContServ">
            <li v-for="item in productosContServ" :key="item._id">
              {{ item.cantidad }} de {{ item.Producto.nombre }}
              {{ item.Producto.descripcion }}
              {{ item.Producto.unidadDimension }}
            </li>
          </ul>
        </v-card-subtitle>
        <v-card-subtitle style="text-align: right"></v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Medicamento asignado</v-card-title>
        <v-card-subtitle>
          <ul v-if="productosAsigPac">
            <li
              v-for="itemProdAsig in productosAsigPac"
              :key="itemProdAsig._id"
            >
              {{ itemProdAsig.cantidad }} de {{ itemProdAsig.Producto.nombre }}
              {{ itemProdAsig.Producto.descripcion }}
              {{ itemProdAsig.Producto.unidadDimension }} el
              {{ itemProdAsig.fechaAsignacion.slice(0, 10) }} a las
              {{ itemProdAsig.fechaAsignacion.slice(11, 16) }}
            </li>
          </ul>
        </v-card-subtitle>
        <v-card-subtitle style="text-align: right"></v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Comparativa</v-card-title>
        <v-card-subtitle>
          <ul v-if="concentracionProdAsigPac">
            <li
              v-for="itemProdAsig in concentracionProdAsigPac"
              :key="itemProdAsig._id"
            >
              {{ itemProdAsig.cantidad }} /
              {{ itemProdAsig.cantidadServicio }} de
              {{ itemProdAsig.Producto.nombre }}
              {{ itemProdAsig.Producto.descripcion }}
              {{ itemProdAsig.Producto.unidadDimension }} el
              {{ itemProdAsig.fechaAsignacion.slice(0, 10) }} a las
              {{ itemProdAsig.fechaAsignacion.slice(11, 16) }}
              <br />
              {{ itemProdAsig.diferencia }} x {{ itemProdAsig.costoUnitario }} =
              {{ itemProdAsig.importe }}
            </li>
          </ul>
          <v-card-subtitle style="text-align: right">
            $ {{ importeTotalMedicamentos }}
          </v-card-subtitle>
        </v-card-subtitle>
        <v-card-subtitle style="text-align: right"></v-card-subtitle>
      </v-card>
    </v-flex>

    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Total a Pagar</v-card-title>
        <v-card-actions style="text-align: right">
          <v-spacer></v-spacer>
            
            <h2>pagar $ {{ importeTotal }}</h2>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    moment.locale("es-mx");
    return {
      snackbar: false,
      textPeticionRest: "",
      multiLine: true,
      validarDatos: false,
      pacienteInf: {},
      factura: true,
      pacientes: [],
      precios: [],
      importeTotalMedicamentos: 0,
      importeTotal: 0,
      importeServicio: 0,
      idPacienteSeleccion: "",
      productosContServ: [],
      productosAsigPac: [],
      concentracionProdAsigPac: [],
      productosCobrar: [],
      seleccionPaciente: "",
      loadingSelectSucursal: false,
      idTicket: 0,
    };
  },
  created() {
    this.obtenerPacientes();
    this.obtenerParamentros();
    this.obtenerPrecios();
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    obtenerParamentros() {
      const params = new URLSearchParams(window.location.search);
      if (params.has("f")) {
        this.factura = false;
      }
    },
    obtenerPrecios() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };

      axios
        .get(
          this.$store.state.servidor + "precioAdministradorSoloPrecio/0/0",
          configuracion
        )
        .then(function (response) {
          me.precios = response.data.listadoPrecio;
        })
        .catch(function (error) {
          me.precios = [];
          me.validarDatos = false;
          console.log(error);
        });
    },
    obtenerPacientes() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.loadingSelectSucursal = true;
      
      let url = this.$route.params.id;
      axios
        .get(
          this.$store.state.servidor + "ticketInformacionURL/"+url,
          configuracion
        )
        .then(function (response) {
          me.pacientes = response.data.tikects;
          me.pacientes.forEach((paciente) => {
            let resp =
              "Folio: " +
              paciente.folio +
              " - " +
              paciente.Cliente.nombre +
              " " +
              paciente.Cliente.appPaterno +
              " " +
              paciente.Cliente.appMaterno;
            paciente.descCliente = resp;
          });
        })
        .catch(function (error) {
          me.pacientes = [];
          console.log(error);
        });
      me.loadingSelectSucursal = false;
    },
    async obtenerProductosDelServicio() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (me.pacienteInf.servicio) {
        await axios
          .get(
            this.$store.state.servidor +
              "productoDelServicio/" +
              this.pacienteInf.servicio._id,
            configuracion
          )
          .then(function (response) {
            me.productosContServ = response.data.productosDelServicio;
            me.validarDatos = true;
          })
          .catch(function (error) {
            console.log(error);
            me.validarDatos = false;
            me.productosContServ = [];
          });
      }
    },
    async obtenerProductosAsignadosToPaciente() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.concentracionProdAsigPac = [];
      await axios
        .get(
          this.$store.state.servidor +
            "asignacionProductoTicket/" +
            me.pacienteInf.Cliente._id,
          configuracion
        )
        .then(function (response) {
          me.productosAsigPac = response.data.productosAsignados;
          me.productosAsigPac.forEach((element) => {
            let band = false;

            me.concentracionProdAsigPac.forEach((elementoConcentrado) => {
              if (
                element.Producto.codigoProducto ==
                elementoConcentrado.Producto.codigoProducto
              ) {
                band = true;
                elementoConcentrado.cantidad += element.cantidad;
                //elementoConcentrado.cantidadServicio=element.cantidad;
              }
            });

            if (band == false) {
              let VariableTmp = {
                cantidad: element.cantidad,
                cantidadServicio: 0,
                diferencia: 0,
                costoUnitario: 0,
                importe: 0,
                Producto: element.Producto,
                fechaAsignacion: element.fechaAsignacion,
              };
              me.concentracionProdAsigPac.push(VariableTmp);
            }
          });
        })
        .catch(function (error) {
          me.productosAsigPac = [];
          me.validarDatos = false;
          console.log(error);
        });
    },
    crearConcentradoMedicamentAsigando() {
      let me = this;
      me.concentracionProdAsigPac.forEach((element) => {
        element.diferencia = element.cantidad;
        me.productosContServ.forEach((productos) => {
          if (
            productos.Producto.codigoProducto == element.Producto.codigoProducto
          ) {
            element.cantidadServicio = productos.cantidad;
            element.diferencia = element.cantidad - element.cantidadServicio;
            if (element.diferencia < 0) {
              element.diferencia = 0;
            }
          }
        });
      });
      this.importeTotalMedicamentos = 0;
      me.concentracionProdAsigPac.forEach((element) => {
        me.precios.forEach((precio) => {
          if (precio.Producto && element.Producto) {
            if (
              precio.Producto.codigoProducto == element.Producto.codigoProducto
            ) {
              element.costoUnitario = precio.precioCalculadoMenudeo;
              element.importe = element.costoUnitario * element.diferencia;
              this.importeTotalMedicamentos += element.importe;
            }
          }
        });
      });
      if (this.pacienteInf.servicio) {
        this.importeServicio = this.pacienteInf.servicio.precio;
        this.importeTotal =
          this.importeTotalMedicamentos + this.importeServicio;
      }
    },
    async obtenerInformacionServicios() {
      let me = this;
      me.validarDatos = false;
      me.pacientes.forEach((paciente) => {
        if (paciente._id == this.seleccionPaciente) {
          me.pacienteInf = paciente;
          me.idPacienteSeleccion = paciente._id;
        }
      });
      await this.obtenerProductosDelServicio();
      await this.obtenerProductosAsignadosToPaciente();
      this.crearConcentradoMedicamentAsigando();
    },

   
  },
};
</script>



































































<!----

<template>
  <v-layout wrap>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title
          >Nombre: {{ ticket.Cliente.appPaterno }}
          {{ ticket.Cliente.appMaterno }} {{ ticket.Cliente.nombre }}
        </v-card-title>
        <v-card-subtitle
          >Servicio: {{ ticket.servicio.nombre }} <br />
          ${{ ticket.servicio.precio }}</v-card-subtitle
        >
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Medicamento asignado</v-card-title>
        <v-card-subtitle>
          <ul v-if="productosAsigPac">
            <li
              v-for="itemProdAsig in productosAsigPac"
              :key="itemProdAsig._id"
            >
              {{ itemProdAsig.cantidad }} de {{ itemProdAsig.Producto.nombre }}
              {{ itemProdAsig.Producto.descripcion }}
              {{ itemProdAsig.Producto.unidadDimension }} el
              {{ itemProdAsig.fechaAsignacion.slice(0, 10) }} a las
              {{ itemProdAsig.fechaAsignacion.slice(11, 16) }}
            </li>
          </ul>
        </v-card-subtitle>
        <v-card-subtitle style="text-align: right"></v-card-subtitle>
      </v-card>
    </v-flex>
    <v-flex xs12 sm12 md12>
      <v-card v-if="validarDatos">
        <v-card-title>Costo</v-card-title>
        <v-card-text>
            Servicio : {{importeServicio}} <br/>
            Medicamento : {{importeTotalMedicamentos}} <br/>
           Total :  {{importeTotal}}
        </v-card-text>
        <v-card-subtitle style="text-align: right"></v-card-subtitle>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
  data() {
    moment.locale("es-mx");
    return {
      idServicio: 0,
      ticket: {},
      snackbar: false,
      textPeticionRest: "",
      multiLine: true,
      validarDatos: false,
      ticket: {},
      factura: true,
      pacientes: [],
      precios: [],
      importeTotalMedicamentos: 0,
      importeTotal: 0,
      importeServicio: 0,
      idPacienteSeleccion: "",
      productosContServ: [],
      productosAsigPac: [],
      concentracionProdAsigPac: [],
      productosCobrar: [],
      seleccionPaciente: "",
      loadingSelectSucursal: false,
      idTicket: 0,
    };
  },
  async created() {
    this.obtenerParamentros();
    await this.obtenerDatosFunciones();
    await this.obtenerProductosAsignadosToPaciente();
    await this.obtenerProductosDelServicio();
    await this.crearConcentradoMedicamentAsigando();
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    obtenerParamentros() {
      const params = new URLSearchParams(window.location.search);
      this.idServicio = this.$route.params.id;
      if (params.has("f")) {
        this.factura = false;
      }
    },
    async obtenerDatosFunciones() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
        console.log("1");
      await axios
        .get(
          this.$store.state.servidor + "ticketUrl/" + me.idServicio,
          configuracion
        )
        .then(function (response) {
          me.ticket = response.data.ticket;
          me.validarDatos = true;
        })
        .catch(function (error) {
          console.log(error);
          
          me.productosContServ = [];
        });
    },
    async obtenerProductosAsignadosToPaciente() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      me.concentracionProdAsigPac = [];
      console.log(me.ticket);
      console.log("2");
      await axios
        .get(
          this.$store.state.servidor +
            "asignacionProductoTicket/" +
            me.ticket._id,
          configuracion
        )
        .then(function (response) {
          me.productosAsigPac = response.data.productosAsignados;
          me.productosAsigPac.forEach((element) => {
            let band = false;

            me.concentracionProdAsigPac.forEach((elementoConcentrado) => {
              if (
                element.Producto.codigoProducto ==
                elementoConcentrado.Producto.codigoProducto
              ) {
                band = true;
                elementoConcentrado.cantidad += element.cantidad;
                //elementoConcentrado.cantidadServicio=element.cantidad;
              }
            });

            if (band == false) {
              let VariableTmp = {
                cantidad: element.cantidad,
                cantidadServicio: 0,
                diferencia: 0,
                costoUnitario: 0,
                importe: 0,
                Producto: element.Producto,
                fechaAsignacion: element.fechaAsignacion,
              };
              me.concentracionProdAsigPac.push(VariableTmp);
            }
          });
        })
        .catch(function (error) {
          me.productosAsigPac = [];
          
          console.log(error);
        });
    },
    async obtenerProductosDelServicio() {
        console.log("3");
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (me.ticket.servicio._id) {
        await axios
          .get(
            this.$store.state.servidor +
              "productoDelServicio/" +
              me.ticket.servicio._id,
            configuracion
          )
          .then(function (response) {
            me.productosContServ = response.data.productosDelServicio;
            
          })
          .catch(function (error) {
            console.log(error);
            
            me.productosContServ = [];
          });
      }
    },
    async crearConcentradoMedicamentAsigando() {
        console.log("4");
      let me = this;
      me.concentracionProdAsigPac.forEach((element) => {
        element.diferencia = element.cantidad;
        me.productosContServ.forEach((productos) => {
          if (
            productos.Producto.codigoProducto == element.Producto.codigoProducto
          ) {
            element.cantidadServicio = productos.cantidad;
            element.diferencia = element.cantidad - element.cantidadServicio;
            if (element.diferencia < 0) {
              element.diferencia = 0;
            }
          }
        });
      });
      this.importeTotalMedicamentos = 0;
      me.concentracionProdAsigPac.forEach((element) => {
        me.precios.forEach((precio) => {
          if (precio.Producto && element.Producto) {
            if (
              precio.Producto.codigoProducto == element.Producto.codigoProducto
            ) {
              element.costoUnitario = precio.precioCalculadoMenudeo;
              element.importe = element.costoUnitario * element.diferencia;
              this.importeTotalMedicamentos += element.importe;
            }
          }
        });
      });
      if (this.ticket.servicio) {
        this.importeServicio = this.ticket.servicio.precio;
        this.importeTotal =
          this.importeTotalMedicamentos + this.importeServicio;
      }
    }
  },
};
</script>


---->