<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <!-- <v-btn icon @click="regresar()"><v-icon color="primary">arrow_back</v-icon></v-btn> -->
                <v-toolbar-title>Enfermería </v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-toolbar-title> {{nombre}} <span></span></v-toolbar-title>
                <v-spacer></v-spacer>
                {{this.puesto}}
                
            </v-toolbar>
            <!-- DIALOGO PARA SELECCIONAR PACIENTE -->
            <v-dialog v-model="selccionarProducto" width="1000">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Seleccionar Producto </span>
                        </v-card-title>   
                        <v-container>
                            <div>
                            <v-spacer></v-spacer>
                            <label>
                                Producto seleccionado: <b> {{this.productoSel != null ? this.productoSel.nombre : ""}}</b>
                            </label>
                            <v-spacer></v-spacer>                            
                            <label>
                                Cantidad:
                                <b><input v-model.number="cantidad" type="number" step="1"/></b>
                            </label>
                            </div>
                        </v-container>                     
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="lstProductos.length > 0" color="primary" text @click="setProducto(productoSel,2)">Agregar a paciente</v-btn>
                            <v-btn color="secondary" text @click="selccionarProducto = false">Cancelar</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-container>
                            <template>
                                <v-flex>
                                    <v-toolbar flat color="white">
                                        <v-toolbar-title>Productos</v-toolbar-title>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            class="text-xs-center"
                                            v-model="search"
                                            append-icon="search"
                                            label="Búsqueda"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </v-flex>
                            </template>
                        <!-- Listado de productos-->
                            <v-data-table
                                :headers="headers"
                                :items="lstProductos"
                                :search="search"
                                :loading="cargando"
                                loading-text="Cargando datos... Por favor espere un momento"
                                class="elevation-1"
                            >
                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.Producto.codigoProducto }}</td>
                                    <td>{{ item.cantidad }}</td>
                                    
                                    <td>{{ item.Producto.nombre }}</td>
                                    <td>{{ item.Producto.descripcion }}</td>
                                    <td class="justify-center layout px-0">
                                        <v-btn
                                        icon
                                        color="primary"
                                        @click="setProducto(item.Producto,1)"
                                        >
                                            <v-icon>send</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </template>
                                <template v-slot:no-data>
                                <v-btn color="primary" @click="obtenerPacientes">Actualizar</v-btn>
                                </template>
                            </v-data-table>
                        </v-container>

                    </v-card>
            </v-dialog>
            <!-- LISTA DE PACIENTES DE ENFERMERA -->
            <div>
                
                <v-list subheader three-line >
                    <v-divider></v-divider>
                    <v-subheader >
                        <v-toolbar-title>Mis Pacietes {{ lstMisPacientes.length >0 ? '(' + lstMisPacientes.length + ') ' : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-subheader>
                    <span v-if="lstMisPacientes.length <=0 && ! cargando" style="color: red;">  NO HAY DATOS</span>
                        <v-list-group
                        v-for="pas in lstMisPacientes" :key="pas._id"
                        :value="false"
                        no-action
                        sub-group
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title >{{pas.Cliente.nombre}} {{pas.Cliente.appPaterno}} {{pas.Cliente.appMaterno}}</v-list-item-title>
                                    <v-list-item-subtitle >Genero: {{pas.Cliente.genero}}; F. Nac: {{pas.Cliente.fechaNacimiento}}; F. Ing: {{mascarafecha(pas.Cliente.fechaIngreso)}}</v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <v-list-item subheader three-line>
                                <v-list-item-content>
                                <v-btn text color="secondary" @click="abrirSeleccionarProducto(pas.Cliente)">Agregar producto</v-btn>
                                <span v-if="pas.productosApl.length <=0 && ! cargando" style="color: red;"> NO HAY DATOS</span>
                                <v-spacer></v-spacer>
                                <v-data-table
                                    v-if="pas.productosApl.length >0 && ! cargando"
                                    :headers="headerPaciente"
                                    :items="pas.productosApl"
                                    :items-per-page="5"
                                    class="elevation-3"
                                    
                                >
                                    <template v-slot:item="{ item }">
                                    <tr>
                                        <td>{{ mascarafecha(item.fechaAsignacion) }}</td>
                                        <td>{{ item.Producto.nombre }}</td>
                                        <td>{{ item.cantidad }}</td>
                                        <td>
                                            <v-btn
                                            icon
                                            color="primary"
                                            small
                                            @click="setProducto(item,3)"
                                            >
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </template>
                                    <template v-slot:no-data>
                                    <v-btn color="primary" @click="obtenerPacientes">Actualizar</v-btn>
                                    </template>
                                </v-data-table>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item >
                                
                                
                            </v-list-item>
                        </v-list-group>
                </v-list>   
            </div>
            <div>
                <v-progress-linear
                    v-if="cargando"
                    indeterminate
                    color="accent"
                    height="8"
                ></v-progress-linear>
                <v-snackbar v-model="snackbar" :multi-line="multiLine">
                    {{ this.txtSnackBar }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
    
    data(){
        
        return{
            rutaServ : this.$store.state.servidor,  
            // dialogo
            selccionarProducto: false,
            search: "",
            lstProductos: [],
            productoSel:null,
            cantidad: 0,
            headers: [
                { text: "Código", value: "codigoProducto", sortable: true },
                { text: "Cantidad", value: "cantidad", sortable: true },
                { text: "Nombre", value: "nombre", sortable: true },
                { text: "descripcion", value: "descripcion", sortable: true },
                { text: "Opciones", value: "opciones", sortable: false },
            ],
            pacienteAct: null,
           
            //snackbar
            cargando: false,
            multiLine: true,
            snackbar: false,
            txtSnackBar: '',
            
            //Iniciales
            cargando: false,
            idUsuario:"",
            nombre: "",
            puesto: "",
            lstMisPacientes: [],
            headerPaciente: [
                { text: "Fecha Hora", value: "fechaAsignacion", sortable: true },
                { text: "Producto", value: "codigoProducto", sortable: true },
                { text: "Cantidad", value: "nombre", sortable: true },
                { text: "Opciones", value: "opciones", sortable: false },
            ],
        };
    },
    computed:{},
    watch:{},
    created(){
        this.obtenerPacientes();
    },
    methods:{
        mascarafecha(date){
            return moment(date).format("YYYY-MM-DD HH:mm");
        },
        obtenerPacientes(){
            if(localStorage.getItem("_id")){
                this.idUsuario = localStorage.getItem("_id");
            }
            if(localStorage.getItem("nombre")){
                const nom = localStorage.getItem("nombre");
                const paterno = localStorage.getItem("appPaterno");
                const materno = localStorage.getItem("appMaterno");
                this.nombre = nom + " " + paterno + " " + materno;
            }
            if(localStorage.getItem("puestoAsignado")){
                const puestoDat = JSON.parse(localStorage.getItem("puestoAsignado"));
                this.puesto = puestoDat[0].CategoriaPuesto.titulo;
                
            }
            if (this._id != ""){
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                me.lstMisPacientes = [];
                me.cargando = true;
                axios
                .get(this.$store.state.servidor + "pacientesEnfermera/" + me.idUsuario, configuracion)
                .then(function (response) {
                    const lstTemp = response.data.pacientes;
                    lstTemp.forEach(p => {
                        const ptemp = {
                            Cliente: p.Cliente,
                            Enfermera: p.Enfermera,
                            _id: p._id,
                            productosApl: [],
                            selccionado: false
                        };
                        me.lstMisPacientes.push(ptemp);
                    });
                    me.cargando = false;
                    // console.log('lstMisPacientes', me.lstMisPacientes);
                    me.cargaProductosPaciente();
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log(error);
                });
            }
        },
        cargaProductosPaciente(){
            this.lstMisPacientes.forEach( cli =>{
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                
                me.cargando = true;
                axios
                .get(this.$store.state.servidor + "asignacionProductoTicket/" + cli.Cliente._id, configuracion)
                .then(function (response) {
                    cli.productosApl = response.data.productosAsignados;
                    me.cargando = false;
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log(error);
                });
            });
            
        },
        abrirSeleccionarProducto(paciente){
            if(paciente.InSucursal){                
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                
                me.cargando = true;
                axios
                .get(this.$store.state.servidor + "inventario/" + paciente.InSucursal + "/0/0", configuracion)
                .then(function (response) {
                    me.pacienteAct = paciente;
                    me.lstProductos = response.data.inventario;
                    me.cargando = false;
                    me.selccionarProducto = true;
                })
                .catch(function (error) {
                    me.pacienteAct = null;
                    me.cargando = false;
                    me.mensajeSnackBar("No es posible cargar los productos");
                    console.log(error);
                });
            }else{
                this.mensajeSnackBar("El paciente no está asignado");
            }
            
        },
        setProducto(producto, opcion){
            // Seleccionar
            if (opcion === 1){
                this.productoSel = producto;
            }
            // agregar prod a paciente
            if (opcion === 2){
                const body = {
                    Cliente: this.pacienteAct._id,
                    Producto: producto._id,
                    cantidad: this.cantidad
                };
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                axios
                .post(
                    this.$store.state.servidor + "asignacionProductoTicket",
                    body,
                    configuracion
                )
                .then(function (response) {
                    me.cargando = false;
                    me.mensajeSnackBar("Se agregó correctamente el producto al paciente");
                    me.selccionarProducto = false;
                    me.obtenerPacientes();
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log('error', error);
                    me.mensajeSnackBar("No se agregó el producto al paciente");
                })
                .finally(() => (this.snackbar = true));
            }
            // quitar prod a paciente
            if (opcion === 3){             let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                axios
                .delete(
                    this.$store.state.servidor + "quitarProductoAsignacion/" + producto._id,
                    configuracion
                )
                .then(function (response) {
                    me.cargando = false;
                    me.mensajeSnackBar("Se quitó correctamente el producto al paciente");
                    me.selccionarProducto = false;
                    me.obtenerPacientes();
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log('error', error);
                    me.mensajeSnackBar("No se quitó el producto al paciente");
                })
                .finally(() => (this.snackbar = true));
            }
        },
        
        mensajeSnackBar(mensaje){
            this.txtSnackBar = mensaje;
            this.snackbar = true
        },
    },
};
</script>