<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Paciente</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="appPaterno" label="Apellido Paterno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="appMaterno" label="Apellido Materno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="RFC" label="RFC"> </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 d-flex>
                    <v-select
                      :items="generos"
                      v-model="genero"
                      item-text="generos"
                      item-value="generos"
                      label="Genero"
                    ></v-select>
                  </v-flex>

                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="acompanante" label="Acompañante">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 d-flex>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="fechaIngresoEdit"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaIngresoEdit"
                          label="Fecha de Cita"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaIngresoEdit"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="limpiarFechaIngreso()"
                        >
                          Cancelar
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(fechaIngresoEdit)"
                        >
                          Aceptar
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              al Paciente
              {{ adTitulo }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="pacientes"
        :search="search"
        :loading="loadingTable"
        
        
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.nombre }} {{ item.appPaterno }} {{ item.appMaterno }}
            </td>
            <td>{{ item.RFC }}</td>
            <td>{{ item.genero }}</td>
            <td>{{ item.acompanante }}</td>
            <td>
              <v-chip :color="ingreso(item.isInSucursal)">
                {{ item.isInSucursal ? item.InSucursal.alias : "Sin Ingreso" }}
              </v-chip>
            </td>
            
            <td>
               
              {{ item.fechaMostrar }}
               
            </td>
            <td>
              <v-btn v-if="validarFolio(item)" icon color="primary" :to="{ path: 'manejoServicio/'+item.ticket.folio}" target="_blank">
  {{ item.ticket?item.ticket.folio:"" }}
                </v-btn>
              
              
              
            </td>
            <td class="justify-center layout px-0">
              <!--<v-btn icon target="_blank">
                <v-icon color="info" @click="obtenerInf(item)">info</v-icon>
              </v-btn>-->

              <v-btn icon>
                <v-icon color="inner" @click="activarIngreso(item)"
                  >fa-street-view</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="calendar" @click="editItem(item)"
                  >fa-calendar</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="add" @click="crearServicioTicket(item)">fa-check</v-icon
                >
              </v-btn>
              <v-btn icon>
                <v-icon color="permiso"  @click="permiso01PDF(item)">fa-file-pdf-o</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="permiso"  @click="permiso02PDF(item)">fa-file-pdf-o</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import jsPDF from 'jspdf'


export default {
  data() {
    return {
      dialog: false,
      search: "",
      generos: ["Masculino", "Femenino", "Sin especificar"],
      pacientes: [],
      headers: [
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "RFC", value: "RFC", sortable: true },
        { text: "Genero", value: "genero", sortable: true },
        { text: "Acompañante", value: "acompanante", sortable: true },
        { text: "Area", value: "InSucursal.alias", sortable: true },
        { text: "Cita", value: "fechaMostrar", sortable: true },
        
        { text: "Folio de Servicios", value: "ticket.folio", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",

      nombre: "",
      appPaterno: "",
      appMaterno: "",
      RFC: "",
      genero: "",
      ubicacionPrincipal: "",

      snackbar: false,
      textPeticionRest: 'Se guardo correctamente',
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
      actPersona: "",
      menu: false,

      isInSucursal: false,
      InSucursal: "",
      fechaIngreso: new Date(),
      fechaActual: new Date(),
      fechaInicio : new Date(),
      fechaIngresoEdit: "",
      acompanante: "",

      tickets : []
      
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo paciente" : "Editar paciente";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    validarFolio(item){
      if (item.ticket){
        return true;
      } else {
        return false;
      }
    },
    permiso01PDF(item){
      
      var vm = this;
      var doc = new jsPDF('p', 'pt');
      let nombrecompleto = item.nombre+" "+item.appPaterno+" "+item.appMaterno;
      doc.text(nombrecompleto, 40, 40);
      doc.text(item.appPaterno, 40, 60);
      doc.text(item.appMaterno, 40, 80);
      doc.text(item.RFC, 40, 100);
      doc.text(item.acompanante, 40, 120);
      doc.text(item.InSucursal.alias, 40, 140);
     
      doc.save('prueba.pdf');
    
    },
    permiso02PDF(item){
      
      var vm = this;
      var doc = new jsPDF('p', 'pt');
      let nombrecompleto = item.nombre+" "+item.appPaterno+" "+item.appMaterno;
      doc.text(nombrecompleto, 40, 40);
      doc.text(item.appPaterno, 40, 60);
      doc.text(item.appMaterno, 40, 80);
      doc.text(item.RFC, 40, 100);
      doc.text(item.acompanante, 40, 120);
      doc.text(item.InSucursal.alias, 40, 140);
     
      doc.save('test.pdf');
    
    },
    limpiarFechaIngreso() {
      this.menu = false;

      this.fechaIngresoEdit = "";
    },
    ingreso(band) {
      if (band) {
        return "#009B7D";
      } else {
        return "#B29E9E";
      }
    },
    isOpen(band){
      if (band == null){
        return "white"
      } else 
      if (band) {
        return "open";
      } else {
        return "close";
      }

    },
    isHoy(date) {
      
      if (date){
        date = date.substring(0,10);
        this.fechaActual = new Date();
        let mes = (this.fechaActual.getMonth()+1)<10?"0"+(this.fechaActual.getMonth()+1).toString():(this.fechaActual.getMonth()+1).toString();
        let dia = (this.fechaActual.getDate()+1)<10?"0"+(this.fechaActual.getDate()).toString():(this.fechaActual.getDate()).toString();
        let tmpFecha = this.fechaActual.getFullYear()+"-"+mes+"-"+dia;
        
        if (date==tmpFecha){
          date="Hoy";
        }
      }
      return date;
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    async listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };

      await axios
        .get(this.$store.state.servidor + "sucursalPrincipal", configuracion)
        .then(function (response) {
          me.ubicacionPrincipal = response.data.sucursal._id;
          
        })
        .catch(function (error) {
          console.log(error);
        });

      await axios
        .get(this.$store.state.servidor + "ticket/0/0", configuracion)
        .then(function (response) {
          me.tickets = response.data.tikects;
          
        })
        .catch(function (error) {
          console.log(error);
        });
      await axios
        .get(this.$store.state.servidor + "paciente/0/0", configuracion)
        .then(function (response) {
          me.pacientes = response.data.ListadoPersonas;
          me.pacientes.forEach(element => {
            element.fechaMostrar = me.isHoy(element.fechaIngreso);
          });
          me.pacientes.forEach((paciente)=>{
            me.tickets.forEach((ticket)=>{
              
              if (ticket.Cliente == paciente._id){
                paciente.ticket = ticket;
                
              }
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.nombre = "";
      this.appPaterno = "";
      this.appMaterno = "";
      this.RFC = "";
      this.genero = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
      this.acompanante = "";
      this.search = "";
      
      this.limpiarFechaIngreso();
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nombre.length < 3 || this.nombre.length > 50) {
        this.validaMensaje.push(
          "El nombre del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (this.appPaterno.length < 3 || this.appPaterno.length > 50) {
        this.validaMensaje.push(
          "El Apellido Paterno del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (this.appMaterno.length < 3 || this.appMaterno.length > 50) {
        this.validaMensaje.push(
          "El Apellido Materno del paciente debe estar entre 3-50 caracteres."
        );
      }
      if (
        this.RFC.length != 0 &&
        (this.RFC.length < 12 || this.RFC.length > 13)
      ) {
        this.validaMensaje.push(
          "El RFC del paciente debe estar entre 12-13 caracteres."
        );
      }
      
      if (!this.genero) {
        this.validaMensaje.push("Debe seleccionar el genero del paciente");
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    actualizarIngreso() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .put(
          this.$store.state.servidor + "personaIsInSucursal",
          {
            Persona: this.actPersona,
            isInSucursal: this.isInSucursal,
            InSucursal: this.ubicacionPrincipal,
            fechaIngreso: this.fechaIngresoEdit,
          },
          configuracion
        )
        .then(function (response) {
          me.limpiar();
          me.close();
          me.listar();
          me.mensajeSnackBar(
            "Se actualizo correctamente el ingreso a la clinica"
          );
        })
        .catch(function (error) {
          me.mensajeSnackBar(
            "No se puede actualizar correctamente el ingreso a la clinica"
          );
        })
        .finally(() => (this.snackbar = true));
    },
    crearTicket() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .post(
          this.$store.state.servidor + "ticket",
          {
            Cliente: this._id,
            fechaInicio: this.fechaIngreso,
            comentarios : ""
          },
          configuracion
        )
        .then(function (response) {
          me.limpiar();
          me.close();
          me.mensajeSnackBar(
            "Se creo correctamente la solicitud de servicio"
          );
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar(
            "No se puede crear correctamente la solicitud de servicio"
          );
        })
        .finally(() => (this.snackbar = true));
        
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "persona",
            {
              _id: this._id,
              nombre: this.nombre,
              appPaterno: this.appPaterno,
              appMaterno: this.appMaterno,
              RFC: this.RFC,
              genero: this.genero,
              acompanante: this.acompanante,
              fechaIngreso: this.fechaIngresoEdit
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Paciente");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar el Paciente");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "persona",
            {
              nombre: this.nombre,
              appPaterno: this.appPaterno,
              appMaterno: this.appMaterno,
              RFC: this.RFC,
              genero: this.genero,
              fechaIngreso: this.fechaIngresoEdit,
              acompanante: this.acompanante,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Paciente");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear el Paciente");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    obtenerInf(item) {
      this.$router.push({
        name: "informacionPaciente",
        params: {
          id: item.id,
        },
      });
    },
    editItem(item) {
      this._id = item._id;
      this.nombre = item.nombre;
      this.appPaterno = item.appPaterno;
      this.appMaterno = item.appMaterno;
      this.RFC = item.RFC;
      this.genero = item.genero;
      this.fechaIngresoEdit = item.fechaIngreso? item.fechaIngreso.substring(0, 10) : "";
      this.acompanante = item.acompanante;
      this.dialog = true;
      this.editedIndex = 1;
    },
    editarFechaIngreso(item) {},
    activarIngreso(item) {
      this.actPersona = item._id;
      
      if (item.isInSucursal == false) {
        this.isInSucursal = true;
      } else {
        this.isInSucursal = false;
      }
      
      this.actualizarIngreso();
    },
    crearServicioTicket(item) {
      
      this._id = item._id;
      this.fechaInicio = this.fechaIngreso;
      this.crearTicket();

    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adTitulo =
        item.nombre + " " + item.appPaterno + " " + item.appMaterno;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "persona/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adTitulo = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente el Paciente");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar el Paciente");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>
