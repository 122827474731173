<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Categoria</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template >
        <v-btn class="mb-2"  color="xls" @click="crearExcel">
          <v-icon color="white">fa-file-excel-o</v-icon>
        </v-btn>
      </template>
        

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="abreviatura" label="Abreviatura">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="descripcion" label="Descripción">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              la Categoria
              {{ adNombre }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="departamentos"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.abreviatura }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.descripcion }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      departamentos: [],
      headers: [
        { text: "Abreviatura", value: "abreviatura", sortable: true },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",
      nombre: "",
      abreviatura: "",
      descripcion: "",
      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva categoria"
        : "Editar categoria";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    crearExcel() {
      let infTmp = [];
      this.departamentos.forEach((item) => {
        let variable = {
          Abreviatura : item.abreviatura ,
          Nombre: item.nombre,
          Descripcion : item.descripcion
        };
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte_Categorias_CEYE";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "departamentoCEYE/0/0", configuracion)
        .then(function (response) {
          me.departamentos = response.data.Departamentos;
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.nombre = "";
      this.abreviatura = "";
      this.descripcion = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nombre.length < 4 || this.nombre.length > 25) {
        this.validaMensaje.push(
          "El nombre del departamento debe tener entre 4-25 caracteres."
        );
      }
      if (this.abreviatura.length < 3 || this.abreviatura.length > 7) {
        this.validaMensaje.push(
          "La abreviatura del departamento debe tener entre 3-7 caracteres."
        );
      }
      if (this.descripcion.length < 15) {
        this.validaMensaje.push(
          "La descripción del departamento debe contener al menos 15 caracteres"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "departamentoCEYE",
            {
              _id: this._id,
              abreviatura: this.abreviatura,
              nombre: this.nombre,
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente la Categoria");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar la Categoria");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "departamentoCEYE",
            {
              abreviatura: this.abreviatura,
              nombre: this.nombre,
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente la Categoria");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear la Categoria");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "departamentoCEYE/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente la Categoria");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar la Categoria");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>



<!---
<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Categoria en CEYE</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template>
          <v-btn class="mb-2" color="xls" @click="crearExcel">
            <v-icon color="white">fa-file-excel-o</v-icon>
          </v-btn>
        </template>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="abreviatura" label="Abreviatura">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="descripcion" label="Descripción">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              la Categoria en CEYE
              {{ adNombre }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="departamentos"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.abreviatura }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.descripcion }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="delete" @click="eliminarItem(item)"
                  >delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      departamentos: [],
      headers: [
        { text: "Abreviatura", value: "abreviatura", sortable: true },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Descripcion", value: "descripcion", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",
      nombre: "",
      abreviatura: "",
      descripcion: "",
      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "Nueva categoria en CEYE"
        : "Editar categoria en CEYE";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    crearExcel() {
      let infTmp = [];
      if (this.departamentos!=null) {
        this.departamentos.forEach((item) => {
          let variable = {
            Abreviatura: item.abreviatura,
            Nombre: item.nombre,
            Descripcion: item.descripcion,
          };
          infTmp.push(variable);
        });
        let data = XLSX.utils.json_to_sheet(infTmp);
        const workbook = XLSX.utils.book_new();
        const filename = "Reporte_Categorias_CEYE";
        XLSX.utils.book_append_sheet(workbook, data, filename);
        XLSX.writeFile(workbook, `${filename}.xlsx`);
      } else {
           this.mensajeSnackBar(
              "No hay registros para generar el reporte"
            );
      }
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(this.$store.state.servidor + "depCEYE/0/0", configuracion)
        .then(function (response) {
          me.departamentos = response.data.DepCEYEs;
        })
        .catch(function (error) {
          console.log(error);
        });
      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.nombre = "";
      this.abreviatura = "";
      this.descripcion = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.nombre.length < 4 || this.nombre.length > 25) {
        this.validaMensaje.push(
          "El nombre del departamento debe tener entre 4-25 caracteres."
        );
      }
      if (this.abreviatura.length < 3 || this.abreviatura.length > 7) {
        this.validaMensaje.push(
          "La abreviatura del departamento debe tener entre 3-7 caracteres."
        );
      }
      if (this.descripcion.length < 15) {
        this.validaMensaje.push(
          "La descripción del departamento debe contener al menos 15 caracteres"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "depCEYE",
            {
              _id: this._id,
              abreviatura: this.abreviatura,
              nombre: this.nombre,
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar(
              "Se actualizo correctamente la Categoria en CEYE"
            );
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede actualizar la Categoria en CEYE");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            this.$store.state.servidor + "depCEYE",
            {
              abreviatura: this.abreviatura,
              nombre: this.nombre,
              descripcion: this.descripcion,
            },
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente la Categoria en CEYE");
          })
          .catch(function (error) {
            me.mensajeSnackBar("no se puede crear la Categoria en CEYE");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      this._id = item._id;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          this.$store.state.servidor + "depCEYE/" + me.removeId,
          configuracion
        )
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.removeId = "";
          me.mensajeSnackBar("Se elimino correctamente la Categoria en CEYE");
          me.listar();
        })
        .catch(function (error) {
          me.mensajeSnackBar("No se puede eliminar la Categoria en CEYE");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.limpiar();
      this.dialog = false;
    },
  },
};
</script>


---->