<template>
  <v-layout align-center justify-center>
    <v-flex xs12 sm8 md6 lg5 xl4>
      <v-card>
        <v-toolbar dark color="secondary">
          <v-toolbar-title class="flex text-center">
            Iniciar Sesión</v-toolbar-title
          >
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="email"
            autofocus
            color="accent"
            label="Email"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            color="accent"
            label="Password"
            required
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="px-3 pb-3">
          <v-flex text-xs-right>
            <v-btn @click="ingresar()" color="primary">Ingresar</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
      <div>
        <v-progress-linear
          v-if="isDataSending"
          indeterminate
          color="accent"
          height="8"
        ></v-progress-linear>
        <v-snackbar v-model="snackbar" :multi-line="multiLine">
          {{ textNoLogin }}

          <template v-slot:action="{ attrs }">
            <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      isDataSending: false,
      multiLine: true,
      snackbar: false,
      textNoLogin: `No se puede acceder al sistema, verifique sus credenciales`,
    };
  },
  components: {},
  methods: {
    async ingresar() {
      
      this.isDataSending = true;
      let response = await axios
        .post( this.$store.state.servidor + "login", {
          usuario: this.email,
          password: this.password,
        })
        .then(function (response) {
          return response.data;
        })
        .then((data) => {
          this.$store.dispatch("guardarToken", data);
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          return "error";
          //console.log(error);
        });
      //this.isDataSending = false;
      if (response == "error") {
        this.isDataSending = false;
        this.snackbar=true;
      }
    },
  },
};
</script>

