<template>
    <v-layout align-start>
        <v-flex>
            <v-toolbar flat color="white">
                <!-- <v-btn icon @click="regresar()"><v-icon color="primary">arrow_back</v-icon></v-btn> -->
                <v-toolbar-title>Enfermería </v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-toolbar-title> {{nombre}} <span></span></v-toolbar-title>
                <v-spacer></v-spacer>
                {{this.puesto}}
                
            </v-toolbar>
            <!-- DIALOGO PARA SELECCIONAR PACIENTE -->
            <v-dialog v-model="selccionarPaciente" width="1000">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Seleccionar Paciente </span>
                        </v-card-title>                    
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" text @click="selccionarPaciente = false">Cancelar</v-btn>
                        </v-card-actions>
                        <v-divider></v-divider>
                        <v-container>
                            <template>
                                <v-flex>
                                    <v-toolbar flat color="white">
                                        <v-toolbar-title>Paciente</v-toolbar-title>
                                        <v-divider class="mx-2" inset vertical></v-divider>
                                        <v-spacer></v-spacer>
                                        <v-text-field
                                            class="text-xs-center"
                                            v-model="search"
                                            append-icon="search"
                                            label="Búsqueda"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                </v-flex>
                            </template>
                            <v-data-table
                                :headers="headers"
                                :items="lstOtrosPacientes"
                                :search="search"
                                :loading="cargando"
                                loading-text="Cargando datos... Por favor espere un momento"
                                class="elevation-1"
                            >
                                <template v-slot:item="{ item }">
                                <tr>
                                    <td>{{ item.Cliente.nombre }}</td>
                                    <td>{{ item.Cliente.appPaterno }}</td>
                                    <td>{{ item.Cliente.appMaterno }}</td>
                                    <td>{{ item.Cliente.genero }}</td>
                                    <td>{{ item.Cliente.fechaNacimiento }}</td>
                                    <td>{{ item.Cliente.fechaIngreso }}</td>
                                    <td class="justify-center layout px-0">
                                        <v-btn
                                        icon
                                        color="primary"
                                        @click="tomarPaciente(item)"
                                        >
                                            <v-icon>send</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                                </template>
                                <template v-slot:no-data>
                                <v-btn color="primary" @click="obtenerPacientes">Actualizar</v-btn>
                                </template>
                            </v-data-table>
                        </v-container>

                    </v-card>
            </v-dialog>
            <!-- LISTA DE PACIENTES DE ENFERMERA -->
            <div>
                
                <v-list subheader three-line >
                    <v-divider></v-divider>
                    <v-subheader >
                        <v-toolbar-title>Mis Pacietes {{ lstMisPacientes.length >0 ? '(' + lstMisPacientes.length + ') ' : '' }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                        class="ma-2"
                        outlined
                        color="indigo"
                        @click="abrirAddPaciente()"
                        >
                        Agregar
                        </v-btn>
                    </v-subheader>
                    <span v-if="lstMisPacientes.length <=0 && ! cargando" style="color: red;">  NO HAY DATOS</span>
                    <!-- <v-divider></v-divider> -->
                
                
                    <v-list-item v-for="pas in lstMisPacientes" :key="pas._id">
                        <!-- <v-spacer></v-spacer> -->
                        <v-list-item-content>
                            <v-list-item-title >{{pas.Cliente.nombre}} {{pas.Cliente.appPaterno}} {{pas.Cliente.appMaterno}}</v-list-item-title>
                            <v-list-item-subtitle >Genero: {{pas.Cliente.genero}}; F. Nac: {{pas.Cliente.fechaNacimiento}}; F. Ing: {{pas.Cliente.fechaIngreso}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                        <v-list-item-action>
                            <v-btn text color="secondary" @click="liberarPaciente(pas)">
                            Liberar paciente
                            </v-btn>
                         </v-list-item-action>
                         <!-- <v-spacer></v-spacer> -->
                    </v-list-item>
                </v-list>   
            </div>
            <div>
                <v-progress-linear
                    v-if="cargando"
                    indeterminate
                    color="accent"
                    height="8"
                ></v-progress-linear>
                <v-snackbar v-model="snackbar" :multi-line="multiLine">
                    {{ this.txtSnackBar }}
                    <template v-slot:action="{ attrs }">
                    <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
                        Cerrar
                    </v-btn>
                    </template>
                </v-snackbar>
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
import axios from "axios";

export default {
    
    data(){
        
        return{
            rutaServ : this.$store.state.servidor,  
            selccionarPaciente: false,
            search: "",
            headers: [
                { text: "Nombre", value: "nombre", sortable: true },
                { text: "Paterno", value: "appPaterno", sortable: true },
                { text: "Materno", value: "appMaterno", sortable: true },
                { text: "Genero", value: "genero", sortable: true },
                { text: "F. Nac.", value: "fechaNacimiento", sortable: true },
                { text: "F. Ing", value: "fechaIngreso", sortable: true },
                { text: "Opciones", value: "opciones", sortable: false },
            ],
            //snackbar
            cargando: false,
            multiLine: true,
            snackbar: false,
            txtSnackBar: '',
            
            //
            cargando: false,
            idUsuario:"",
            nombre: "",
            puesto: "",
            lstMisPacientes: [],
            lstOtrosPacientes: [],
        };
    },
    computed:{},
    watch:{},
    created(){
        this.obtenerPacientes();
    },
    methods:{
        obtenerPacientes(){
            if(localStorage.getItem("_id")){
                this.idUsuario = localStorage.getItem("_id");
            }
            if(localStorage.getItem("nombre")){
                const nom = localStorage.getItem("nombre");
                const paterno = localStorage.getItem("appPaterno");
                const materno = localStorage.getItem("appMaterno");
                this.nombre = nom + " " + paterno + " " + materno;
            }
            if(localStorage.getItem("puestoAsignado")){
                const puestoDat = JSON.parse(localStorage.getItem("puestoAsignado"));
                this.puesto = puestoDat[0].CategoriaPuesto.titulo;
                
            }
            if (this._id != ""){
                let me = this;
                me.cargando = true;
                let header = { Authorization: localStorage.token };
                let configuracion = { headers: header };
                me.lstOtrosPacietes = [];
                axios
                .get(this.$store.state.servidor + "pacienteDisponible", configuracion)
                .then(function (response) {
                    me.lstOtrosPacientes = response.data.pacientes;
                    me.cargando = false;
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log(error);
                });
                me.lstMisPacientes = [];
                me.cargando = true;
                axios
                .get(this.$store.state.servidor + "pacientesEnfermera/" + me.idUsuario, configuracion)
                .then(function (response) {
                    me.lstMisPacientes = response.data.pacientes;
                    me.cargando = false;
                })
                .catch(function (error) {
                    me.cargando = false;
                    console.log(error);
                });
            }
        },
        abrirAddPaciente(){
            this.selccionarPaciente = true;
        },
        liberarPaciente(pas){
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            
            axios
            .delete(
                this.$store.state.servidor + "pacienteEnfermera?Cliente=" + pas.Cliente._id + '&Enfermera=' + me.idUsuario,
                configuracion
            )
            .then(function (response) {
                me.cargando = false;
                me.mensajeSnackBar("Se liberó correctamente el paciente");
                me.selccionarPaciente = 0;
                me.obtenerPacientes();
            })
            .catch(function (error) {
                me.cargando = false;
                console.log('error', error);
                me.mensajeSnackBar("No se liberó el paciente");
            })
            .finally(() => (this.snackbar = true));
        },
        tomarPaciente(pas){
            const body = {
                Cliente: pas.Cliente._id,
                Enfermera: this.idUsuario
            };
            let me = this;
            me.cargando = true;
            let header = { Authorization: localStorage.token };
            let configuracion = { headers: header };
            axios
            .post(
                this.$store.state.servidor + "pacienteEnfermera",
                body,
                configuracion
            )
            .then(function (response) {
                me.cargando = false;
                me.mensajeSnackBar("Se agregó correctamente el paciente");
                me.selccionarPaciente = 0;
                me.obtenerPacientes();
            })
            .catch(function (error) {
                me.cargando = false;
                console.log('error', error);
                me.mensajeSnackBar("No se agregó el producto");
            })
            .finally(() => (this.snackbar = true));
        },
        mensajeSnackBar(mensaje){
            this.txtSnackBar = mensaje;
            this.snackbar = true
        },
    },
};
</script>