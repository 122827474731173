var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"align-start":""}},[_c('v-flex',[_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Concentrado de Productos en CEYE")]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"text-xs-center",attrs:{"append-icon":"search","label":"Búsqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),[_c('v-btn',{attrs:{"color":"xls"},on:{"click":_vm.crearExcel}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("fa-file-excel-o")])],1)]],2),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.inventario,"search":_vm.search,"loading":_vm.loadingTable,"loading-text":"Cargando datos... Por favor espere un momento"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.descripcion ? item.descripcion.codigoProducto : "")+" ")]),_c('td',[_vm._v(" "+_vm._s(item.descripcion ? item.descripcion.nombre : "")+" "+_vm._s(item.descripcion ? item.descripcion.descripcion : "")+" "+_vm._s(item.descripcion ? item.descripcion.unidadDimension : "")+" ")]),_c('td',[_c('v-chip',{attrs:{"color":_vm.colorMaximosMinimos(
                  item.minimoTotal,
                  item.maximoTotal,
                  item.cantidadTotal
                )}},[_vm._v(_vm._s(item.cantidadTotal)+" ")])],1),_c('td',[_vm._v(_vm._s(item.minimoTotal)+" / "+_vm._s(item.maximoTotal))]),_c('td',[_vm._v(_vm._s(item.cantidadTotal-item.minimoTotal>0?0:item.minimoTotal-item.cantidadTotal))]),_c('td',[_vm._v(_vm._s(item.cantidadTotal-item.maximoTotal>0?0:item.maximoTotal-item.cantidadTotal))]),_c('td',_vm._l((item.Inventario),function(itmTemp){return _c('span',{key:itmTemp._id},[_vm._v(" "+_vm._s(itmTemp.Sucursal ? itmTemp.Sucursal.alias : "")+": "),_c('v-chip',{attrs:{"color":_vm.colorMaximosMinimos(
                    itmTemp.minimo,
                    itmTemp.maximo,
                    itmTemp.cantidad
                  )}},[_vm._v(" "+_vm._s(itmTemp.cantidad)+" ")])],1)}),0)])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.obtenerDatosInventario()}}},[_vm._v("Actualizar")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }