<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Asignación de Precio</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          class="text-xs-center"
          v-model="search"
          append-icon="search"
          label="Búsqueda"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template>
        <v-btn color="xls" @click="crearExcel">
          <v-icon color="white" >fa-file-excel-o</v-icon>
        </v-btn>
      </template>

        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-banner elevation="6">
                      <v-icon slot="icon" color="info" size="36">
                        fa-info-circle
                      </v-icon>
                      {{ descripcionProducto }}</v-banner
                    >
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <div class="text-right">
                      <v-switch
                        @change="calcularPrecio"
                        v-model="IVA"
                        label="IVA"
                      ></v-switch>
                    </div>
                  </v-flex>
                  <v-flex xs12 sm8 md8>
                    <v-select
                      @change="calcularPrecio"
                      :items="tipoAsignacionPrecio"
                      v-model="tipoAumentoPrecio"
                      label="Tipo de Incremento"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm5 md5>
                    <v-text-field
                      v-model="precioCompra"
                      type="number"
                      min="0"
                      @keyup="calcularPrecio"
                      @click="calcularPrecio"
                      label="Precio Compra"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-text-field
                      v-model="precioMenudeo"
                      type="number"
                      min="0"
                      @keyup="calcularPrecio"
                      @click="calcularPrecio"
                      label="Incremento"
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm3 md3>
                    <v-text-field
                      v-model="precioCalculado"
                      readonly
                      label="Precio Venta"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="adModal" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Precio
              {{ adNombre }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="DesactivarCerrar()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="precios"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.Producto.codigoProducto }}</td>
            <td>
              {{ item.Producto.nombre }} {{ item.Producto.descripcion }} (
              {{ item.Producto.unidadDimension }} )
            </td>
            <td>{{ item.precioCalculadoMenudeo }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="edit" @click="editItem(item)">edit</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="listar()">Actualizar</v-btn>
        </template>
      </v-data-table>
    </v-flex>
    <v-snackbar v-model="snackbar" :multi-line="multiLine">
      {{ textPeticionRest }}

      <template v-slot:action="{ attrs }">
        <v-btn color="delete" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      tipoAsignacionPrecio: ["Porcentaje", "Aumento"],
      precios: [],
      headers: [
        { text: "Codigo", value: "Producto.codigoProducto", sortable: true },
        { text: "Descripcion", value: "Producto.nombre", sortable: true },
        { text: "Precio de Venta", value: "precioCalculadoMenudeo", sortable: true },
        {
          text: "Opciones",
          value: "opciones",
          align: "center",
          sortable: false,
        },
      ],
      editedIndex: -1,
      _id: "",
      IVA: false,
      precioCompra: 0,
      precioMenudeo: 0,
      precioCalculado: 0,
      descripcionProducto: "",
      codigoProducto: "",
      tipoAumentoPrecio: "",
      nombre: "",
      descripcion: "",
      selectDepartamento: false,
      tags: "",

      snackbar: false,

      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,

      loadingTable: true,

      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      removeId: "",
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Precio" : "Editar Precio";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.listar();
  },
  methods: {
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(
          this.$store.state.servidor + "precioAdministrador/0/0",
          configuracion
        )
        .then(function (response) {
          let arregloElementosEliminar =[];
          let preciosFiltrados =[];
          me.precios = response.data.listadoPrecio;
          me.precios.forEach((element, indice)=>{
            if (element.Producto==null || element.Producto== undefined ){
              arregloElementosEliminar.push(indice);
            }else {
              preciosFiltrados.push(element);
            }
          });
          me.precios = preciosFiltrados;
        })
        .catch(function (error) {
          console.log(error);
        });

      this.loadingTable = false;
    },
    limpiar() {
      this._id = "";
      this.validaMensaje = [];
      this.editedIndex = -1;

      this.Producto = "";
      this.abreviatura = "";
      this.nombre = "";
      this.descripcion = "";
      this.codigoProducto = "";

      this.tipoAumentoPrecio = 0;
      this.precioMenudeo = 0;
      this.precioCompra = 0;
      this.precioCalculado = 0;

      this.descripcionProducto =
        "" +
        item.Producto.codigoProducto +
        "" +
        item.Producto.nombre +
        " " +
        item.Producto.descripcion +
        "" +
        item.Producto.unidadDimension +
        "";
      this.dialog = true;
      this.editedIndex = 1;
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.tipoAumentoPrecio) {
        if (this.tipoAumentoPrecio.length <= 0) {
          this.validaMensaje.push(
            "Debe seleccionar al menos un registro de tipo de aumento"
          );
        }
      } else {
        this.validaMensaje.push(
          "Debe seleccionar un registro de tipo de aumento"
        );
      }

      if (parseFloat(this.precioMenudeo) <= 0) {
        this.validaMensaje.push("El aumento debe ser mayor a cero");
      }

      if (parseFloat(this.precioCompra) == 0) {
        this.validaMensaje.push("El precio de compra debe ser mayor a cero");
      }

      if (parseFloat(this.precioCalculado) == 0) {
        this.validaMensaje.push(
          "El precio de venta debe ser mayor a cero, tenemos un error"
        );
      }
      if (this.validaMensaje.length) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > -1) {
        //Código para editar
        axios
          .put(
            this.$store.state.servidor + "precio",
            {
              Precio: this._id,
              Producto: this.Producto,
              codigoProducto: this.codigoProducto,
              precioCompra: this.precioCompra,
              tipoAumentoPrecio: this.tipoAumentoPrecio,
              precioMayoreo: this.precioMenudeo,
              precioMenudeo: this.precioMenudeo,
              IVA: this.IVA.toString(),
              ISR: "false",
            },
            configuracion
          )
          .then(function (response) {
            //me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Precio");
          })
          .catch(function (error) {
            me.mensajeSnackBar(
              "No se puede actualizar el Precio, verifique sus datos y que no se repita el Código de Farmaco y Precio"
            );
          })
          .finally(() => (this.snackbar = true));
      }
    },
    editItem(item) {
      
      this._id = item._id;
      this.Producto = item.Producto._id;
      this.codigoProducto = item.codigoProducto;
      this.tipoAumentoPrecio = item.tipoAumentoPrecio;
      //this.tipoAsignacionPrecio=item.tipoAumentoPrecio;
      this.precioMenudeo = item.precioMenudeo;
      this.precioCompra = item.precioCompra;
      this.precioCalculado = item.precioCalculadoMenudeo;

      this.IVA = item.IVA;

      this.descripcionProducto =
        "Código: " +
        item.Producto.codigoProducto +
        ", Descripción: " +
        item.Producto.nombre +
        " " +
        item.Producto.descripcion +
        " (" +
        item.Producto.unidadDimension +
        ")";
      this.dialog = true;
      this.editedIndex = 1;
    },
    eliminarItem(item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.removeId = item._id;
      this.adAccion = 2;
    },
    calcularPrecio() {
      if (this.tipoAumentoPrecio.length == 0) {
        this.precioCalculado = "0";
      } else if (
        parseFloat(this.precioCompra) > 0 &&
        parseFloat(this.precioMenudeo) > 0
      ) {
        switch (this.tipoAumentoPrecio) {
          case "Porcentaje":
            this.precioCalculado =
              (1 + parseFloat(this.precioMenudeo) * .01) *
              parseFloat(this.precioCompra);
              this.precioCalculado = Math.ceil((this.precioCalculado*100))*.01;
            break;
          case "Aumento":
            this.precioCalculado =
              parseFloat(this.precioMenudeo) + parseFloat(this.precioCompra);
            break;
          default:
            this.precioCompra = 0;
            break;
        }
      } else {
        this.precioCalculado = 0;
      }
    },
    DesactivarCerrar() {
      this.adModal = 0;
    },

    close() {
      //this.limpiar();
      this.dialog = false;
    },
    crearExcel() {
      let infTmp = [];
      this.precios.forEach((item) => {
        let variable = {
          "Codigo" : item.Producto.codigoProducto,
          "Descripcion" : item.Producto.nombre +" "+ item.Producto.descripcion +" "+ item.Producto.unidadDimension,
          "Precio Venta" : item.precioCalculadoMenudeo,
        };
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Lista_precios";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
  },
};
</script>




