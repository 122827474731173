<template>
  <v-layout align-start>
    <v-flex>
      <v-toolbar flat color="white">
        <v-toolbar-title>Trabajador</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        class="text-xs-center"
        v-model="search"
        append-icon="search"
        label="Búsqueda"
        single-line
        hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <template >
          <v-btn class="mb-2"  color="xls" @click="crearExcel">
            <v-icon color="white">fa-file-excel-o</v-icon>
          </v-btn>
        </template>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">Nuevo</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ editedIndex == 1 ? 'Editar trabajador'  : 'Nuevo trabajador' }}</span>
            </v-card-title>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-card-subtitle>Datos personales</v-card-subtitle>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="trabajador.nombre" label="Nombre">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="trabajador.appPaterno" label="Paterno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="trabajador.appMaterno" label="Materno">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-select
                      :items="generos"
                      v-model="trabajador.genero"
                      item-text="item"
                      item-value="item"
                      label="Genero"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="trabajador.CURP" label="CURP">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field v-model="trabajador.RFC" label="RFC">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="email" v-model="trabajador.correo" label="E-Mail">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="tel" v-model="trabajador.telefono" label="Telefono">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-card-subtitle>Datos de cuenta de usuario</v-card-subtitle>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field v-model="trabajador.alias" label="Usuario">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="password" v-model="trabajador.password" label="Contraseña">
                    </v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <v-text-field type="password" v-model="confpasword" label=" Confirmar contraseña">
                    </v-text-field>
                  </v-flex>

                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="close">Cancelar</v-btn>
              <v-btn color="primary" text @click="guardar">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="selPuesto" max-width="500">
          <v-card>
            <v-card-title>
              <span class="headline">{{editarPuesto? 'Modificar puesto' : 'Asignar puesto'}}</span>
            </v-card-title>
            <v-card-text>
              {{trabajador.nombre}} {{trabajador.appPaterno}} {{trabajador.appMaterno}}<br>
              {{ puestoSel? puestoSel.descripcion : 'Sin Puesto' }}
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-select
                      :items="puestos"
                      v-model="puestoSel"
                      item-text="descripcion"
                      item-value="_id"
                      label="Seleccionar puesto"
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm12 md12 v-show="valida">
                    <div
                      class="red--text"
                      v-for="v in validaMensaje"
                      :key="v"
                      v-text="v"
                    ></div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="setPuesto(null, 2)">Cancelar</v-btn>
              <v-btn color="primary" text @click="setPuesto(null,3)">Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="delTrabajador" max-width="290">
          <v-card>
            <v-card-title class="headline"> Eliminar registro </v-card-title>
            <v-card-text>
              Estás a punto de <span> eliminar </span>
              el Trabajador
              {{ trabajador.nombre }} {{ trabajador.appPaterno }} {{ trabajador.appMaterno }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" text @click="cerrarDelTrabajador()"
                >Cancelar
              </v-btn>
              <v-btn color="primary" text @click="eliminar()"> Eliminar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="trabajadores"
        :search="search"
        :loading="loadingTable"
        loading-text="Cargando datos... Por favor espere un momento"
        class="elevation-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.puesto }}</td>
            <td>{{ item.nombre }}</td>
            <td>{{ item.appPaterno }}</td>
            <td>{{ item.appMaterno }}</td>
            <td>{{ item.alias }}</td>
            <td class="justify-center layout px-0">
              <v-btn icon>
                <v-icon color="black" @click="setPuesto(item, 1)">badge</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="orange" @click="editItem(item)">edit</v-icon>
              </v-btn>
              <v-btn icon>
                <v-icon color="red" @click="eliminarItem(item)">delete</v-icon
                >
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="listar">Actualizar</v-btn>
        </template>
      </v-data-table>

    </v-flex>
      <v-snackbar v-model="snackbar" :multi-line="multiLine">
          {{ textPeticionRest }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Cerrar
            </v-btn>
          </template>
      </v-snackbar>
  </v-layout>
</template>
<script>
import axios from "axios";
import XLSX from "xlsx";  

export default {
  data() {
    return {
      dialog: false,
      search: "",
      trabajadores: [],
      puestos: [],
      ubicacion: null,
      generos: ["Masculino", "Femenino"],
      puestoSel: null,
      idPuestoTrabajador: "",
      headers: [
        { text: "Puesto", value: "puesto", sortable: true },
        { text: "Nombre", value: "nombre", sortable: true },
        { text: "Paterno", value: "appPaterno", sortable: true },
        { text: "Materno", value: "appMaterno", sortable: true },
        { text: "Usuario", value: "alias", sortable: true },
        { text: "Opciones", value: "opciones", sortable: false },
      ],
      trabajador: {
        _id: null,
        appPaterno: "",
        appMaterno: "",
        nombre: "",
        genero: "",
        CURP: "",
        correo: "",
        telefono: "",
        RFC: "",
        alias: "",
        password: "",
        puesto: ""
      },
      snackbar: false,
      textPeticionRest: `Se guardo correctamente`,
      multiLine: true,
      
      loadingTable: true,
      valida: 0,
      validaMensaje: [],
      adModal: 0,
      adAccion: 0,
      adTitulo: "",
      removeId: "",
      selPuesto: 0,
      confpasword: "",
      delTrabajador: 0,
      editarPuesto: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo trabajador" : "Editar trabajador";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.limpiar();
    this.listar();
    this.cargaCatalogos();
  },
  methods: {
    crearExcel() {
      let infTmp = [];
      this.trabajadores.forEach((item) => {
        let variable = {
          "Puesto" : item.puesto ,
          "Nombre": item.nombre,
          "Paterno": item.appPaterno,
          "Materno": item.appMaterno,
          "Usuario": item.alias,
        };
        infTmp.push(variable);
      });
      let data = XLSX.utils.json_to_sheet(infTmp);
      const workbook = XLSX.utils.book_new();
      const filename = "Reporte_Personal";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    cargaCatalogos(){
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .get(me.$store.state.servidor + "categoriaPuesto/0/0", configuracion)
        .then(function (response) {
          me.puestos = response.data.categoriasPuesto;
        })
        .catch(function (error) {
          console.log(error);
        });
      axios
        .get(me.$store.state.servidor + "sucursalPrincipal", configuracion)
        .then(function (response) {
          me.ubicacion = response.data.sucursal;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    mensajeSnackBar(mensaje) {
      this.textPeticionRest = mensaje;
    },
    listar() {
      this.loadingTable = true;
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      let trabajadoresAux = [];
      me.trabajadores = [];
      axios
        .get(me.$store.state.servidor + "personal/0/0", configuracion)
        .then(function (response) {
          trabajadoresAux = response.data.ListadoPersonas;
          // let me = this;
          let header2 = { Authorization: localStorage.token };
          let configuracion2 = { headers: header2 };
          trabajadoresAux.forEach((t) => {  
            axios
              .get(me.$store.state.servidor + "personalPuestoAsignadoSucursalPrincipal/" + t._id , configuracion2)
              .then(function (response2){
                t.puesto = response2.data.puesto.CategoriaPuesto.titulo;
                me.trabajadores.push(t);
              })
              .catch(function (error2) {
                t.puesto = "";
                me.trabajadores.push(t);
              });
          });
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          
        });
      this.loadingTable = false;
    },
    addPuesto(){
      
    },
    validar() {
      this.valida = 0;
      this.validaMensaje = [];
      if (this.trabajador.nombre.length < 3 || this.trabajador.nombre.length > 50) {
        this.validaMensaje.push(
          "El nombre debe tener entre 3-50 caracteres."
        );
      }
      if (this.trabajador.appPaterno.length < 3 || this.trabajador.appPaterno.length > 50) {
        this.validaMensaje.push(
          "El apellido paterno debe tener entre 3-50 caracteres."
        );
      }
      if (this.trabajador.appMaterno.length < 3 || this.trabajador.appMaterno.length > 50) {
        this.validaMensaje.push(
          "El apellido materno debe tener entre 3-50 caracteres."
        );
      }
      if (this.trabajador.genero.length <= 0) {
        this.validaMensaje.push(
          "El genero es un campo obligatorio."
        );
      }
      if (this.trabajador.password.length > 0) {
        if(this.trabajador.password != this.confpasword){
          this.validaMensaje.push(
          "La contraseña es diferente a la confirmación"
        );
        }
        this.confpasword = "";
      }
      if ( this.validaMensaje.length > 0) {
        this.valida = 1;
      }
      return this.valida;
    },
    guardar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      if (this.validar()) {
        return;
      }
      if (this.editedIndex > 0) {
        //Código para editar
        axios
          .put(
            me.$store.state.servidor + "personal",
            me.trabajador,
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se actualizo correctamente el Trabajador");
          })
          .catch(function (error) {
            console.log('error', error);
            me.mensajeSnackBar("no se puede actualizar el Trabajador");
          })
          .finally(() => (this.snackbar = true));
      } else {
        //Código para guardar
        axios
          .post(
            me.$store.state.servidor + "personal",
            me .trabajador,
            configuracion
          )
          .then(function (response) {
            me.limpiar();
            me.close();
            me.listar();
            me.mensajeSnackBar("Se creo correctamente el Trabajador");
          })
          .catch(function (error) {
            console.log('error', error);
            me.mensajeSnackBar("No se puede crear el Trabajador");
          })
          .finally(() => (this.snackbar = true));
      }
    },
    setPuesto(item, opcion){
      if (opcion === 1){
        this.seleccionarTrabajador(item);
        this.puestoSel= null;
        this.selPuesto = 1;
        let me = this;
        let header = { Authorization: localStorage.token };
        let configuracion = { headers: header };
        axios
          .get(me.$store.state.servidor + "personalPuestoAsignadoSucursalPrincipal/" + this.trabajador._id , configuracion)
          .then(function (response) {
            
            me.idPuestoTrabajador = response.data.puesto._id;
            me.puestoSel = response.data.puesto.CategoriaPuesto;
            me.editarPuesto = true;
          })
          .catch(function (error) {
            me.editarPuesto = false;
            console.log(error);
          });      
      }
      if (opcion === 2) {
        this.selPuesto = 0;
        this.puestoSel = null;
        this.limpiar();
      }
      if (opcion === 3) {
        
        let me = this;
        let header = { Authorization: localStorage.token };
        let configuracion = { headers: header };
        if (!this.editarPuesto) {
          console.log('trabajador', me.trabajador._id, 
          '; sucursal:', me.ubicacion._id,'; CategoriaPuesto:', me.puestoSel);
          axios
            .post(me.$store.state.servidor + "asignarPuesto",
            {
              Persona: me.trabajador._id,
              Sucursal: me.ubicacion._id,
              CategoriaPuesto: me.puestoSel
            }, 
            configuracion)
            .then(function (response) {
              me.limpiar();
              me.selPuesto = 0;
              me.listar();
              me.mensajeSnackBar("Se asigno correctamente el puesto del trabajador");              
            })
            .catch(function (error) {
              console.log(error);
              me.mensajeSnackBar("No se puede asignar el puesto al trabajador");
            })
            .finally(() => (this.snackbar = true));  
        } else {
          // console.log('_id:', me.idPuestoTrabajador,'; trabajador', me.trabajador._id, 
          // '; sucursal:', me.ubicaicones[0]._id,'; puesto: ', me.puestoSel);
          axios
            .put(me.$store.state.servidor + "asignarPuesto",
            {
              _id: me.idPuestoTrabajador,
              Persona: me.trabajador._id,
              Sucursal: me.ubicacion._id,
              CategoriaPuesto: me.puestoSel
            }, 
            configuracion)
            .then(function (response) {
              me.limpiar();
              me.selPuesto = 0;
              me.listar();
              me.mensajeSnackBar("Se asignó correctamente el puesto del trabajador");              
            })
            .catch(function (error) {
              console.log(error);
              me.mensajeSnackBar("No se puede asignar el puesto al trabajador");
            })
            .finally(() => (this.snackbar = true));  
        }
      }

    },
    editItem(item) {
      this.editedIndex = 1;
      this.seleccionarTrabajador(item);
      this.dialog = true;
    },
    eliminarItem(item) {
      this.delTrabajador = 1;
      this.seleccionarTrabajador(item);
      this.adAccion = 2;
    },
    cerrarDelTrabajador() {
      this.delTrabajador = 0;
      this.limpiar();
    },

    eliminar() {
      let me = this;
      let header = { Authorization: localStorage.token };
      let configuracion = { headers: header };
      axios
        .delete(
          me.$store.state.servidor + "personal/" + me.trabajador._id,
          configuracion
        )
        .then(function (response) {
          me.delTrabajador = 0;
          me.adAccion = 0;
          me.mensajeSnackBar("Se elimino correctamente el Puesto");
          me.listar();
        })
        .catch(function (error) {
          console.log('error', error);
          me.mensajeSnackBar("No se puede eliminar el Puesto");
        })
        .finally(() => (this.snackbar = true));
    },
    close() {
      this.dialog = false;
      this.limpiar();
    },
    seleccionarTrabajador(item){
      this.trabajador._id = item._id;
      this.trabajador.appPaterno = item.appPaterno;
      this.trabajador.appMaterno = item.appMaterno;
      this.trabajador.nombre = item.nombre;
      this.trabajador.genero = item.genero;
      this.trabajador.RFC = item.RFC;
      this.trabajador.CURP = item.CURP;
      this.trabajador.correo = item.correo;
      this.trabajador.telefono = item.telefono;
      this.trabajador.alias = item.alias;
      this.trabajador.pasword = item.pasword;
    },
    limpiar() {
      this.trabajador._id = null;
      this.trabajador.appPaterno = "";
      this.trabajador.appMaterno = "";
      this.trabajador.nombre = "";
      this.trabajador.genero = "";
      this.trabajador.RFC = "";
      this.trabajador.CURP = "";
      this.trabajador.correo = "";
      this.trabajador.telefono = "";
      this.trabajador.alias = "";
      this.trabajador.password = "";
      this.valida = 0;
      this.validaMensaje = [];
      this.editedIndex = -1;
    },
  },
};
</script>
